import Analytics from '../analytics';

// Redirect when deep link is opened
export const Listener = function(redirect, updateToken) {
  // Function to invoke redirect
  const handler = function(e) {
    if(!("deeplink" in e.detail)) return false;
    const payload = e.detail.deeplink;

    // Handle URL
    if("url" in payload && payload.url) {
      // Get path from URL
      const url = payload.url;
      const regex = new RegExp("^(?:http|https):\\/\\/app\\.lotsuite\\.com(.+?)(\\?.*|$)", "i");
      // Test URL match
      const match = url.match(regex);
      // Test URL for validity
      if(match === null) return console.warn("Invalid deep link URL");
      // Get path from URL
      const path = match[1].replace(/^$/, "/");  // Empty URL should be "/"
      // Get query string from URL
      const querystring = match[2];

      // Check for token in querystring
      const tokenMatch = querystring.match(/[?&]token=([^&#]*)/i);
      if(tokenMatch !== null) {
        updateToken(decodeURIComponent(tokenMatch[1]), false);
      }

      // Analytics
      Analytics.event("deeplink_opened", {
        app_state: ("app_state" in payload) ? payload.app_state : null,
        event_category: "referral",
        link_path: path,
        non_interaction: true
      });

      // Invoke redirect callback
      redirect(path+querystring);
    }
  };

  // Add listener
  document.removeEventListener("onDataInjection", handler);
  document.addEventListener("onDataInjection", handler);

  // Return method for stopping event listener
  return () => document.removeEventListener("onDataInjection", handler);
}

// Export functions
const functionsToExport = {
  Listener
};
export default functionsToExport;
