import React, { Component } from 'react';
import Agreements from '../Agreements/Agreements';
import { Spacer } from "../Header/Header";
import Status from "../Status/Status";
import * as Util from "../Utilities/Utilities";
import "./Updater.css";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import awsmobile from '../amplify-config';
Amplify.configure(awsmobile);

class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: null,
      seconds: this.props.seconds
    };
  }

  componentDidMount() {
    // Tick seconds down
    let interval = setInterval(() => {
      // When ticker is done
      if(this.state.seconds <= 0) {
        // Invoke `onDone` function
        this.props.onDone();
        // Stop ticking
        return clearInterval(this.state.interval);
      }
      // Tick
      this.setState({ seconds: this.state.seconds - 1 });
    }, 1000);
    this.setState({ interval: interval });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.seconds !== this.props.seconds
    || prevProps.snooze !== this.props.snooze) {
      this.setState({ seconds: this.props.seconds });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    // Format time
    let min = Math.floor(this.state.seconds / 60).toString();
    let sec = Math.floor(this.state.seconds % 60).toString()
      .padStart(2, '0');

    // Return time
    return (
      <span className="CountDown">
        {min+":"+sec}
      </span>
    );
  }
}

class Updater extends Component {
  constructor(props) {
    super(props);
    this.checkOnVisibilitychange = this.checkOnVisibilitychange.bind(this);
    this.reload = this.reload.bind(this);
    this.state = {
      interval: null,
      logout: false,  // Should user be logged out (expired/invalid token)
      softwareVersionData: {},  // Latest version data from API
      snooze: 0,  // Increment this to update state and force snooze
      timer: 30  // Time before page reloads automatically
    };
    this.lastCheck = 0;
  }

  componentDidMount() {
    // Check for updated software
    this.checkSoftwareVersion(true);

    // Check again every 10 minutes
    let interval = setInterval(() => this.checkSoftwareVersion(), 60*10*1000);
    this.setState({ interval: interval });

    // Check when page becomes visible after being hidden
    document.addEventListener("visibilitychange", this.checkOnVisibilitychange);
  }

  componentWillUnmount() {
    // Clear interval
    clearInterval(this.state.interval);

    // Remove event listeners
    document.removeEventListener("visibilitychange", this.checkOnVisibilitychange);
    document.removeEventListener("visibilitychange", this.reload);
  }

  checkOnVisibilitychange() {
    // Skip check if last checked seconds ago
    if((Math.floor(new Date() / 1000) - this.lastCheck) < 10) return;

    this.checkSoftwareVersion(true);
  }

  checkSoftwareVersion(force) {
    // Do not check if page is not visible
    if(document.hidden && !force) return;
    // Record check time
    this.lastCheck = Math.floor(new Date() / 1000);
    // Check API for new software versions
    const query = queries.checkSoftwareVersion;
    const params = { token: this.props.token };
    API.graphql(graphqlOperation(query, params))
      .then((res) => this.handleSoftwareVersion(res.data.checkSoftwareVersion))
      .catch((err) => {
        try {
          const errorType = err.errors[0].errorType;
          if(errorType === "Lambda:Handled"
          || err.errors[0].message === "Password has changed") {
            this.setState({ logout: true });
          }
        } catch(try_error) {}
        console.warn(err);
      });
  }

  handleSoftwareVersion(data) {
    // Update token
    if(data.new_token) this.props.updateToken(data.new_token);

    // Save data to state
    this.setState({ softwareVersionData: data });
  }

  reload() {
    // Do not reload while page is hidden
    if(document.hidden) {
      // Remove event listener
      document.removeEventListener("visibilitychange", this.reload);
      // Listen for page visibility change
      document.addEventListener("visibilitychange", this.reload);
      // Clear other page visibility listeners (already going to reload page)
      document.removeEventListener("visibilitychange", this.checkOnVisibilitychange);
      // Do not reload while hidden
      return;
    }

    // Reload page
    window.location.reload(true);
  }

  snooze() {
    this.setState({
      snooze: this.state.snooze + 1,
      timer: 300
    });
  }

  render() {
    // Log out, e.g. if token is expired
    if(this.state.logout) return <Status status="logout"/>;

    // Variables
    let needReload = false;
    let needAgree = false;

    // Check reload time
    const reload_at = Number(this.state.softwareVersionData.reload_at);
    if(reload_at > 0 && !isNaN(reload_at)) {
      const now = Math.floor(new Date() / 1000);
      if(reload_at >= window.lotsuiteLoadedAt  // Webapp loaded before `reload_at`
      && now >= reload_at) {  // Current time is after `reload_at` (prevent looping reloads)
        needReload = true;
      }
    }

    // Check if user needs to have agreed to T&C by a specified time
    const agree_at = Number(this.state.softwareVersionData.agree_at);
    if(agree_at > 0 && !isNaN(agree_at)) {
      // Get agreed_at timestamp from user
      const agreed_at = this.props.user.agreed_at;
      // Get current timestamp
      const now = Math.floor(new Date() / 1000);
      // Check if user needs to re-agree
      if(agree_at >= agreed_at && now >= agree_at) {
        needAgree = true;
      }
    }

    // Check webapp version
    if(this.state.softwareVersionData.webapp_version) {
      const minVersionCheck = Util.insertVariables("$webapp.minVersion("+
        this.state.softwareVersionData.webapp_version+")");
      if(minVersionCheck === false || minVersionCheck === "false") {
        needReload = true;
      }
    }

    // Show reload prompt
    if(needReload) return (
      <div className="UpdaterContainer" key="updater">

        <div className="Updater">

          <div className="wrapper">
            <h1>A new version of LotSuite is available</h1>
            <h2>
              Refreshing in&nbsp;
              <CountDown
                seconds={this.state.timer}
                snooze={this.state.snooze}
                onDone={this.reload.bind(this)}/>
            </h2>
            <div
              onClick={this.snooze.bind(this)}
              className="wait">
              Snooze
            </div>
            <button
              onClick={this.reload.bind(this)}>
              Refresh Now
            </button>
          </div>

          <Spacer
            position="bottom"
            min="30"/>

        </div>

        <Spacer
          className="mobileSpacer"
          position="bottom"
          min="30"/>
        <Spacer
          className="desktopSpacer"
          position="bottom"
          min="60"/>
      </div>
    );

    // Show Agreements page
    if(needAgree) return (
      <Agreements
        updateToken={this.props.updateToken.bind(this)}
        token={this.props.token}/>
    );

    // Show nothing if not needed
    return "";
  }
}

export default Updater;
