import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Status from "../Status/Status";
import { Header, Footer, Spacer } from "../Header/Header";
import { Buttons } from "../Button/Button";
import * as Util from "../Utilities/Utilities";
import Analytics from '../analytics';
import { InstallPrompt } from "../InstallPage/InstallPage";
import Updater from "../Updater/Updater";
import { Link } from 'react-router-dom';
import Bowser from "bowser";
import "./ReportsPage.css";
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isPlainObject';

import Amplify, { API, graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import awsmobile from '../amplify-config';
const configureAmplify = function() {
  awsmobile.API = { graphql_headers: async () => ({
    // Add token to headers for subscription auth
    token: localStorage.getItem("token")
  }) };
  Amplify.configure(awsmobile);
};
configureAmplify();

const browser = Bowser.getParser(window.navigator.userAgent);
const doesNotSupportDownload = browser.satisfies({
  "internet explorer": "<=11",
  "edge": "12"
});

class Menu extends Component {
  render() {
    // Hide menu
    if(!this.props.showMenu) return "";

    // Create options
    let menuOptions = [];
    for(let i=0; i<this.props.ranges.length; i++) {
      menuOptions.push(
        <div
          key={i}
          onClick={() => this.props.changeRange(this.props.ranges[i])}
          className="button">
          {this.props.ranges[i].title}
        </div>
      );
    }

    // Return menu
    return (
      <div className="menu">
        {menuOptions}
      </div>
    );
  }
}

class Download extends Component {
  ieFileDownload(csv, filename) {
    // Create blob from file
    let blob = new Blob([csv], {type: "text/csv"});
    // Download file
    navigator.msSaveBlob(blob, filename+".csv");
  }

  quoteWrap(value) {
    return (/,/.test(value))
      ? "\""+value+"\""
      : value;
  }

  removeCommas(value) {
    return value.replace(/,/g, "");
  }

  render() {
    // Hide if in native app
    if(window.isNative) return "";

    // Prepare CSV
    let csv = [];
    // Add dimensions to headers row
    let headers = this.props.headers;
    let row = [];
    for(let i=0; i<headers.dimensions.length; i++) {
      row.push(this.quoteWrap(headers.dimensions[i].name));
    }
    // Add periods to headers row
    for(let i=0; i<headers.periods.length; i++) {
      row.push(this.quoteWrap(headers.periods[i].name));
    }
    // Add headers to CSV
    csv.push(row);

    // Add rows to CSV
    let rows = this.props.rows;
    for(let i=0; i<rows.length; i++) {
      let row = []
      // Add row dimension values to row
      for(let j=0; j<rows[i].dimensions.length; j++) {
        row.push(this.quoteWrap(rows[i].dimensions[j].formatted));
      }
      // Add row period values to row
      for(let j=0; j<rows[i].periods.length; j++) {
        row.push(this.removeCommas(rows[i].periods[j].formatted));
      }
      // Add row to CSV
      csv.push(row);
    }

    // Convert CSV to string
    let csvString = csv.map(e => e.join(",")).join("\n");

    // Report name
    let report_name = this.props.page.analytics
      .reports[this.props.report_id].name;
    // Add date range to name
    const periodsCount = headers.periods.length;
    let periodsString = "";
    if(periodsCount > 0) periodsString = headers.periods[0].id +
      ((periodsCount > 1)
        ? " to " + headers.periods[periodsCount - 1].id
        : "");
    // Insert dashes into dates
    periodsString = periodsString
      .replace(/(\d{4})(\d{0,2})(\d{0,2})/g, "$1-$2-$3")
      .replace(/-+(?!\d)/g, "");
    // Append periods to file name
    let file_name = report_name+" ("+periodsString+")";

    // Return button with legacy file download method for IE
    if(doesNotSupportDownload) return (
      <div
        onClick={() => {
          Analytics.event("report_downloaded", {
            event_category: "reports",
            report_id: this.props.report_id,
            report_name: report_name
          });
          this.ieFileDownload(csvString, file_name);
        }}
        className="card">
        Download Report
      </div>
    );

    // Return button to download file
    return (
      <a
        className="card"
        onClick={() => {
          Analytics.event("report_downloaded", {
            event_category: "reports",
            report_id: this.props.report_id,
            report_name: report_name
          });
        }}
        href={"data:text/csv;charset=utf-8,"+encodeURIComponent(csvString)}
        download={file_name+".csv"}>
        Download Report
      </a>
    );
  }
}

class RangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    return (
      <div
        onClick={this.toggleMenu.bind(this)}
        className="card">
        Range: {this.props.range.title}
        <Menu
          changeRange={this.props.changeRange.bind(this)}
          range={this.props.range}
          ranges={this.props.ranges}
          showMenu={this.state.showMenu}/>
      </div>
    );
  }
}

class ReportButtons extends Component {
  render() {
    return (
      <div className="ReportButtons">
        <Download
          headers={this.props.headers}
          rows={this.props.rows}
          report_id={this.props.report_id}
          page={this.props.page}/>
        <RangePicker
          changeRange={this.props.changeRange.bind(this)}
          range={this.props.range}
          ranges={this.props.ranges}/>
      </div>
    );
  }
}

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],  // Metrics from API
      loading: true,
      range: {},  // Selected range of periods
      ranges: [],  // Predefined ranges of periods
      params: {}  // Query params to get data from API
    };
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.report_id !== this.props.report_id
    || prevProps.page.id !== this.props.page.id) {
      this.initialize();
    }
  }

  addMissingPeriods(period_ids) {
    let range = this.state.range;
    // Get start date
    let start = {
      year: range.start_year,
      month: (range.start_month) ? range.start_month : 1,
      day: (range.start_day) ? range.start_day : 1
    };
    // Get end date
    let end = {
      year: ("end_year" in range) ? range.end_year : null,
      month: ("end_month" in range) ? range.end_month : null,
      day: ("end_day" in range) ? range.end_day : null
    }
    // Create end date if end year is not defined
    if(end.year === null) {
      let now = new Date();
      end.year = now.getFullYear();
      end.month = now.getMonth()+1;
      end.day = now.getDate();
    }
    // Get missing periods
    switch(range.interval) {
      case "year":
        for(let year=start.year; year<=end.year; year++) {
          let y = year.toString();
          if(period_ids.indexOf(y) < 0) period_ids.push(y);
        }
        break;

      case "month":
        let m_start = start.year*12 + start.month - 1;
        let m_end = end.year*12 + end.month - 1;
        for(let m=m_start; m<=m_end; m++) {
          let year = Math.floor(m / 12).toString();
          let month = (m % 12 + 1).toString().padStart(2, '0');
          let period = year+month;
          if(period_ids.indexOf(period) < 0) period_ids.push(period);
        }
        break;

      case "day":
        let d_start = new Date(start.year, start.month - 1, start.day);
        let d_end = new Date(end.year, end.month - 1, end.day);
        let date = d_start;
        while(date <= d_end) {
          // Create period ID
          let year = date.getFullYear().toString();
          let month = (date.getMonth() + 1).toString().padStart(2, '0');
          let day = date.getDate().toString().padStart(2, '0');
          let period = year+month+day;
          // Add period to array if not already present
          if(period_ids.indexOf(period) < 0) period_ids.push(period);
          // Go to next date
          date = new Date(date.setDate(date.getDate() + 1));
        }
        break;

      default:
        console.error("Unrecognized metric interval: "+range.interval);
    }
    // Return period IDs
    return period_ids;
  }

  changeRange(range) {
    // Analytics
    Analytics.event("range_changed", {
      event_category: "reports",
      range_title: range.title,
      range_interval: range.interval,
      report_id: this.props.report_id,
      report_name: this.props.page.analytics
        .reports[this.props.report_id].name
    });
    // Update range, then re-query data
    this.setState({ range: range }, this.loadData);
  }

  initialize() {
    // Do not initialize when waiting for data
    if(typeof this.props.page.id === "undefined") return;

    // Get report config
    const report_id = this.props.report_id;
    const report_config = ("analytics" in this.props.page
    && "reports" in this.props.page.analytics
    && report_id in this.props.page.analytics.reports)
      ? this.props.page.analytics.reports[report_id]
      : {};

    // Set query parameters
    let params = {
      token: this.props.token,
      page_id: this.props.page.id,
      list_id: ("query" in report_config
      && "list_id" in report_config.query)
        ? report_config.query.list_id
        : this.props.page.lists[0].id,
      metric_id: ("query" in report_config
      && "metric_id" in report_config.query)
        ? report_config.query.metric_id
        : null,
      metric_ids: ("query" in report_config
      && "metric_ids" in report_config.query)
        ? report_config.query.metric_ids
        : null
    };

    // Add tag data to params if defined
    if("query" in report_config
    && "tag_id" in report_config.query) params.tag_id =
      report_config.query.tag_id;
    if("query" in report_config
    && "tag_ids" in report_config.query) params.tag_ids =
      report_config.query.tag_ids;
    if("query" in report_config
    && "tag_value" in report_config.query) params.tag_value =
      report_config.query.tag_value;

    // Define times
    let now = new Date();
    // 7 days ago
    let sevenDaysAgo = new Date(now.getFullYear(), now.getMonth(),
      now.getDate() - 7);
    // 30 days ago
    let thirtyDaysAgo = new Date(now.getFullYear(), now.getMonth(),
      now.getDate() - 30);
    // This week
    let thisWeek = new Date(now.getFullYear(), now.getMonth(),
      now.getDate() - now.getDay());
    // Last week
    let lastWeekStart = new Date(now.getFullYear(), now.getMonth(),
      now.getDate() - now.getDay() - 7);
    let lastWeekEnd = new Date(now.getFullYear(), now.getMonth(),
      now.getDate() - now.getDay() - 1);
    // Last month
    let lastMonth = new Date(now.getFullYear(), now.getMonth() - 1);
    let lastDayLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    // Define range options
    let ranges = [
      {
        title: "Last 7 days",
        start_year: sevenDaysAgo.getFullYear(),
        start_month: sevenDaysAgo.getMonth() + 1,
        start_day: sevenDaysAgo.getDate(),
        interval: "day"
      },
      {
        title: "Last 30 days",
        start_year: thirtyDaysAgo.getFullYear(),
        start_month: thirtyDaysAgo.getMonth() + 1,
        start_day: thirtyDaysAgo.getDate(),
        interval: "day"
      },
      {
        title: "This week",
        start_year: thisWeek.getFullYear(),
        start_month: thisWeek.getMonth() + 1,
        start_day: thisWeek.getDate(),
        interval: "day"
      },
      {
        title: "Last week",
        start_year: lastWeekStart.getFullYear(),
        start_month: lastWeekStart.getMonth() + 1,
        start_day: lastWeekStart.getDate(),
        end_year: lastWeekEnd.getFullYear(),
        end_month: lastWeekEnd.getMonth() + 1,
        end_day: lastWeekEnd.getDate(),
        interval: "day"
      },
      {
        title: "This month",
        start_year: now.getFullYear(),
        start_month: now.getMonth() + 1,
        start_day: 1,
        interval: "day"
      },
      {
        title: "Last month",
        start_year: lastMonth.getFullYear(),
        start_month: lastMonth.getMonth() + 1,
        start_day: 1,
        end_year: lastMonth.getFullYear(),
        end_month: lastMonth.getMonth() + 1,
        end_day: lastDayLastMonth.getDate(),
        interval: "day"
      },
      {
        title: "This year",
        start_year: now.getFullYear(),
        start_month: 1,
        start_day: 1,
        interval: "month"
      },
      {
        title: "Last year",
        start_year: now.getFullYear() - 1,
        start_month: 1,
        start_day: 1,
        end_year: now.getFullYear() - 1,
        end_month: 12,
        end_day: 31,
        interval: "month"
      }
    ];
    // Update state, then load data
    this.setState({
      range: ranges[0],  // Default to first range option
      ranges: ranges,
      params: params
    }, this.loadData);
  }

  loadData() {
    // Indicate loading
    this.setState({
      loading: true,
      data: []
    });

    // Get info from state and merge range info
    let params = Object.assign({}, cloneDeep(this.state.params),
      cloneDeep(this.state.range));

    // Define query
    let query = `query
      GetMetrics(
        $token: String!
        $page_id: ID!
        $list_id: ID!
        $metric_id: ID
        $metric_ids: [ ID ]
        $interval: MetricInterval!
        $start_year: Int
        $start_month: Int
        $start_day: Int
        $end_year: Int
        $end_month: Int
        $end_day: Int
        $tag_id: ID
        $tag_ids: [ ID ]
        $tag_value: ID
      ) {
        getMetrics(
          token: $token
          page_id: $page_id
          list_id: $list_id
          metric_id: $metric_id
          metric_ids: $metric_ids
          interval: $interval
          start_year: $start_year
          start_month: $start_month
          start_day: $start_day
          end_year: $end_year
          end_month: $end_month
          end_day: $end_day
          tag_id: $tag_id
          tag_ids: $tag_ids
          tag_value: $tag_value
        ) {
          list_id
          metric_id
          tag_id
          tag_value
          year
          month
          day
          value
        }
      }`;
    // Query API
    API.graphql(graphqlOperation(query, params))
      // Success
      .then(res => this.setState({
        data: res.data.getMetrics,
        loading: false,
      }))
      // Error
      .catch(err => {
        console.log(err);
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  render() {
    // Handle loading
    if(this.state.loading) return <Status status="loading"/>;

    // Round numbers
    const round = function(number) {
      // Round to hudredths, only if number has a decimal
      return ((number % 1 > 0)
        ? Math.round(number*100)/100
        : number).toLocaleString('en-US');  // Add thousands separators
    }

    // Format seconds as readable time
    const formatClock = (value) => {
      // Hours
      let hours = Math.floor(value / 3600)
        .toString().padStart(2, '0');
      // Minutes
      let minutes = Math.floor((value % 3600) / 60)
        .toString().padStart(2, '0');
      // Seconds
      let seconds = (value % 60)
        .toString().padStart(2, '0');
      // Combine into formatted value, hide hours if `00`
      return ((hours !== '00') ? hours + ":" : "")
        + minutes +":" + seconds;
    }

    // Format synthetic dimensions
    const format_syn = function(value, format) {
      switch(format) {
        case "time_clock":
          return (Util.isNumeric(value))
            ? formatClock(Math.round(value))
            : value;

        default:
          return round(value);
      }
    };

    // Get report config
    const report_config =
      this.props.page.analytics.reports[this.props.report_id];
    // Add dimensions to report config if only `dimension` is given
    if(!("dimensions" in report_config)) {
      report_config.dimensions = [ report_config.dimension ];
    }
    // Loop through data and group by dimensions
    let grouped_data = {};
    let period_ids = [];
    for(let i=0; i<this.state.data.length; i++) {
      let row = this.state.data[i];
      let row_id = null;
      let dimension_id = null;
      let table_row = { values: {} }
      // Create period ID
      let period_id = row.year.toString() +
        ((row.month) ? row.month.toString().padStart(2, '0') : "") +
        ((row.day) ? row.day.toString().padStart(2, '0') : "");
      // Parse dimensions to get row_id
      let dimensions_array = [];
      // Add each dimension's value to array
      for(let j=0; j<report_config.dimensions.length; j++) {
        dimension_id = (typeof report_config.dimensions[j] === "string")
          ? report_config.dimensions[j]
          : report_config.dimensions[j].id;
        dimensions_array.push(row[dimension_id]);
      }
      // Stringify dimensions to use array as key
      row_id = JSON.stringify(dimensions_array);
      // Add dimensions array to table data row
      table_row.dimensions = dimensions_array;
      // Create dimension row in table if it does not exist
      if(!(row_id in grouped_data)) grouped_data[row_id] = table_row;
      // Add period ID to row if it does not exist
      if(period_ids.indexOf(period_id) < 0) period_ids.push(period_id);
      // Add value to row
      grouped_data[row_id].values[period_id] = row.value;
    }

    // Add missing dates
    period_ids = this.addMissingPeriods(period_ids);
    // Sort period_ids
    period_ids.sort();
    // Create array of period totals
    let period_totals = new Array(period_ids.length).fill(0);

    // Create headers array
    let headers = {
      dimensions: [],
      periods: []
    };
    // Create headers array
    for(let i=0; i<report_config.dimensions.length; i++) {
      let id = null;
      let name = null;
      // Get name from dimension
      if(typeof report_config.dimensions[i] === "string") {
        id = report_config.dimensions[i];
        name = id;
      } else {
        id = report_config.dimensions[i].id;
        if("name" in report_config.dimensions[i]) {
          name = report_config.dimensions[i].name
        } else name = id;
      }
      // Add dimension to headers
      headers.dimensions.push({
        id: id,
        name: name
      });
    }
    // Add periods to headers
    for(let i=0; i<period_ids.length; i++) {
      // Get period ID
      let id = period_ids[i];
      let name = id;
      // Format value
      let match = id.toString().match(/^(\d{4})(\d{2})?(\d{2})?$/);
      if(match !== null) {
        // Month
        if(typeof match[2] !== "undefined") name = match[2]+"/"+match[1];
        // Day
        if(typeof match[3] !== "undefined") name = match[2]+"/"+match[3];
      }
      // Add header to array
      headers.periods.push({
        id: id,
        name: name
      });
    }

    // Replace function
    const replace = function(string, find = "", new_string = "") {
      // Check if `find` is regex
      const regexMatch = find.match(/\/(.+)\/(\w*)/);
      if(regexMatch !== null) {
        find = new RegExp(regexMatch[1], regexMatch[2]);
      }

      // Replace
      return string.replace(find, new_string);
    }

    // Convert grouped data to arrays
    let rows = [];
    Object.keys(grouped_data).forEach((key) => {
      let row = {
        dimensions: [],
        periods: [],
        total: 0,
        exclude: false
      };
      // Add all dimensions to row
      for(let i=0; i<grouped_data[key].dimensions.length; i++) {
        // Get dimension value
        let value = grouped_data[key].dimensions[i];
        let formatted = value;
        let id = key;
        let dimension_config = report_config.dimensions[i];

        // Exclude select dimensions
        if("exclude" in dimension_config
        && Array.isArray(dimension_config.exclude)
        && dimension_config.exclude.indexOf(value) >= 0) {
          // Remove excluded dimension from report
          row.exclude = true;
        }

        // Check for dimension formatting
        let format = dimension_config.format;
        // Format parameters
        if(isObject(format) && "type" in format) {
          // Replacements
          if(Array.isArray(format.replace) && "replace" in format) {
            // Loop through replace
            for(let j=0; j<format.replace.length; j++) {
              let rep = format.replace[j];

              // Remove (string, e.g. `"replaceme"`)
              if(typeof rep == "string") {
                formatted = replace(formatted, rep);
                continue;
              }

              // Remove (array, e.g. `[ "replaceme" ]`)
              if(Array.isArray(rep) && rep.length === 1) {
                formatted = replace(formatted, rep[0]);
                continue;
              }

              // Replace (array, e.g. `[ "replaceme", "newvalue" ]`)
              if(Array.isArray(rep) && rep.length === 2) {
                formatted = replace(formatted, rep[0], rep[1]);
                continue;
              }
            }
          }

          // Get type of format from format params
          format = format.type;
        }
        // Format types
        switch(format) {
          case "beautify":
            formatted = formatted
              .replace(/_/g, " ")  // Replace underscores with spaces
              .replace(/(?:^|\s)\w/g, match => {  // Capitalize words
                return match.toUpperCase();
              });
            break;

          case "user_id":
            // Get user name from `users`
            let found = false;
            for(let j=0; j<this.props.users.length; j++) {
              let user = this.props.users[j];
              if(user.id === formatted) {
                formatted = (user.name) ? user.name : user.email;
                found = true;
                break;
              }
            }
            // If user was not found, indicate removed
            if(!found) formatted = "[REMOVED]";
            break;

          default:
            if(typeof report_config.dimensions[i].format !== "undefined")
              console.warn("Unrecognized report dimension format: "+
                report_config.dimensions[i].format);
        }

        // Add dimension to row
        row.dimensions.push({
          value: value,
          formatted: formatted,
          id: id
        });
      }

      // Add values to row
      for(let i=0; i<period_ids.length; i++) {
        // Get value for this period
        let value = grouped_data[key].values[period_ids[i]];
        let formatted = value;
        let id = key;
        if(typeof value === "undefined") formatted = "";
        // Add value to column and row counts
        else {
          // Do not add to period total if row is excluded
          if(!row.exclude) period_totals[i] += value;
          row.total += value;
        }
        // Add value to row
        row.periods.push({
          value: value,
          formatted: round(formatted),
          id: id
        });
      }

      // Add row to array
      rows.push(row);
    });

    // Create function to sort data by dimensions
    const sortRows = function(rows) {
      return rows.sort((a,b) => {
        let order = 0;
        // Sort by formatted value of dimensions
        for(let i=0; i<a.dimensions.length; i++) {
          // Sort [REMOVED] last
          if(a.dimensions[i].formatted === "[REMOVED]"
          && b.dimensions[i].formatted !== "[REMOVED]") return 1;
          if(a.dimensions[i].formatted !== "[REMOVED]"
          && b.dimensions[i].formatted === "[REMOVED]") return -1;
          // Sort by comparing formatted values
          order = a.dimensions[i].formatted
            .localeCompare(b.dimensions[i].formatted);
          if(order !== 0) return order;
        }
        // Sort by raw value of dimensions (if not already sorted)
        for(let i=0; i<a.dimensions.length; i++) {
          if(a.dimensions[i].value === null) return 1;
          if(b.dimensions[i].value === null) return -1;
          order = a.dimensions[i].value
            .localeCompare(b.dimensions[i].value);
          if(order !== 0) return order;
        }
        // Fallback
        return 0;
      });
    };
    // Sort rows
    rows = sortRows(rows);

    // Create synthetic dimension valuess in last-heirarchical dimension
    let dim_count = report_config.dimensions.length;
    let last_dim_config = report_config.dimensions[dim_count - 1];
    // Convert rows to object to allow reference by index
    let rows_object = {};
    rows.forEach((row) => rows_object[row.dimensions[0].id] = row);
    // Function to recursively compare dimensions
    let dim_match = function(dim_this, dim_next, depth) {
      // If depth is 1, parent dimension will always be the same
      if(depth === 1) return false;

      // Combine parent dimensions for this and next row
      let dim_this_str = "";
      let dim_next_str = "";
      for(let i=0; i<depth - 1; i++) {
        dim_this_str += "~" + dim_this[i].value;
        dim_next_str += "~" + dim_next[i].value;
      }

      // Compare this and next dimension strings
      return (dim_this_str !== dim_next_str) ? true : false;
    }
    // Check for synthetic dimensions in last dimension's config
    let syn_rows = [];
    if("synthetic" in last_dim_config
      && Array.isArray(last_dim_config.synthetic)
    ) for(let i=0; i<last_dim_config.synthetic.length; i++) {

      // Loop through rows
      for(let j=0; j<rows.length; j++) {
        // Check if this row is last of this dimension
        if(j + 1 === rows.length  // Last row in table
        || dim_match(rows[j].dimensions, rows[j+1].dimensions, dim_count)) {  // Compare this row's dimensions to next row's dimensions

          // Create synthetic dimension for this and parent dimension(s)
          let syn = last_dim_config.synthetic[i];
          let row_id_json = JSON.parse(rows[j].dimensions[0].id);
          let syn_row = {
            dimensions: JSON.parse(JSON.stringify(rows[j].dimensions)),
            periods: Array(rows[j].periods.length),
            format: ("format" in syn) ? syn.format : null,
            total: 0
          };

          // Function to create ID for each referenced dimension value
          let get_id = function(id_json, depth, dimension_id) {
            // Put dimension_id in row_id
            id_json.splice(depth-1, 1, dimension_id);
            // Output row_id as string
            return JSON.stringify(id_json);
          }

          // Update synthetic row dimensions object
          for(let k=0; k<syn_row.dimensions.length; k++) {
            // Create synthetic row ID
            let syn_id = "synthetic_"+i;
            syn_row.dimensions[k].id =
              get_id(row_id_json, dim_count, syn_id);
            // Add name to row (last dimension only)
            if(k + 1 === syn_row.dimensions.length) {
              syn_row.dimensions[k].value = syn_id;
              syn_row.dimensions[k].formatted = ("name" in syn)
                ? syn.name
                : syn_id;
            }
          }

          // Calculate value for each period
          for(let k=0; k<syn_row.periods.length; k++) {
            // If `value` is defined, get row ID then look up value
            let syn_dim_id = ("value" in syn)
              ? get_id(row_id_json, dim_count, syn.value)
              : null;
            // Get values
            let syn_dim_value = 0;
            let syn_tot_value = 0;
            if(syn_dim_id !== null
            && syn_dim_id in rows_object) {
              let row = rows_object[syn_dim_id];
              // Period value
              if(typeof row.periods[k].value !== "undefined") {
                syn_dim_value = row.periods[k].value;
              }
              // Total value
              if(typeof row.total !== "undefined") {
                syn_tot_value = row.total;
              }
            }

            // Math
            if("math" in syn && Array.isArray(syn.math)) {
              for(let m=0; m<syn.math.length; m++) {
                let operation = syn.math[m];
                // Handle shorthand format
                if(!Array.isArray(operation)) operation = [ operation ];
                // Get operation dimension value
                let operatee = get_id(row_id_json, dim_count, operation[0])
                let dim_value = (operatee in rows_object
                && typeof rows_object[operatee].periods[k].value !== "undefined")
                  ? rows_object[operatee].periods[k].value
                  : 0;
                // Get operation total value
                let tot_value = (operatee in rows_object
                && typeof rows_object[operatee].total !== "undefined")
                  ? rows_object[operatee].total
                  : 0;
                // Get operator
                let operator = (operation.length > 1)
                  ? operation[1]
                  : "add";
                // Perform operation
                switch(operator) {
                  // Multiply
                  case "multiply":
                    syn_dim_value *= dim_value;
                    syn_tot_value *= tot_value;
                    break;

                  // Divide
                  case "divide":
                    if(dim_value !== 0) syn_dim_value /= dim_value;
                    if(tot_value !== 0) syn_tot_value /= tot_value;
                    break;

                  // Subtract
                  case "subtract":
                    syn_dim_value -= dim_value;
                    syn_tot_value -= tot_value;
                    break;

                  // Default (add)
                  default:
                    syn_dim_value += dim_value;
                    syn_tot_value += tot_value;
                }
              }
            }

            // Add data to synthetic dimension row
            syn_row.periods[k] = {
              value: syn_dim_value,
              // Replace `0` with blank
              formatted: (syn_dim_value === 0)
                ? ""
                // Format
                : ("format" in syn)
                  ? format_syn(syn_dim_value, syn.format)
                  : round(syn_dim_value)
            };
            syn_row.total = syn_tot_value

          }

          // Add synthetic dimension row to `rows` array
          syn_rows.push(syn_row);

        }
      }

    }
    // Add synthetic rows to rows array
    rows = rows.concat(syn_rows);
    // Remove excluded rows (do this now so they can be used in synthetic dimensions)
    for(let i=rows.length-1; i>= 0; i--) {
      if(rows[i].exclude) rows.splice(i, 1);
    }
    // Re-sort rows after adding synthetic dimensions
    rows = sortRows(rows);

    // Create header cells from dimensions
    let tableHeadersCells = [];
    for(let i=0; i<headers.dimensions.length; i++) {
      tableHeadersCells.push(
        <th key={"dimension" + i} className="dimension">
          {headers.dimensions[i].name}
        </th>
      );
    }
    // Create header cells from periods
    for(let i=0; i<headers.periods.length; i++) {
      tableHeadersCells.push(
        <th key={"period" + i} className="period">
          {headers.periods[i].name}
        </th>
      );
    }
    // Add `Total` column to header
    if(period_ids.length > 1) tableHeadersCells.push(
      <th key={"total"} className="total">
        Total
      </th>
    );

    // Check if there are multiple dimensions
    let hasGroups = (rows.length > 0 && rows[0].dimensions.length > 1)
      ? true : false;
    let rowspans = (hasGroups)
      ? new Array(rows[0].dimensions.length) : [ 1 ];
    // Create table rows from data rows
    let tableRows = [];
    for(let i=0; i<rows.length; i++) {
      let tableRowCells = [];
      let rowDimensions = [];

      // Check if row shares main dimension with next row, if so group
      let sameAsNext = (hasGroups && i+1 < rows.length
        && rows[i].dimensions[0].value
        === rows[i+1].dimensions[0].value) ? true : false;

      // Create row cells from dimensions
      for(let j=0; j<rows[i].dimensions.length; j++) {

        // Combine duplicate dimension cells in groups
        if(typeof rowspans[j] === "undefined") rowspans[j] = 1;
        if(rowspans[j] === 1) {
          // Loop through subsequent rows
          for(let k=i+1; k<rows.length; k++) {
            // Skip if next row is different
            if(rows[i].dimensions[j].value
              !== rows[k].dimensions[j].value) break;
            // Skip if previous dimension is different
            if(j > 0 && rows[i].dimensions[j-1].value
              !== rows[k].dimensions[j-1].value) break;
            // Count each row that matches
            rowspans[j]++;
          }
        } else {
          // Increment rowspan down
          rowspans[j]--;
          // Hide cell (part of earlier rowspan)
          continue;
        }

        // Add to row
        rowDimensions.push(rows[i].dimensions[j].formatted);
        tableRowCells.push(
          <td
            key={"dimension" + j}
            rowSpan={rowspans[j]}
            className={
              "dimension" +
              ((j!==0 && sameAsNext) ? " group" : "") +
              ((i + rowspans[j] === rows.length) ? " lastGroup" : "")
            }>
            {rows[i].dimensions[j].formatted}
          </td>
        );

      }
      // Create row cells from periods
      for(let j=0; j<rows[i].periods.length; j++) {
        tableRowCells.push(
          <td
            key={"periods" + j}
            className={
              "period " +
              ((sameAsNext) ? "group" : "")
            }
            title={rowDimensions.join(", ")+" ("+headers.periods[j].name+")"}>
            {rows[i].periods[j].formatted}
          </td>
        );
      }
      // Create row cell from total
      if(period_ids.length > 1) tableRowCells.push(
        <td
          key={"total"}
          className={
            "total " +
            ((sameAsNext) ? "group" : "")
          }
          title={rowDimensions.join(", ")+" (Total)"}>
          {("format" in rows[i] && rows[i].format !== null)
            ? (rows[i].total !== 0)
              ? format_syn(rows[i].total, rows[i].format)
              : ""
            : round(rows[i].total)}
        </td>
      );
      // Add row cells to table rows
      tableRows.push(
        <tr key={i} className={(hasGroups) ? "groups" : ""}>
          {tableRowCells}
        </tr>
      );
    }
    // Create `Total` row cells
    let totalCells = [];
    for(let i=0; i<period_totals.length; i++) {
      totalCells.push(
        <td
          key={i}
          className="total"
          title={headers.periods[i].name+" (Total)"}>
          {round(period_totals[i])}
        </td>
      );
    }
    // Add grand total row
    let grandTotal = period_totals.reduce((a, b) => a+b, 0);
    if(period_ids.length > 1) totalCells.push(
      <td
        key="grandtotal"
        className="total"
        title="Grand Total">
        {round(grandTotal)}
      </td>
    );
    // Add `Total` row
    let tableFooter = (tableRows.length <= 1) ? null : (
      <tr key={"total"}>
        <td colSpan={headers.dimensions.length} className="total">
          Total
        </td>
        {totalCells}
      </tr>
    );
    // Hide totals footer if querying metrics by `list_id` (multiple metrics shown, should not be totalled)
    if(!("query" in report_config)
    || !("metric_id" in report_config.query)
    || report_config.query.metric_id == null) tableFooter = null;

    // Create page body
    let body = (
      <div className="reportTableWrapper">
        <table>
          <thead>
            <tr>
              {tableHeadersCells}
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
          <tfoot>
            {tableFooter}
          </tfoot>
        </table>
      </div>
    );

    // Handle empty data
    if(tableRows.length === 0) {
      body = (
        <div className="noData">
          There is no data for this time period.
          Please select a different range.
        </div>
      );
    }

    // Update page title
    this.props.setTitle("Report: "+
      this.props.page.analytics.reports[this.props.report_id].name);

    // Return data table
    return <div>
      <div className="reportTitle">
        {this.props.page.analytics.reports[this.props.report_id].name}
      </div>
      <ReportButtons
        headers={headers}
        rows={rows}
        report_id={this.props.report_id}
        changeRange={this.changeRange.bind(this)}
        range={this.state.range}
        ranges={this.state.ranges}
        page={this.props.page}/>
      {body}
    </div>;
  }
}

class Reports extends Component {
  render() {
    // Get reports from page
    const page = this.props.page;
    const reports = ("reports" in page.analytics)
      ? page.analytics.reports : {};

    // Sort reports
    let report_ids = Object.keys(reports)
      .sort((a, b) => {
        // By name
        if("name" in reports[a]) {
          if("name" in reports[b]) {
            return reports[a].name.localeCompare(reports[b].name);
          } else return -1;
        } else if("name" in reports[b]) return 1;
        // By ID
        return a.localeCompare(b);
      });

    // Prepare data
    let ivdata = {
      token: this.props.token,
      groups: this.props.groups,
      user: this.props.user,
      page: page
    };

    // Create report components
    let report_components = [];
    for(let i=0; i<report_ids.length; i++) {
      // Check if report is enabled
      let report = reports[report_ids[i]];
      if("if" in report && !Util.evalIf(report.if, ivdata)) continue;

      // Add report button to array
      report_components.push(
        <Link
          key={i}
          className="card grid-6 grid-xs-12"
          style={{ textDecoration: "none", color: "inherit" }}
          to={"./reports/"+report_ids[i]}>
          <div className="reportName">
            {report.name}
          </div>
        </Link>
      );
    }

    // Return components or message
    return (report_components.length > 0) ? report_components : (
      <div className="card grid-6 grid-xs-12">
        <div className="reportName">
          No Reports Available
        </div>
      </div>
    );
  }
}

class ReportsRouter extends Component {
  render() {
    let component, className;
    let report_id = this.props.report_id;
    // Check for report_id
    if(!report_id) {
      // Show links to reports if no `report_id` is specified
      className = "Reports";
      component = <Reports
        token={this.props.token}
        page={this.props.page}
        groups={this.props.groups}
        user={this.props.user}/>;
    } else {
      // Check for report_id in `page`
      if(!("analytics" in this.props.page)
      || !("reports" in this.props.page.analytics)
      || !(report_id in this.props.page.analytics.reports)) {
        return <Redirect to="../reports"/>;
      }

      // Check if report is enabled
      let ivdata = {
        token: this.props.token,
        groups: this.props.groups,
        user: this.props.user,
        page: this.props.page
      };
      let report = this.props.page.analytics.reports[report_id];
      if("if" in report && !Util.evalIf(report.if, ivdata)) {
        return <Redirect to="../reports"/>;
      };

      // Show data
      className = "Report";
      component = (
        <Report
          token={this.props.token}
          setTitle={this.props.setTitle}
          report_id={report_id}
          page={this.props.page}
          users={this.props.users}/>
        );
      }

    return (
      <div
        className={className + " cards"}>
        <Spacer position="top"/>
        {component}
        <Footer
          token={this.props.token}/>
      </div>
    );
  }
}

class ReportsPageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  redirect(path) {
    this.setState({ redirect: path });
  }

  render() {
    // Redirect
    if(this.state.redirect !== false) {
      return <Redirect to={this.state.redirect} push={true}/>;
    }

    // Buttons
    let buttons = {
      back: {
        icon: "arrow-back",
        title: "Back",
        position: "topleft",
        action: {
          function: "link",
          link: (!this.props.report_id)
            ? "/p/"+this.props.page.id
            : "../reports"
        }
      }
    };

    // Reports
    let reports = (
      <ReportsRouter
        token={this.props.token}
        setTitle={this.props.setTitle}
        report_id={this.props.report_id}
        page={this.props.page}
        groups={this.props.groups}
        user={this.props.user}
        users={this.props.users}/>
    );

    // Org status
    if(this.props.status.status !== "enabled") {
      // Disable buttons other than "back" button
      buttons = { back: buttons.back };
      // Show status instead of reports
      reports = (
        <Status
          status={this.props.status.status}
          description={this.props.status.description}
          token={this.props.token}/>
      );
    }

    // Page content
    return <div className="ReportsPage page">
      <InstallPrompt
        token={this.props.token}
        showDownloadModal={this.props.showDownloadModal.bind(this)}/>
      <Updater
        user={this.props.user}
        token={this.props.token}
        updateToken={this.props.updateToken.bind(this)}/>
      <Buttons
        redirect={this.redirect.bind(this)}
        buttons={buttons}/>
      <Header
        color={this.props.page.color}
        icon={(this.props.page.icon) ? this.props.page.icon : "list-box"}
        title={(this.props.page.name) ? this.props.page.name+": Reports" : ""}/>
      {reports}
    </div>
  }
}

class ReportsPage extends Component {
  render() {
    // Create payload
    let payload = {
      token: this.props.token,
      page_id: this.props.page_id
    };

    // Create query
    const query = `query
      GetOrg($token: String!, $page_id: ID!) {
        getOrg(token: $token) {
          status
          groups {
            id
            name
            admin
          }
          pages(page_id: $page_id) {
            id
            name
            icon
            color
            config
            analytics
            lists {
              id
            }
          }
          users {
            id
            name
            email
            group_ids
          }
        }
      }`;

    // Get page from GraphQL
    return (
      <Connect
        query={graphqlOperation(query, payload)}>
        {({ data, errors }) => {
          // Handle errors
          if(errors.length > 0) {
            if(errors[0].message.indexOf("token") >= 0) {
              return <Status status="logout"/>;
            } else return <Status status="error"/>;
          }
          if(data === null) return <Status status="link" link="/orgs"/>;

          // Get page data from response
          let page = {};
          if(data && "getOrg" in data) {
            // Set page data
            if(data.getOrg.pages.length > 0) page = data.getOrg.pages[0];
            // Redirect to orgs if invalid page or page from another org
            else return <Status status="link" link="/orgs"/>;
          } else {
            // Indicate loading
            return <Status status="loading"/>;
          }

          // Status
          const status = ("getOrg" in data)
            ? JSON.parse(data.getOrg.status) : {};

          // Get users from response
          let users = [];
          if(data && "getOrg" in data) {
            users = data.getOrg.users;
          }

          // Groups
          let groups = [];
          if(data && "getOrg" in data && data.getOrg.groups.length > 0) {
            groups = data.getOrg.groups;
          }

          // Parse config
          page.config = (!("config" in page) || page.config === null)
            ? {}
            : (typeof page.config !== "object")
              ? JSON.parse(page.config)
              : page.config;

          // Parse analytics
          if("analytics" in page && page.analytics !== null) {
            if(typeof page.analytics !== "object") {
              // Insert config variables
              page.analytics = JSON.parse(Util.insertConfigVariables(page.config, page.analytics));
            }
          } else page.analytics = {};

          // Show content
          return <ReportsPageContent
            showDownloadModal={this.props.showDownloadModal.bind(this)}
            user={this.props.user}
            groups={groups}
            token={this.props.token}
            updateToken={this.props.updateToken.bind(this)}
            status={status}
            setTitle={this.props.setTitle}
            report_id={this.props.report_id}
            page={page}
            users={users}/>
        }}
      </Connect>
    );
  }
}

export default ReportsPage;
