/* eslint-disable */
// This is a manually created version of `aws-exports.js` to allow for prod end dev environments

// Check if Node is in production environment
const isProd = (process.env.NODE_ENV === "production");
if(!isProd) console.log("NODE_ENV: " + process.env.NODE_ENV);

// Change whether production or development API is used based on Node environment
const awsmobile = (isProd) ?
  // Production API
  {
      "aws_appsync_graphqlEndpoint": "https://vicsp2hjfbbl7dgo5cw7jl6lju.appsync-api.us-west-2.amazonaws.com/graphql",
      "aws_appsync_region": "us-west-2",
      "aws_appsync_authenticationType": "API_KEY",
      "aws_appsync_apiKey": "da2-c7uucq5uxngctdldc6eyugd4py"
  } :
  // Development API
  {
    "aws_appsync_graphqlEndpoint": "https://7wht37t5sbfbdmznir22hw5djq.appsync-api.us-west-2.amazonaws.com/graphql",
      "aws_appsync_region": "us-west-2",
      "aws_appsync_authenticationType": "API_KEY",
      "aws_appsync_apiKey": "da2-pthp65vttnhlzaw4fm6lgevffu"
  };

export default awsmobile;