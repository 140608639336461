import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Status from "../Status/Status";
import { Header, Footer, Spacer } from "../Header/Header";
import Icon from "../Icon/Icon";
import { Buttons } from "../Button/Button";
import "./OrgsPage.css";
import Analytics from '../analytics';
import { InstallPrompt } from "../InstallPage/InstallPage";
import Updater from "../Updater/Updater";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import awsmobile from '../amplify-config';
Amplify.configure(awsmobile);

class Org extends Component {
  componentDidMount() {
    // Update token if new token is passed
    let token = this.props.token;
    let new_token = this.props.new_token;
    if(token !== new_token && new_token !== null) {
      this.updateToken = this.props.updateToken.bind(this);
      this.updateToken(new_token);
    }
  }

  setOrg() {
    // Validate org_id
    if(!this.props.org_id) return;
    // Analytics
    Analytics.event("org_changed", { event_category: "session" });
    Analytics.properties({ org_id: this.props.org_id });
    // Change org
    this.props.setOrg(this.props.org_id)
  }

  render() {
    return (
      <div
        className="card grid-6 grid-xs-12"
        onClick={this.setOrg.bind(this)}>
        <div className="orgName">
          {this.props.org_name}
        </div>
      </div>
    );
  }
}

class Orgs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false
    }
  }

  setOrg(org_id) {
    // Ignore if org_id is missing
    if(!org_id) return;
    // Show loading
    this.setState({ loading: true });
    // Get new token from API with org_id
    const submit = async () => {
      // Create mutation
      let mutation = `mutation
        UpdateToken($token: String!, $org_id: ID) {
          updateToken(token: $token, org_id: $org_id) {
            token
            group_ids
          }
        }`;
      // Create payload
      let payload = {
        token: this.props.token,
        org_id: org_id
      };
      // Submit to API
      return await API.graphql(graphqlOperation(mutation, payload));
    }
    submit().then(
      (res)=>{
        let token = res.data.updateToken.token;
        let group_ids = res.data.updateToken.group_ids;
        // Update state in parent
        this.props.changedOrg(token, org_id, group_ids, () => {
          // Redirect to login
          this.setState({ redirect: true });
        });
      },
      (err)=>{ console.log(err) }
    );
  }

  render() {
    // Redirect to home page
    if(this.state.redirect) return <Redirect to="/"/>;
    // Prevent calling Connect while loading, during redirect
    if(this.state.loading) return <Status status="loading"/>;

    // Create mutation
    let query = `query
      GetUser($token: String) {
        getUser(token: $token) {
          new_token
          orgs {
            name
            id
          }
        }
      }`;

    // Create payload
    let payload = { token: this.props.token };

    // Get data from GraphQL
    return (
      <Connect
        query={graphqlOperation(query, payload)}>
        {({ data: { getUser }, errors }) => {
          // Handle errors
          if(errors.length > 0) {
            if(errors[0].message.indexOf("token") >= 0) {
              return <Status status="logout"/>;
            } else return <Status status="error"/>;
          }

          // Handle loading
          if(!getUser || this.state.loading) {
            return <Status status="loading"/>;
          }

          // If no orgs
          if(getUser.orgs.length === 0) {
            return <Org org_name="You do not have access to any orgs"/>
          }

          // Sort orgs
          let userOrgs = getUser.orgs;
          userOrgs.sort((a, b) => {
            let sort = 0;
            if("name" in a) {
              if("name" in b) sort = a.name.localeCompare(b.name);
              else sort = -1;
            } else if("name" in b) sort = 1;
            return (sort !== 0) ? sort : a.id.localeCompare(b.id);
          });

          // Loop through orgs
          let orgs = [];
          for(let i=0; i<userOrgs.length; i++) {
            let org = userOrgs[i];
            orgs.push(
              <Org
                key={i}
                org_id={org.id}
                org_name={org.name}
                token={this.props.token}
                new_token={(i === 0 && "new_token" in getUser) ? getUser.new_token : null}
                updateToken={this.props.updateToken.bind(this)}
                setOrg={this.setOrg.bind(this)}/>
            );
          }

          // Show message if no orgs
          const empty = (
            <div className="empty">
              <Icon name="rocket"/>
              <h1>No organizations to show</h1>
              <h2>You are not a member of any organization</h2>
            </div>
          );

          // Return orgs
          return (
            <div
              className="cards">
              <Spacer position="top"/>
              { (orgs.length > 0) ? orgs : empty }
              <Footer
                token={this.props.token}/>
            </div>
          );
        }}
      </Connect>
    );
  }
}

class OrgsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  redirect(path) {
    this.setState({ redirect: path });
  }

  render() {
    // Redirect
    if(this.state.redirect !== false) {
      return <Redirect to={this.state.redirect} push={true}/>;
    }

    // Buttons
    const buttons = {
      settings: {
        icon: "settings",
        position: "topright",
        title: "Settings",
        buttons: {
          logout: {
            title: "Log Out",
            action: {
              function: "logout",
              callback: {
                function: "link",
                link: "/login"
              }
            }
          }
        }
      }
    };

    // Return content
    return (
      <div
        className="OrgsPage page">
        <InstallPrompt
          token={this.props.token}
          showDownloadModal={this.props.showDownloadModal.bind(this)}/>
        <Updater
          user={this.props.user}
          token={this.props.token}
          updateToken={this.props.updateToken.bind(this)}/>
        <Buttons
          redirect={this.redirect.bind(this)}
          buttons={buttons}/>
        <Header/>
        <Orgs
          token={this.props.token}
          updateToken={this.props.updateToken.bind(this)}
          changedOrg={this.props.changedOrg.bind(this)}/>
      </div>
    );
  }
}

export default OrgsPage;
