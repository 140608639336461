import React, { Component } from 'react';
import "./Icon.css";
import icon from './icons';
import ionicon from './ionicons';

class Icon extends Component {
  getName() {
    // Separate icon name from prefix (`ios-``, `md-`, or no prefix)
    let lowercase = (this.props.name) ? this.props.name.toLowerCase() : "";
    const match = lowercase.toLowerCase()
      .match(/^(ios-|md-|logo-|)(.*)$/);
    // Validate name
    if(match === null || match[2] === "") return "";
    // Get platform name, default to iOS
    const platform = (match[1] !== "")
      ? match[1].replace("-", "") : "ios"
    // Get icon name
    const name = match[2];
    // Return
    return [ platform, name ];
  }

  render() {
    // Get platform (if specified) and shape name
    const info = this.getName();
    const platform = info[0];
    const name = info[1];

    // Check if name is a standard icon
    let svg = icon(name);
    // Check if name is an ionicon
    if(!svg) svg = ionicon(platform+"-"+name);

    // Handle unknown icon name
    if(!svg) svg = "";

    return (
      <span
        className="icon"
        name={name}>
        {svg}
      </span>
    );
  }
}

export default Icon;
