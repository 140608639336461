// eslint-disable
// this is an auto generated file. This will be overwritten

export const addItemZones = `mutation AddItemZones(
  $id: ID!
  $list_id: ID!
  $page_id: ID
  $token: String!
  $zones: [ZoneInput]
) {
  addItemZones(
    id: $id
    list_id: $list_id
    page_id: $page_id
    token: $token
    zones: $zones
  ) {
    id
    list_id
    created_at
    updated_at
    archived_at
    bookmark
    cue
    data
    parent {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    parent_id
    parent_list_id
    parent_page_id
    children {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    children_archived {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    zones {
      list_id
      required
      added_at
    }
  }
}
`;
export const acceptAgreements = `mutation AcceptAgreements($agreements: [String!]!, $token: String!) {
  acceptAgreements(agreements: $agreements, token: $token) {
    token
    user_id
    org_id
    user {
      id
      name
      email
      phone
      employee_id
      active_at
      login_at
      password_at
      orgs {
        id
        name
        groups {
          id
          name
          admin
        }
        users {
          id
          name
          email
          phone
          employee_id
          active_at
          login_at
          password_at
          orgs {
            id
            name
            config
            status
            new_token
          }
          group_ids
          new_token
        }
        pages {
          id
          name
          icon
          color
          description
          config
          analytics
          lists {
            id
            new_token
          }
          list_ids
          new_token
        }
        config
        status
        billing {
          billing_id
          sources {
            id
            type
            bank_name
            last4
            brand
            exp_month
            exp_year
          }
        }
        new_token
      }
      group_ids
      new_token
    }
    org {
      id
      name
      groups {
        id
        name
        admin
      }
      users {
        id
        name
        email
        phone
        employee_id
        active_at
        login_at
        password_at
        orgs {
          id
          name
          groups {
            id
            name
            admin
          }
          users {
            id
            name
            email
            phone
            employee_id
            active_at
            login_at
            password_at
            group_ids
            new_token
          }
          pages {
            id
            name
            icon
            color
            description
            config
            analytics
            list_ids
            new_token
          }
          config
          status
          billing {
            billing_id
          }
          new_token
        }
        group_ids
        new_token
      }
      pages {
        id
        name
        icon
        color
        description
        config
        analytics
        lists {
          id
          items {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          items_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          new_token
        }
        list_ids
        new_token
      }
      config
      status
      billing {
        billing_id
        sources {
          id
          type
          bank_name
          last4
          brand
          exp_month
          exp_year
        }
      }
      new_token
    }
    group_ids
    kiosk_id
  }
}
`;
export const archiveItem = `mutation ArchiveItem(
  $token: String!
  $page_id: ID
  $list_id: ID!
  $id: ID!
  $data: String
  $archived_at: Int
) {
  archiveItem(
    token: $token
    page_id: $page_id
    list_id: $list_id
    id: $id
    data: $data
    archived_at: $archived_at
  ) {
    id
    list_id
    created_at
    updated_at
    archived_at
    bookmark
    cue
    data
    parent {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    parent_id
    parent_list_id
    parent_page_id
    children {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    children_archived {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    zones {
      list_id
      required
      added_at
    }
  }
}
`;
export const createItem = `mutation CreateItem(
  $token: String!
  $page_id: ID
  $list_id: ID!
  $parent_id: ID
  $parent_list_id: ID
  $parent_page_id: ID
  $data: String
  $cue: Int
  $zones: [ZoneInput]
) {
  createItem(
    token: $token
    page_id: $page_id
    list_id: $list_id
    parent_id: $parent_id
    parent_list_id: $parent_list_id
    parent_page_id: $parent_page_id
    data: $data
    cue: $cue
    zones: $zones
  ) {
    id
    list_id
    created_at
    updated_at
    archived_at
    bookmark
    cue
    data
    parent {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    parent_id
    parent_list_id
    parent_page_id
    children {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    children_archived {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    zones {
      list_id
      required
      added_at
    }
  }
}
`;
export const createToken = `mutation CreateToken($email: String!, $password: String!) {
  createToken(email: $email, password: $password) {
    token
    user_id
    org_id
    user {
      id
      name
      email
      phone
      employee_id
      active_at
      login_at
      password_at
      orgs {
        id
        name
        groups {
          id
          name
          admin
        }
        users {
          id
          name
          email
          phone
          employee_id
          active_at
          login_at
          password_at
          orgs {
            id
            name
            config
            status
            new_token
          }
          group_ids
          new_token
        }
        pages {
          id
          name
          icon
          color
          description
          config
          analytics
          lists {
            id
            new_token
          }
          list_ids
          new_token
        }
        config
        status
        billing {
          billing_id
          sources {
            id
            type
            bank_name
            last4
            brand
            exp_month
            exp_year
          }
        }
        new_token
      }
      group_ids
      new_token
    }
    org {
      id
      name
      groups {
        id
        name
        admin
      }
      users {
        id
        name
        email
        phone
        employee_id
        active_at
        login_at
        password_at
        orgs {
          id
          name
          groups {
            id
            name
            admin
          }
          users {
            id
            name
            email
            phone
            employee_id
            active_at
            login_at
            password_at
            group_ids
            new_token
          }
          pages {
            id
            name
            icon
            color
            description
            config
            analytics
            list_ids
            new_token
          }
          config
          status
          billing {
            billing_id
          }
          new_token
        }
        group_ids
        new_token
      }
      pages {
        id
        name
        icon
        color
        description
        config
        analytics
        lists {
          id
          items {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          items_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          new_token
        }
        list_ids
        new_token
      }
      config
      status
      billing {
        billing_id
        sources {
          id
          type
          bank_name
          last4
          brand
          exp_month
          exp_year
        }
      }
      new_token
    }
    group_ids
    kiosk_id
  }
}
`;
export const createUser = `mutation CreateUser(
  $token: String!
  $email: String!
  $name: String
  $phone: String
  $employee_id: String
  $group_ids: [ID]
) {
  createUser(
    token: $token
    email: $email
    name: $name
    phone: $phone
    employee_id: $employee_id
    group_ids: $group_ids
  ) {
    id
    name
    email
    phone
    employee_id
    active_at
    login_at
    password_at
    orgs {
      id
      name
      groups {
        id
        name
        admin
      }
      users {
        id
        name
        email
        phone
        employee_id
        active_at
        login_at
        password_at
        orgs {
          id
          name
          groups {
            id
            name
            admin
          }
          users {
            id
            name
            email
            phone
            employee_id
            active_at
            login_at
            password_at
            group_ids
            new_token
          }
          pages {
            id
            name
            icon
            color
            description
            config
            analytics
            list_ids
            new_token
          }
          config
          status
          billing {
            billing_id
          }
          new_token
        }
        group_ids
        new_token
      }
      pages {
        id
        name
        icon
        color
        description
        config
        analytics
        lists {
          id
          items {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          items_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          new_token
        }
        list_ids
        new_token
      }
      config
      status
      billing {
        billing_id
        sources {
          id
          type
          bank_name
          last4
          brand
          exp_month
          exp_year
        }
      }
      new_token
    }
    group_ids
    new_token
  }
}
`;
export const invokeIntegration = `mutation InvokeIntegration($token: String!, $id: ID!, $data: String) {
  invokeIntegration(token: $token, id: $id, data: $data) {
    data
  }
}
`;
export const recordEvent = `mutation RecordEvent(
  $token: String!
  $list_id: ID!
  $metric_id: ID!
  $page_id: ID!
  $value: Float
  $tags: [EventTagInput]
) {
  recordEvent(
    token: $token
    list_id: $list_id
    metric_id: $metric_id
    page_id: $page_id
    value: $value
    tags: $tags
  ) {
    list_id
    metric_id
    value
    tags {
      id
      value
    }
  }
}
`;
export const removeDevice = `mutation RemoveDevice($token: String!, $device_id: ID!) {
  removeDevice(token: $token, device_id: $device_id)
}
`;
export const removeUser = `mutation RemoveUser($token: String!, $id: ID!) {
  removeUser(token: $token, id: $id) {
    id
    name
    email
    phone
    employee_id
    active_at
    login_at
    password_at
    orgs {
      id
      name
      groups {
        id
        name
        admin
      }
      users {
        id
        name
        email
        phone
        employee_id
        active_at
        login_at
        password_at
        orgs {
          id
          name
          groups {
            id
            name
            admin
          }
          users {
            id
            name
            email
            phone
            employee_id
            active_at
            login_at
            password_at
            group_ids
            new_token
          }
          pages {
            id
            name
            icon
            color
            description
            config
            analytics
            list_ids
            new_token
          }
          config
          status
          billing {
            billing_id
          }
          new_token
        }
        group_ids
        new_token
      }
      pages {
        id
        name
        icon
        color
        description
        config
        analytics
        lists {
          id
          items {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          items_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          new_token
        }
        list_ids
        new_token
      }
      config
      status
      billing {
        billing_id
        sources {
          id
          type
          bank_name
          last4
          brand
          exp_month
          exp_year
        }
      }
      new_token
    }
    group_ids
    new_token
  }
}
`;
export const saveDevice = `mutation SaveDevice($token: String!, $device_id: ID!, $n_token: String) {
  saveDevice(token: $token, device_id: $device_id, n_token: $n_token)
}
`;
export const sendNotification = `mutation SendNotification(
  $token: String!
  $message: String!
  $user_id: ID
  $user_ids: [ID]
  $group_id: ID
  $page_id: ID
  $page_name: String
) {
  sendNotification(
    token: $token
    message: $message
    user_id: $user_id
    user_ids: $user_ids
    group_id: $group_id
    page_id: $page_id
    page_name: $page_name
  ) {
    message
    sent_at
    user_id
    group_id
  }
}
`;
export const sendPassword = `mutation SendPassword(
  $email: String!
  $name: String
  $phone: String
  $create: Boolean
) {
  sendPassword(email: $email, name: $name, phone: $phone, create: $create)
}
`;
export const setPassword = `mutation SetPassword($prt: String!, $password: String!) {
  setPassword(prt: $prt, password: $password) {
    token
    user_id
    org_id
    user {
      id
      name
      email
      phone
      employee_id
      active_at
      login_at
      password_at
      orgs {
        id
        name
        groups {
          id
          name
          admin
        }
        users {
          id
          name
          email
          phone
          employee_id
          active_at
          login_at
          password_at
          orgs {
            id
            name
            config
            status
            new_token
          }
          group_ids
          new_token
        }
        pages {
          id
          name
          icon
          color
          description
          config
          analytics
          lists {
            id
            new_token
          }
          list_ids
          new_token
        }
        config
        status
        billing {
          billing_id
          sources {
            id
            type
            bank_name
            last4
            brand
            exp_month
            exp_year
          }
        }
        new_token
      }
      group_ids
      new_token
    }
    org {
      id
      name
      groups {
        id
        name
        admin
      }
      users {
        id
        name
        email
        phone
        employee_id
        active_at
        login_at
        password_at
        orgs {
          id
          name
          groups {
            id
            name
            admin
          }
          users {
            id
            name
            email
            phone
            employee_id
            active_at
            login_at
            password_at
            group_ids
            new_token
          }
          pages {
            id
            name
            icon
            color
            description
            config
            analytics
            list_ids
            new_token
          }
          config
          status
          billing {
            billing_id
          }
          new_token
        }
        group_ids
        new_token
      }
      pages {
        id
        name
        icon
        color
        description
        config
        analytics
        lists {
          id
          items {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          items_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          new_token
        }
        list_ids
        new_token
      }
      config
      status
      billing {
        billing_id
        sources {
          id
          type
          bank_name
          last4
          brand
          exp_month
          exp_year
        }
      }
      new_token
    }
    group_ids
    kiosk_id
  }
}
`;
export const stripeAttachSource = `mutation StripeAttachSource($token: String!, $payment_source_id: String!) {
  stripeAttachSource(token: $token, payment_source_id: $payment_source_id) {
    id
    type
    bank_name
    last4
    brand
    exp_month
    exp_year
  }
}
`;
export const stripeAttachPlaid = `mutation StripeAttachPlaid(
  $token: String!
  $bank_token: String!
  $bank_account_id: String!
  $environment: String
) {
  stripeAttachPlaid(
    token: $token
    bank_token: $bank_token
    bank_account_id: $bank_account_id
    environment: $environment
  ) {
    id
    type
    bank_name
    last4
    brand
    exp_month
    exp_year
  }
}
`;
export const updateItem = `mutation UpdateItem(
  $token: String!
  $page_id: ID!
  $list_id: ID!
  $id: ID!
  $data: String
  $cue: Int
) {
  updateItem(
    token: $token
    page_id: $page_id
    list_id: $list_id
    id: $id
    data: $data
    cue: $cue
  ) {
    id
    list_id
    created_at
    updated_at
    archived_at
    bookmark
    cue
    data
    parent {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    parent_id
    parent_list_id
    parent_page_id
    children {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    children_archived {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    zones {
      list_id
      required
      added_at
    }
  }
}
`;
export const updateMetric = `mutation UpdateMetric(
  $token: String!
  $page_id: ID
  $list_id: ID!
  $metric_id: ID!
  $tags: [EventTagInput]
  $value: Float
  $year: Int!
  $month: Int!
  $day: Int!
) {
  updateMetric(
    token: $token
    page_id: $page_id
    list_id: $list_id
    metric_id: $metric_id
    tags: $tags
    value: $value
    year: $year
    month: $month
    day: $day
  ) {
    list_id
    metric_id
    tag_id
    tag_value
    year
    month
    day
    value
  }
}
`;
export const updateToken = `mutation UpdateToken($token: String!, $org_id: ID, $kiosk_name: String) {
  updateToken(token: $token, org_id: $org_id, kiosk_name: $kiosk_name) {
    token
    user_id
    org_id
    user {
      id
      name
      email
      phone
      employee_id
      active_at
      login_at
      password_at
      orgs {
        id
        name
        groups {
          id
          name
          admin
        }
        users {
          id
          name
          email
          phone
          employee_id
          active_at
          login_at
          password_at
          orgs {
            id
            name
            config
            status
            new_token
          }
          group_ids
          new_token
        }
        pages {
          id
          name
          icon
          color
          description
          config
          analytics
          lists {
            id
            new_token
          }
          list_ids
          new_token
        }
        config
        status
        billing {
          billing_id
          sources {
            id
            type
            bank_name
            last4
            brand
            exp_month
            exp_year
          }
        }
        new_token
      }
      group_ids
      new_token
    }
    org {
      id
      name
      groups {
        id
        name
        admin
      }
      users {
        id
        name
        email
        phone
        employee_id
        active_at
        login_at
        password_at
        orgs {
          id
          name
          groups {
            id
            name
            admin
          }
          users {
            id
            name
            email
            phone
            employee_id
            active_at
            login_at
            password_at
            group_ids
            new_token
          }
          pages {
            id
            name
            icon
            color
            description
            config
            analytics
            list_ids
            new_token
          }
          config
          status
          billing {
            billing_id
          }
          new_token
        }
        group_ids
        new_token
      }
      pages {
        id
        name
        icon
        color
        description
        config
        analytics
        lists {
          id
          items {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          items_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          new_token
        }
        list_ids
        new_token
      }
      config
      status
      billing {
        billing_id
        sources {
          id
          type
          bank_name
          last4
          brand
          exp_month
          exp_year
        }
      }
      new_token
    }
    group_ids
    kiosk_id
  }
}
`;
export const updateUser = `mutation UpdateUser(
  $token: String!
  $id: ID!
  $email: String
  $name: String
  $phone: String
  $employee_id: String
  $group_ids: [ID]
) {
  updateUser(
    token: $token
    id: $id
    email: $email
    name: $name
    phone: $phone
    employee_id: $employee_id
    group_ids: $group_ids
  ) {
    id
    name
    email
    phone
    employee_id
    active_at
    login_at
    password_at
    orgs {
      id
      name
      groups {
        id
        name
        admin
      }
      users {
        id
        name
        email
        phone
        employee_id
        active_at
        login_at
        password_at
        orgs {
          id
          name
          groups {
            id
            name
            admin
          }
          users {
            id
            name
            email
            phone
            employee_id
            active_at
            login_at
            password_at
            group_ids
            new_token
          }
          pages {
            id
            name
            icon
            color
            description
            config
            analytics
            list_ids
            new_token
          }
          config
          status
          billing {
            billing_id
          }
          new_token
        }
        group_ids
        new_token
      }
      pages {
        id
        name
        icon
        color
        description
        config
        analytics
        lists {
          id
          items {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          items_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          new_token
        }
        list_ids
        new_token
      }
      config
      status
      billing {
        billing_id
        sources {
          id
          type
          bank_name
          last4
          brand
          exp_month
          exp_year
        }
      }
      new_token
    }
    group_ids
    new_token
  }
}
`;
