import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Status from "../Status/Status";
import { Header, Footer, Spacer } from "../Header/Header";
import Form from "../Form/Form";
import Icon from "../Icon/Icon";
import * as Util from "../Utilities/Utilities";
import { Buttons } from "../Button/Button";
import "./HomePage.css";
import { InstallPrompt } from "../InstallPage/InstallPage";
import Updater from "../Updater/Updater";

import Amplify, { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import awsmobile from '../amplify-config';
Amplify.configure(awsmobile);

class Widget extends Component {
  render() {
    // Get config
    let config = ("config" in this.props.page)
      ? JSON.parse(this.props.page.config) : {};
    // Insert config variables
    config = Util.insertConfigVariables(config);

    // Prepare data
    let ivdata = {
      token: this.props.token,
      groups: this.props.groups,
      user: this.props.user,
      page: this.props.page
    };

    // Evaluate `if`
    if(config !== null && !(Util.evalIf(config.if, ivdata))) return "";

    // Process page
    const page = ("page" in this.props) ? this.props.page : {};
    const color = ("color" in page && page.color !== null)
      ? page.color : "";
    const icon = ("icon" in page && page.icon !== null)
      ? page.icon : "list-box";
    const link = ("id" in page)
      // Redirect to reports if main page is disabled
      ? "/p/"+page.id
        +((config !== null && config.page === false) ? "/reports" : "")
      : "/";
    const description = ("description" in page) ? page.description : "";
    const name = ("name" in page) ? page.name : <i>&nbsp;</i>;

    return (
      <Link
        to={{
          pathname: link,
          state: this.props.page
        }}
        className="widget">
        <div
          style={{background: color}}
          className="widget_icon">
          <Icon name={icon}/>
        </div>
        <h1 title={description}>
          {name}
        </h1>
      </Link>
    );
  }
}

class Pages extends Component {
  componentDidMount() {
    // Update token if new token is passed
    let token = ("token" in this.props) ? this.props.token : null;
    let new_token = ("new_token" in this.props) ? this.props.new_token : null;
    if(token !== new_token && new_token !== null) {
      this.updateToken = this.props.updateToken.bind(this);
      this.updateToken(new_token);
    }

    // Update org config
    if(this.props.orgConfig !== null) {
      this.props.updateConfig(JSON.parse(this.props.orgConfig));
    }

    // Update org groups
    this.props.updateGroups(this.props.groups);
  }

  render() {
    // Handle no pages
    let pages = [];
    const empty = (
      <div className="empty">
        <Icon name="warning"/>
        <h1>No tools to show</h1>
        <h2>Contact support to add tools to your account</h2>
      </div>
    );

    // Show org's pages
    for(let i=0; i<this.props.pages.length; i++) {
      let page = this.props.pages[i];
      pages.push(
        <Widget
          key={i}
          groups={this.props.groups}
          user={this.props.user}
          token={this.props.token}
          page={page}/>
      );
    }

    // Sort pages by page name
    pages.sort(function(a, b) {
      // Handle missing page name
      if(typeof a.props.page.name !== "string") return 1;
      // Compare names
      return a.props.page.name.localeCompare(b.props.page.name);
    });

    // Return pages
    return (
      <div
        className="cards">
        <Spacer position="top"/>
        <div className="widgets">
          { (pages.length > 0) ? pages : empty }
        </div>
        <Footer
          token={this.props.token}/>
      </div>
    );
  }
}

class HomePageContent extends Component {
  render() {
    // Create query
    let query = `query
      GetOrg($token: String!) {
        getOrg(token: $token) {
          new_token
          config
          status
          groups {
            id
            name
            admin
          }
          pages {
            id
            name
            icon
            color
            description
            config
          }
        }
      }`;

    // Create payload
    let payload = { token: this.props.token };

    // Get data from GraphQL
    return (
      <Connect
        query={graphqlOperation(query, payload)}>
        {({ data: { getOrg }, errors }) => {
          // Handle errors
          if(errors.length > 0) {
            if(errors[0].message.indexOf("token") >= 0) {
              return <Status status="logout"/>;
            } else return <Status status="error"/>;
          }

          // Handle loading
          if(!getOrg) return <Status status="loading"/>;

          // Account status
          const status = JSON.parse(getOrg.status);
          if(status.status !== "enabled") return (
            <Status
              status={status.status}
              description={status.description}
              token={this.props.token}/>
          );

          // Groups
          let groups = [];
          if(getOrg && getOrg.groups.length > 0) {
            groups = getOrg.groups;
          }

          // Return pages
          return (
            <Pages
              orgConfig={getOrg.config}
              updateConfig={this.props.updateConfig.bind(this)}
              new_token={getOrg.new_token}
              updateToken={this.props.updateToken.bind(this)}
              user={this.props.user}
              token={this.props.token}
              groups={groups}
              updateGroups={this.props.updateGroups.bind(this)}
              pages={getOrg.pages}/>
          );
        }}
      </Connect>
    );
  }
}

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {},
      groups: [],
      form_data: {},
      redirect: false
    };
  }

  closeForm() {
    this.setState({ form_data: {} });
  }

  openForm(params) {
    this.setState({ form_data: params });
  }

  updateConfig(config) {
    this.setState({ config: config });
  }

  updateGroups(groups) {
    this.setState({ groups: groups });
  }

  redirect(path) {
    this.setState({ redirect: path });
  }

  render() {
    // Redirect
    if(this.state.redirect !== false) {
      return <Redirect to={this.state.redirect} push={true}/>;
    }

    // Buttons
    let config = this.state.config;
    // Create buttons in config if undefined
    if(!("buttons" in config)) config.buttons = {};
    // Add buttons to config
    config.buttons.back = {
      icon: "arrow-back",
      title: "Back",
      position: "topleft",
      if: { kiosk: false },
      action: {
        function: "link",
        link: "/orgs"
      }
    };

    // Return content
    return (
      <div
        className="HomePage page">
        <InstallPrompt
          token={this.props.token}
          showDownloadModal={this.props.showDownloadModal.bind(this)}/>
        <Updater
          user={this.props.user}
          token={this.props.token}
          updateToken={this.props.updateToken.bind(this)}/>
        <Form
          openForm={this.openForm.bind(this)}
          redirect={this.redirect.bind(this)}
          closeForm={this.closeForm.bind(this)}
          updateToken={this.props.updateToken.bind(this)}
          form_data={this.state.form_data}/>
        <Buttons
          token={this.props.token}
          buttons={config.buttons}
          user={this.props.user}
          groups={this.state.groups}
          openForm={this.openForm.bind(this)}
          redirect={this.redirect.bind(this)}
          updateToken={this.props.updateToken.bind(this)}
          page={{ color: "#3f9af7" }}/>
        <Header/>
        <HomePageContent
          user={this.props.user}
          token={this.props.token}
          updateGroups={this.updateGroups.bind(this)}
          updateConfig={this.updateConfig.bind(this)}
          updateToken={this.props.updateToken.bind(this)}/>
      </div>
    );
  }
}

export default HomePage;
