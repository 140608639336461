import React, { Component } from 'react';
import Analytics from '../analytics';
import '../LoginPage/LoginPage.css';
import './Agreements.css';

import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsmobile from '../amplify-config';
Amplify.configure(awsmobile);

class Agreements extends Component {
  constructor(props){
    super(props);
    this.state = {
      // Determine if logging in or updating existing agreements
      logging_in: ("login" in this.props
        && typeof this.props.login === "function") ? true : false
    };
  }

  submitForm(event) {
    // Prevent native form submit
    event.preventDefault();

    // Submit to API
    const submit = async () => {
      // Create mutation
      let mutation = `mutation
        AcceptAgreements($agreements: [ String! ]!, $token: String!) {
          acceptAgreements(agreements: $agreements, token: $token) {
            token
          }
        }`;
      // Create payload
      let payload = {
        agreements: [ "terms_and_conditions" ],
        token: this.props.token
      };
      // Submit to API
      return await API.graphql(graphqlOperation(mutation, payload));
    }
    submit()
      .then((res)=>{
        let token = res.data.acceptAgreements.token;

        // Stop loading
        this.setState({ loading: false });

        // Analytics
        Analytics.event("accepted_terms", {
          event_category: "documents",
          event_label: (this.state.logging_in)
            ? "logged_in" : "updated_agreements"
        });

        // Next step
        if(this.state.logging_in) {
          // Log in
          this.props.login(token, this.props.orgs);
        } else {
          // Update token
          this.props.updateToken(token);
        }
      },
      (err)=>{
        // Stop loading
        this.setState({ loading: false });
        // Show error
        this.setState({ error: "Please try again" });
      });
  }

  trackTCLink() {
    Analytics.event("opened_terms", {
      event_category: "documents",
      event_label: (this.state.logging_in)
        ? "logged_in" : "updated_agreements"
    });
  }

  render() {
    // Link to Terms & Conditions
    let termsLink = (
      <a
        onClick={this.trackTCLink.bind(this)}
        target="_blank"
        rel="noopener noreferrer"
        href="https://legal.makinauto.com/lotsuite/terms_and_conditions.pdf">
        Terms &amp; Conditions
      </a>
    );

    return (
      <div className="Agreements login">
        <form onSubmit={this.submitForm.bind(this)}>

          <h1>Terms &amp; Conditions</h1>

          <div className="message">
            By clicking the button below, you acknowledge that you have
            read and agree to the {termsLink}
          </div>

          <button type="submit">
            Agree
          </button>

          <div className="links">
            <a href="mailto:support@lotsuite.com">
              support@lotsuite.com
            </a>
          </div>

        </form>
      </div>
    );
  }
}

export default Agreements;
