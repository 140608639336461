import 'core-js';
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter } from 'react-router-dom';
import Analytics from "./analytics";
import LoginPage from "./LoginPage/LoginPage";
import OrgsPage from "./OrgsPage/OrgsPage";
import HomePage from "./HomePage/HomePage";
import ReportsPage from "./ReportsPage/ReportsPage";
import Page from "./Page/Page";
import UsersPage from "./UsersPage/UsersPage.js";
import BillingPage from "./BillingPage/BillingPage.js";
import { InstallPage } from "./InstallPage/InstallPage";
import DeepLinks from './Native/DeepLinks.js';
import Notifications from './Native/Notifications.js';
import './App.css';
import './Cards.css';
import './Menu.css';

// Detect invalid browsers
import Bowser from "bowser";
const browser = Bowser.getParser(window.navigator.userAgent);
const isInvalidBrowser = browser.satisfies({
  "internet explorer": "<11",
});

// Import timezone polyfill for IE11
if(browser.satisfies({ "internet explorer": "11" })) {
  require('date-time-format-timezone');
}

class BrowserLink extends Component {
  render() {
    let url, name;
    switch(this.props.b) {
      case "chrome":
        url = "https://www.google.com/chrome/";
        name = "Chrome";
        break;

      case "edge":
        url = "https://www.microsoft.com/en-us/windows/microsoft-edge";
        name = "Edge";
        break;

      case "firefox":
        url = "https://www.mozilla.org/en-US/firefox/";
        name = "Firefox";
        break;

      case "safari":
        url = "https://support.apple.com/downloads/safari";
        name = "Safari";
        break;

      default:
        url = "";
        name = "";
    }

    return <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>;
  }
}

Notifications.PermissionsListener(); // Listen for notifications permissions

// Record when webapp was loaded
window.lotsuiteLoadedAt = Math.floor(new Date() / 1000);

// Initialize analytics
Analytics.initialize();

function HistoryTracker(props) {
  // Check if page changed
  if(window.oldLocation !== props.location) {
    // Track changes
    window.oldLocation = props.location;
    // Analytics
    Analytics.pageview();
  }

  // Return empty component
  return "";
}

class App extends Component {
  constructor(props) {
    super(props);
    // Parse jwt token if it exists
    let token = localStorage.getItem("token");
    let org_id = null;
    let user_id = null;
    let group_ids = null;
    let kiosk_id = null;
    let agreed_at = null;
    if(token !== null) {
      let payload = this.jwtDecode(token);
      if("org_id" in payload) org_id = payload.org_id;
      if("user_id" in payload) user_id = payload.user_id;
      if("group_ids" in payload) group_ids = payload.group_ids;
      if("kiosk_id" in payload) kiosk_id = payload.kiosk_id;
      if("aat" in payload) agreed_at = payload.aat;
    }
    // Keep screen awake if in kiosk mode
    if(kiosk_id !== null) window.postWebappData({ keepAwake: true });
    // Set state
    this.state = {
      token: token,
      org_id: org_id,
      user_id: user_id,
      group_ids: group_ids,
      kiosk_id: kiosk_id,
      agreed_at: agreed_at,
      orgs: null,
      rerender: 0,
      showDownloadModal: true
    };
  }

  componentDidMount() {
    // Listen for deep links
    this.stopDeepLinksListener =
      DeepLinks.Listener(
        this.webappRedirect.bind(this),
        this.updateToken.bind(this)
      );

    // Listen for notifications
    this.stopNotificationsRedirectListener =
      Notifications.RedirectListener(this.webappRedirect.bind(this));
  }

  componentWillUnmount() {
    // Stop listeners
    this.stopDeepLinksListener();
    this.stopNotificationsRedirectListener();
  }

  changedOrg(token, org_id, group_ids, callback) {
    // Update org_id and group_ids in state
    this.setState({
      org_id: org_id,
      group_ids: group_ids
    });
    // Update token
    this.updateToken(token);
    // Invoke callback
    callback();
  }

  updateToken(token, overwrite) {
    // Prevent token (e.g. passed in URL) from overwriting token
    if(overwrite === false && this.state.token !== null) return;

    // Decode token
    let payload = this.jwtDecode(token);

    // Update local storage
    localStorage.setItem("token", token);

    // Check if entering or exiting kiosk mode
    const was_in_kiosk_mode = (this.state.kiosk_id !== null);
    const kiosk_id = ("kiosk_id" in payload) ? payload.kiosk_id : null;
    const now_in_kiosk_mode = (kiosk_id !== null);
    if(was_in_kiosk_mode !== now_in_kiosk_mode) {
      // Tell native app there was a change in kiosk mode
      window.postWebappData({ keepAwake: now_in_kiosk_mode });
    }

    // Update state
    this.setState({
      token: token,
      user_id: ("user_id" in payload) ? payload.user_id : null,
      group_ids: ("group_ids" in payload) ? payload.group_ids : null,
      kiosk_id: kiosk_id,
      agreed_at: ("aat" in payload) ? payload.aat : null
    });
  }

  jwtDecode(token) {
    if(!/.+\..+\..+/.test(token)) return null;
    let payload = token.split('.')[1]
      .replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(payload));
  }

  login(token, orgs) {
    // Only save token if it exists
    if(!token) {
      console.log("Missing token, cannot log in");
      return;
    }

    // Update token
    this.updateToken(token);

    // Check if in native app then check notifications permissions
    if(window.isNative) {
      window.postWebappData({
        request_notifications_permission: true,
        check_notifications_permission: true
      });
    }

    // Save orgs to state (show org select page)
    this.setState({ orgs: orgs });
  }

  showDownloadModal(close) {
    // Get current time
    const now = Math.floor(new Date() / 1000);

    // Close modal
    if(close === "close") {
      this.setState({ showDownloadModal: now });  // Record when modal was hidden
      return false;
    }

    // Check if modal should be opened
    let showDownloadModal = this.state.showDownloadModal
    // Check if modal is explicitly open
    if(showDownloadModal === true) return true;
    // Show modal if hidden more than an hour ago
    if(Number.isInteger(showDownloadModal)) {
      if(showDownloadModal < (now - 60*60)) return true;
    }

    // Hide modal as fallback
    return false;
  }

  webappRedirect(path) {
    this.redirect = true;  // Indicate a redirect is needed
    this.redirectPath = path;  // Path to redirect to
    this.setState({ rerender: this.state.rerender + 1 });  // Re-render to launch redirect
  }

  render() {
    // Invalid browser message
    if(isInvalidBrowser) return (
      <div className="invalid">
        <h1>Unsupported Browser</h1>
        <h2>
          Please use the latest version of&nbsp;
          <BrowserLink b="chrome"/>, <BrowserLink b="firefox"/>,&nbsp;
          <BrowserLink b="edge"/>, or <BrowserLink b="safari"/>.
        </h2>
        <h3>
          <a href="mailto:support@lotsuite.com">
            support@lotsuite.com
          </a>
        </h3>
      </div>
    );

    // Create `user` object
    const user = {
      id: this.state.user_id,
      group_ids: this.state.group_ids,
      agreed_at: this.state.agreed_at
    };

    // Track history
    const History = withRouter(HistoryTracker);

    // Set page title
    const setTitle = (title) => {
      // Handle login page
      if(title === "login_page") {
        switch(window.location.pathname.replace(/^\//, '')) {
          case "login":
            title = "Log In";
            break;
          case "signup":
            title = "Sign Up";
            break;
          case "password":
            title = "Password";
            break;
          default:
            title = "";
        }
      }

      // Set title
      document.title = (title !== "") ? "LotSuite | "+title : "LotSuite";

      // Return `false` for inline `if`
      return false;
    }

    // Return content
    return (
      <Router>
        <Switch className="rootContainer">

          <Route exact path="/(.*/)" render={(props) =>
            // Remove trailing slash from URL
            <Redirect to={props.match.url.replace(/\/$/, "")}/>
          }/>

          <Route exact path="/" render={(props) =>
            // Redirect to specified path and clear redirect
            (this.redirect && !(this.redirect = false)) ?
              <Redirect to={this.redirectPath}/> :

            // Redirect to login if no token
            (this.state.token === null) ?
              <Redirect to="/login"/> :

            // Show org selection if none selected yet
            (this.state.org_id === null) ?
              <Redirect to="/orgs"/> :

            // Load app
            (setTitle("Tools"))?"":
            <HomePage
              showDownloadModal={this.showDownloadModal.bind(this)}
              updateToken={this.updateToken.bind(this)}
              token={this.state.token}
              user={user}/>
          }/>

          <Route exact path="/(login|signup|password)" render={(props) =>
            // Redirect to specified path and clear redirect
            (this.redirect && !(this.redirect = false)) ?
              <Redirect to={this.redirectPath}/> :

            // Check if token already exists
            (this.state.token !== null) ?
              <Redirect to="/"/> :

            // Show login page
            (setTitle("login_page"))?"":
            <LoginPage
              showDownloadModal={this.showDownloadModal.bind(this)}
              path={window.location.pathname}
              login={this.login.bind(this)}/>
          }/>

          <Route exact path="/orgs" render={(props) =>
            // Redirect to specified path and clear redirect
            (this.redirect && !(this.redirect = false)) ?
              <Redirect to={this.redirectPath}/> :

            // Check for kiosk mode
            (this.state.kiosk_id !== null) ?
              <Redirect to="/"/> :

            // Show orgs page
            (setTitle("Organizations"))?"":
            <OrgsPage
              showDownloadModal={this.showDownloadModal.bind(this)}
              changedOrg={this.changedOrg.bind(this)}
              orgs={this.state.orgs}
              user={user}
              updateToken={this.updateToken.bind(this)}
              token={this.state.token}/>
          }/>

          <Route exact path={[
            "/p/:page_id/reports",
            "/p/:page_id/reports/:report_id"
          ]} render={(props) =>
            // Redirect to specified path and clear redirect
            (this.redirect && !(this.redirect = false)) ?
              <Redirect to={this.redirectPath}/> :

            // Show reports pages
            (setTitle("Reports"))?"":
            <ReportsPage
              showDownloadModal={this.showDownloadModal.bind(this)}
              user={user}
              updateToken={this.updateToken.bind(this)}
              token={this.state.token}
              setTitle={setTitle}
              page_id={props.match.params.page_id}
              report_id={props.match.params.report_id}/>
          }/>

          <Route exact path="/p/:page_id" render={(props) =>
            // Redirect to specified path and clear redirect
            (this.redirect && !(this.redirect = false)) ?
              <Redirect to={this.redirectPath}/> :

            // Show app page
            (setTitle((props.location.state)?props.location.state.name:""))?"":
            <Page
              showDownloadModal={this.showDownloadModal.bind(this)}
              updateToken={this.updateToken.bind(this)}
              token={this.state.token}
              user={user}
              preloaded={props.location.state}
              setTitle={setTitle}
              page_id={props.match.params.page_id}/>
          }/>

          <Route exact path="/users" render={(props) =>
            // Redirect to specified path and clear redirect
            (this.redirect && !(this.redirect = false)) ?
              <Redirect to={this.redirectPath}/> :

            // Show users page
            (setTitle("Manage Users"))?"":
            <UsersPage
              showDownloadModal={this.showDownloadModal.bind(this)}
              updateToken={this.updateToken.bind(this)}
              user={user}
              token={this.state.token}/>
          }/>

          <Route exact path="/billing" render={(props) =>
            // Redirect to specified path and clear redirect
            (this.redirect && !(this.redirect = false)) ?
              <Redirect to={this.redirectPath}/> :

            // Show users page
            (setTitle("Billing"))?"":
            <BillingPage
              showDownloadModal={this.showDownloadModal.bind(this)}
              updateToken={this.updateToken.bind(this)}
              user={user}
              token={this.state.token}/>
          }/>

          <Route exact path="/install" render={(props) =>
            // Do not allow install page in app
            (window.isNative) ? <Redirect to="/"/> :

            // Show install page
            (setTitle("Download App"))?"":
            <InstallPage
              user={user}
              updateToken={this.updateToken.bind(this)}
              token={this.state.token}/>
          }/>

          <Redirect to="/"/>

        </Switch>
        <History/>
      </Router>
    );
  }
}

export default App;
