import Mixpanel from 'mixpanel-browser';

// Global variables
let gtag;

// Mixpanel tokens
const mpProd = 'b6fb2b9d19ecba4b86c0db60f126bcd3';
const mpDev = '1069cd670494435f8e7659900d5e6121';
// Google Analytics tokens
const gaProd = 'UA-64725686-11';
const gaDev = 'UA-64725686-10';
// Google Analytics cross-platform tokens
const gacpProd = 'G-TSVHYCK8T2';
const gacpDev = 'G-7SC4F0XW2F';


// Check if production or development
let env_check = process.env.NODE_ENV === 'production'
  && window.location.hostname === "app.lotsuite.com";

// Decode token
let token = localStorage.getItem("token");
let payload = (token !== null && /.+\..+\..+/.test(token))
  ? JSON.parse(window.atob(token.split('.')[1]
    .replace(/-/g, '+').replace(/_/g, '/')))
  : {};
let kiosk_id = ("kiosk_id" in payload) ? payload.kiosk_id : null;
let org_id = ("org_id" in payload) ? payload.org_id : null;
let user_id = ("user_id" in payload) ? payload.user_id : null;
let uid = (kiosk_id) ? kiosk_id : user_id

// Analytics functions
export const Analytics = {
  // Initialize analytics
  initialize: () => {
    // Set props
    let props = {
      uid: uid,
      webapp_version: `${process.env.REACT_APP_VERSION}`,
      org_id: org_id,
      kiosk_id: kiosk_id,
      user_id: user_id
    };
    // Set native app props
    if(window.isNative) props = Object.assign(props, {
      app_name: "LotSuite",
      app_id: "lotsuite",
      app_version: window.nativeDetails.app_version_string,
      app_build_number: window.nativeDetails.app_build_number,
      manufacturer: window.nativeDetails.manufacturer,
      brand: window.nativeDetails.brand,
      model: window.nativeDetails.model,
      os: window.nativeDetails.os,
      os_version: window.nativeDetails.os_version,
      carrier: window.nativeDetails.carrier
    });

    // Mixpanel
    Mixpanel.init( (env_check) ? mpProd : mpDev );
    Mixpanel.register(props);
    if(uid) Mixpanel.identify(uid);

    // Google Analytics
    window.dataLayer = window.dataLayer || [];
    gtag = function(){ window.dataLayer.push(arguments) }
    gtag('js', new Date());
    const gaProps = {
      custom_map: {
        dimension1: 'webapp_version',
        dimension2: 'app_version',
        dimension3: 'org_id',
        dimension4: 'kiosk_id',
        dimension5: 'user_id'
      },
      send_page_view: false
    }
    gtag('config', (env_check) ? gaProd : gaDev, gaProps);  // Standard
    gtag('config', (env_check) ? gacpProd : gacpDev, gaProps);  // Cross-Platform
    gtag('set', props);
  },

  // Track pageviews
  pageview: () => {
    let page_title = document.title;
    let page_path = window.location.pathname;

    // Mixpanel
    Mixpanel.track('pageview', {
      page_title: page_title,
      page_path: page_path
    });

    // Google Analytics
    gtag('set', {  // Set current page info
      page_title: page_title,
      page_path: page_path
    });
    gtag('event', 'page_view');  // Send page view event
  },

  // Track events
  event: (name, props, non_interaction) => {
    // Mixpanel
    if("user_id" in props) Mixpanel.identify(props.user_id);
    if("kiosk_id" in props) Mixpanel.identify(props.user_id);
    if("name" in props) Mixpanel.people.set(props.name);
    if("email" in props) Mixpanel.people.set(props.email);
    if("phone" in props) Mixpanel.people.set(props.phone);
    Mixpanel.track(name, props);

    // Google Analytics
    gtag('event', name, props);
  },

  // Track properties (websockets, browser, etc.)
  properties: (props) => {
    // Mixpanel
    Mixpanel.register(props);

    // Google Analytics
    gtag('set', props);
  },

  // Track errors
  error: (description, props) => {
    if(!props) props = {};
    props.description = description;
    props.error = true;

    // Mixpanel
    Mixpanel.track(description, props);

    // Google Analytics
    gtag('event', 'exception', props);
  }
};

export default Analytics;
