// Store native app data in global variables
window.injectedData = {};
window.isNative = false;
window.nativeDetails = {};

// Parse user agent
let userAgent = navigator.userAgent;
// Check if in a native app
let versionMatch = userAgent.match(/LotSuite\/([\d.]+)/i);
if(versionMatch !== null) {
  // Set native app indicator
  window.isNative = true;
  // Save native app version
  window.nativeDetails.app_version_string = versionMatch[1];
}
// Parse additional details
let match = userAgent.match(/LotSuite\/([\d.]+)\/([\d.]+) \((.+?); (.+?); (.+?); (.+?); (.+?); (.+?)\)/i);
if(match !== null) {
  // Set app client details
  window.nativeDetails = {
    app_version_string: match[1],
    app_build_number: match[2],
    manufacturer: match[3],
    brand: match[4],
    model: match[5],
    os: match[6],
    os_version: match[7],
    carrier: match[8]
  };
}

// Accept data sent from native app
window.injectData = (data) => {
  // Parse data
  const parsed = JSON.parse(data);

  // Save each item in data to window (idempotent)
  Object.keys(parsed).forEach((key) => {
    window.injectedData[key] = parsed[key];
  });

  // Create and dispatch event
  const event = new CustomEvent("onDataInjection", { detail: parsed });
  document.dispatchEvent(event);
}

// Send data to native app
window.postWebappData = (data) => {
  // Skip if not in native app
  if(!("ReactNativeWebView" in window)) return;

  // Post data to native app
  window.ReactNativeWebView.postMessage(JSON.stringify(data));
}
