import Analytics from '../analytics';

import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import awsmobile from '../amplify-config';
Amplify.configure(awsmobile);

// Redirect when notification is opened
export const RedirectListener = function(redirect) {
  // Function to invoke redirect
  const handler = function(e) {
    if(!("notification" in e.detail)) return false;
    const payload = e.detail.notification;
    // Get path from payload
    const path = ("data" in payload && "page_id" in payload.data
      && payload.data.page_id) ? "/p/"+payload.data.page_id : null;
    const page_name = ("data" in payload && "page_name" in payload.data
      && payload.data.page_name) ? payload.data.page_name : null;

    // Analytics
    if(payload.app_state !== "foreground") {
      Analytics.event("notification_opened", {
        app_state: ("app_state" in payload) ? payload.app_state : null,
        event_category: "referral",
        notification_title: (payload.data && "title" in payload.data)
          ? payload.data.title : null,
        notification_body: (payload.data && "body" in payload.data)
          ? payload.data.body : null,
        link_path: path,
        link_page_name: page_name,
        non_interaction: true
      });
    }

    // Show notification if app is open
    if(payload.app_state === "foreground") {
      ShowNotification(payload.data);
    }

    // Redirect
    if(path !== window.location.pathname  // If not already there
    && payload.app_state !== "foreground"  // If app is open
    && path !== null) redirect(path);
  };

  // Add listener
  document.addEventListener("onDataInjection", handler);

  // Return method for stopping event listener
  return () => document.removeEventListener("onDataInjection", handler);
}

// Save device to database when notifications_token is passed
export const PermissionsListener = function() {
  document.addEventListener("onDataInjection", (e) => {
    if("notifications_token" in e.detail
    && "notifications_enabled" in e.detail
    && "notifications_instance" in e.detail) {
      // Save device details for sending notifications
      saveDevice(
        e.detail.notifications_token,
        e.detail.notifications_enabled,
        e.detail.notifications_instance
      );
    }
  });
};

// Show notification
export const ShowNotification = function(data) {
  window.postWebappData({
    show_notification: {
      title: data.title,
      body: data.body,
      data: data
    }
  });
}

// Remove device from database via API
export const RemoveDevice = async function() {
  // Skip if not in a native app
  if(!window.isNative) return;

  // Get device_id
  if(!("notifications_instance" in window.injectedData)) return;
  const device_id = window.injectedData.notifications_instance;

  // Do not proceed if not logged in
  const token = localStorage.getItem("token");
  if(token === null) return;
  // Do not proceed if in kiosk mode (no user_id in token)
  const payload = JSON.parse(window.atob(
    token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')
  ));
  if(!("user_id" in payload)) return;

  // Set mutation parameters
  let params = {
    token: token,
    device_id: device_id
  };

  // Submit mutation
  try {
    await API.graphql(graphqlOperation(mutations.removeDevice, params));
  } catch (error) {
    console.error(error);
  }
}

// Save device to database via API
const saveDevice = function(n_token, enabled, device_id) {
  // Check if user has enabled notifications on this device
  if(!enabled) {
    RemoveDevice();
    return console.warn("Notification disabled");
  }

  // Do not proceed if not logged in
  const token = localStorage.getItem("token");
  if(token === null) return;
  // Do not proceed if in kiosk mode (no user_id in token)
  const payload = JSON.parse(window.atob(
    token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')
  ));
  if(!("user_id" in payload)) return;

  // Set mutation parameters
  let params = {
    token: token,
    device_id: device_id,
    n_token: n_token
  };

  // Submit mutation
  API.graphql(graphqlOperation(mutations.saveDevice, params))
    // Error
    .catch((err) => console.error(err));
}

// Export functions
const functionsToExport = {
  PermissionsListener,
  RedirectListener,
  ShowNotification,
  RemoveDevice
};
export default functionsToExport;
