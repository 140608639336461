import React, { Component } from 'react';
import ReactMapGL, {
  FullscreenControl,
  NavigationControl,
  FlyToInterpolator
} from 'react-map-gl';
import Icon from "../Icon/Icon";
import "./Map.css";

// Calculate approximate pixel radius in meters
const metersToPixelsAtMaxZoom = (meters, latitude) =>
  meters / 0.075 / Math.cos(latitude * Math.PI / 180);

class StyleButton extends Component {
  render() {
    return (
      <button
        type="button"
        className={
          "mapboxgl-ctrl-icon "+
          ((this.props.mapStyle === this.props.name) ? "active " : "")+
          "button-"+this.props.name}
        onClick={(e) => this.props.changeMapStyle(this.props.name)}/>
    );
  }
}

class StyleControl extends Component {
  render() {
    return (
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
        <StyleButton
          name="streets"
          mapStyle={this.props.mapStyle}
          changeMapStyle={this.props.changeMapStyle.bind(this)}/>
        <StyleButton
          name="hybrid"
          mapStyle={this.props.mapStyle}
          changeMapStyle={this.props.changeMapStyle.bind(this)}/>
      </div>
    );
  }
}

class MapDescription extends Component {
  render() {
    // Hide description of blank
    if(!this.props.description) return "";

    // Show decription
    return (
      <div
        className={"mapDescription" +
          ((this.props.expired) ? " expired" : "")}>
        {(this.props.expired) ? <Icon name="warning"/> : ""}
        <span>
          {this.props.description}
        </span>
      </div>
    )
  }
}

class MapComponent extends Component {
  // Variable to allow `setInterval` to be cleared
  markerAnimationInterval = null;
  // Set initial state
  state = {
    mounted: false,
    mapStyle: this.props.mapStyle,
    viewport: {
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      zoom: this.props.zoom
    }
  };

  componentDidMount() {
    // Indicate when mounted to prevent error during `onViewportChange`
    this.setState({ mounted: true });

    // Add marker to show location on map
    this.addMarker();
  }

  componentDidUpdate(prevProps) {
    // Check for change in coordinates
    if(prevProps.latitude !== this.props.latitude
    || prevProps.longitude !== this.props.longitude) {
      // Move map to new coordinates
      const viewport = {
        ...this.state.viewport,
        latitude: this.props.latitude,
        longitude: this.props.longitude,
        zoom: this.props.zoom,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
        // transitionEasing: d3.easeCubic
      };
      this.setState({viewport});

      // Update markers
      if(prevProps.mounted) {
        // Move marker
        const map = this.reactMap.getMap();
        map.getSource('coordinates').setData({
          "type": "Point",
          "coordinates": [ this.props.longitude, this.props.latitude ]
        });
        // Update marker colors
        map.setPaintProperty('marker', 'circle-color',
          (this.props.expired) ? "#FF453A" : "#27ae60");
        map.setPaintProperty('marker-accuracy', 'circle-color',
          (this.props.expired) ? "#FF453A" : "#27ae60");
        map.setPaintProperty('marker-motion', 'circle-color',
          (this.props.expired) ? "#FF453A" : "#27ae60");
      }
    }
  }

  componentWillUnmount() {
    // Stop marker animation
    clearInterval(this.markerAnimationInterval);
  }

  addMarker(map) {
    // Check if map is already loaded
    if(!map) {
      // Do nothing if no map exists (e.g. still loading)
      if(!this.reactMap) return;
      // Wait for map to load
      const map = this.reactMap.getMap();
      map.on('load', () => this.addMarker(map));
      // Stop function
      return;
    }

    // Check if markers have already been added
    if(map.getLayer('marker')) return;

    // Add coordinates as source
    map.addSource('coordinates', {
      "type": "geojson",
      "data": {
        "type": "Point",
        "coordinates": [ this.props.longitude, this.props.latitude ]
      }
    });

    // Add marker layer to map
    map.addLayer({
      "id": "marker",
      "type": "circle",
      "source": "coordinates",
      "paint": {
        "circle-color": (this.props.expired) ? "#FF453A" : "#27ae60",
        "circle-radius": 8,
        "circle-stroke-color": "#ffffff",
        "circle-stroke-width": 3
      }
    });

    // Add shadow behind marker
    map.addLayer({
      "id": "marker-shadow",
      "type": "circle",
      "source": "coordinates",
      "paint": {
        "circle-color": "#000",
        "circle-radius": 20,
        "circle-opacity": .2,
        "circle-blur": 1
      }
    }, "marker"); // Position behind marker

    // Add accuracy indicator layer to map
    if(this.props.accuracy > 0) map.addLayer({
      "id": "marker-accuracy",
      "type": "circle",
      "source": "coordinates",
      "paint": {
        "circle-color": (this.props.expired) ? "#FF453A" : "#27ae60",
        "circle-opacity": .4,
        "circle-radius": {
          stops: [
            [0, 0],
            [20, metersToPixelsAtMaxZoom(this.props.accuracy,
              this.props.latitude)]
          ],
          base: 2
        },
      }
    }, "marker");  // Position behind marker, in front of shadow

    // Add motion layer to map
    const initialOpacity = .7;
    const finalOpacity = 0;
    const initialRadius = 11;  // Marker's radius + stroke width
    const finalRadius = 40;
    const animationTiming = 3000;
    map.addLayer({
      "id": "marker-motion",
      "type": "circle",
      "source": "coordinates",
      "paint": {
        "circle-color": (this.props.expired) ? "#FF453A" : "#27ae60",
        "circle-opacity": initialOpacity,
        "circle-radius": initialRadius
      }
    }, "marker");  // Position behind accuracy indicator

    // Animate marker
    let radius = initialRadius;
    let opacity = initialOpacity;
    const markerAnimation = () => {
      // Grow
      if(radius !== finalRadius) {
        // Enable animation
        map.setPaintProperty('marker-motion', 'circle-radius-transition',
          { duration: animationTiming });
        map.setPaintProperty('marker-motion', 'circle-opacity-transition',
          { duration: animationTiming });
        // Animate
        radius = finalRadius;
        opacity = finalOpacity;
        map.setPaintProperty('marker-motion', 'circle-radius', radius);
        map.setPaintProperty('marker-motion', 'circle-opacity', opacity);
      }
      // Shrink
      else {
        // Disable animation before resetting properties
        map.setPaintProperty('marker-motion', 'circle-radius-transition',
          { duration: 0 });
        map.setPaintProperty('marker-motion', 'circle-opacity-transition',
          { duration: 0 });
        // Reset properties
        radius = initialRadius;
        opacity = initialOpacity;
        map.setPaintProperty('marker-motion', 'circle-radius', radius);
        map.setPaintProperty('marker-motion', 'circle-opacity', opacity);
        // Animate
        setTimeout(markerAnimation, 50);
      }
    };
    // Animate on load
    markerAnimation();
    // Animate on interval
    clearInterval(this.markerAnimationInterval);
    this.markerAnimationInterval = setInterval(markerAnimation, animationTiming);
  }

  changeMapStyle(mapStyle) {
    // Do not set style if already set
    if(mapStyle === this.state.mapStyle) return;
    // Update map's style
    const map = this.reactMap.getMap();
    map.setStyle('mapbox://styles/mapbox/'+this.mapStylePath(mapStyle));
    map.on('styledata', () => this.addMarker(map));
    // Remember map's style
    this.setState({ mapStyle: mapStyle });
  }

  mapStylePath(mapStyle) {
    switch(mapStyle) {
      case 'streets':
        return 'streets-v11';

      case 'light':
        return 'light-v10';

      case 'dark':
        return 'dark-v10';

      case 'outdoors':
        return 'outdoors-v11';

      case 'satellite':
        return 'satellite-v9';

      case 'hybrid':
        return 'satellite-streets-v11';

      default:
        return 'satellite-streets-v11';
    }
  }

  render() {
    return (
      <div className="Map">
        <ReactMapGL
          ref={(reactMap) => this.reactMap = reactMap}
          mapStyle={'mapbox://styles/mapbox/'+
            this.mapStylePath(this.props.mapStyle)}
          width="100%"
          height="100%"
          {...this.state.viewport}
          onViewportChange={(viewport) => {
            if(this.state.mounted) this.setState({ viewport })
          }}>
          <div className="controls screenControl">
            <FullscreenControl/>
          </div>
          <div className="controls styleControl">
            <StyleControl
              mapStyle={this.state.mapStyle}
              mapStylePath={this.mapStylePath.bind(this)}
              changeMapStyle={this.changeMapStyle.bind(this)}/>
          </div>
          <div className="controls navControl">
            <NavigationControl
              showCompass={false}/>
          </div>
          <MapDescription
            expired={this.props.expired}
            description={this.props.description}/>
        </ReactMapGL>
      </div>
    );
  }
}

class Map extends Component {
  parseProp(prop, def) {
    return (!isNaN(Number(this.props[prop]))
    && typeof this.props[prop] !== "undefined"
    && (typeof this.props[prop] === "string"
    || typeof this.props[prop] === "number"))
      ? Number(this.props[prop])
      : (typeof def !== "undefined") ? def : false;
  }

  prettyTime(t) {
    let dt = new Date(t * 1000);
    // Pretty time if today
    let pretty = "at " + dt.toLocaleTimeString('en-US', {
      hour: "numeric",
      minute: "numeric"
    });

    // Pretty date if another day
    let date_options = {
      day: "numeric",
      month: "short",
      year: "numeric"
    };
    let d = dt.toLocaleDateString('en-US', date_options);
    let today = new Date().toLocaleDateString('en-US', date_options);
    if(d !== today) {
      pretty = d;
    }

    // Return pretty time or date
    return pretty;
  }

  render() {
    // Parse coordinates
    const latitude = this.parseProp('latitude');
    const longitude = this.parseProp('longitude');

    // Show/hide map
    if(this.props.show === false) return null;

    // Loading
    if(this.props.loading) {
      return (
        <div className="Map loading">
          <Icon name="spinner"/>
        </div>
      );
    }

    // Check for valid coordinates
    if(latitude === false || longitude === false) {
      return (
        <div className="Map error">
          <Icon name="globe"/>
          <p className="message">
            Location not found
          </p>
        </div>
      )
    }

    // Check for expired location
    let description = this.props.description;
    let timestamp = this.props.timestamp;
    let expired = this.props.expired;
    if(expired) {
      if(timestamp) description = "Last seen "+this.prettyTime(timestamp)
      else description = "Location Expired";
    }

    // Create map
    return (
      <MapComponent
        {...this.props}
        latitude={latitude}
        longitude={longitude}
        expired={expired}
        zoom={this.parseProp('zoom', 16)}
        accuracy={this.parseProp('accuracy', 10)}
        description={description}
        mapStyle={(this.props.mapStyle) ? this.props.mapStyle : 'hybrid'}/>
    );
  }
}

export default Map;
