// Check if native alerts are supported (<= 1.1.0 does not support alerts)
const nativeAlerts = (window.isNative
  && !(/^(0\.|1\.(0|1))/.test(window.nativeDetails.app_version_string)))
    ? true : false;

// Save browser's `window.alert()` function
const windowAlert = window.alert;
// Handle `window.alert()` for native apps and browsers
window.alert = (body) => {
  // Pass to native app if supported
  if(nativeAlerts) {
    // Pass to native app
    return window.postWebappData({
      show_alert: {
        body: body.toString()
      }
    });
  }
  // Invoke browser alert if in browser or unsupported app version
  return windowAlert(body);
}

// Function to show native confirm dialog
// This function is non-blocking, must provide a callback function
window.nativeConfirm = (body, callback) => {
  // Pass to native app if supported
  if(nativeAlerts) {
    // Function to listen for response
    const handler = function(e) {
      // Only listen for `alert` response
      if(!("alert" in e.detail)) return false;
      // Stop listening after receiving response
      document.removeEventListener("onDataInjection", handler);
      // Invoke callback
      callback((e.detail.alert.result === "positive") ? true : false);
    };

    // Pass to native app
    window.postWebappData({
      show_alert: {
        cancelable: false,
        body: body.toString(),
        buttons: [
          { text: "Cancel" },
          { text: "OK" }
        ]
      }
    });

    // Listen for response
    document.addEventListener("onDataInjection", handler);
  }
  // Use browser's `confirm` if not supported
  else callback(window.confirm(body));
}
