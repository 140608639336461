import React, { Component } from 'react';
import * as Util from "../Utilities/Utilities";
import { Button } from "../Button/Button";
import "./Workflow.css";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsmobile from '../aws-exports';
const configureAmplify = function() {
  awsmobile.API = { graphql_headers: async () => ({
    // Add token to headers for subscription auth
    token: localStorage.getItem("token")
  }) };
  Amplify.configure(awsmobile);
};
configureAmplify();

class TimeInZone extends Component {
  constructor(props) {
    super(props);
    // Specify intervals
    this.timeDiffInterval = null;
    // Set initial state
    this.state = {
      value_formatted: null
    };
  }

  componentDidMount() {
    this.loadComponent();
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadComponent();
    }
  }

  componentWillUnmount() {
    // Clear interval on unmount
    clearInterval(this.timeDiffInterval);
  }

  loadComponent() {
    // Clear interval on load (clear existing intervals on re-load)
    clearInterval(this.timeDiffInterval);

    // Update clock now
    this.setState({
      value_formatted: Util.formatClock(Math.floor(Date.now() / 1000) - this.props.createdAt, {})
    });

    // Update clock every second
    this.timeDiffInterval = setInterval(() => {
      this.setState({
        value_formatted: Util.formatClock(Math.floor(Date.now() / 1000) - this.props.createdAt, {})
      });
    }, 1000);
  }

  render() {
    return this.state.value_formatted;
  }
}

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      created_at: null,
      archived_at: null,
      children: [],
      children_archived: [],
      loading: null,
      parent_zones: {}
    };
  }

  componentDidMount() {
    if(this.props.config.view_past === true
    || this.props.config.view_present === true) {
      // Set loading
      this.setState({ loading: true });
      // Get past and present children
      this.getChildren(
        this.props.config.page_id,
        this.props.config.list_id,
        this.props.config.item_id,
        this.props.config.archived,
        this.props.config.view_past,
        this.props.config.view_present
      );
    }
  }

  getChildren(page_id, list_id, item_id, archived, view_past, view_present) {
    // Past children
    let children_archived = (!view_past) ? "" :
      `children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
      }`;

    // Present children
    let children = (!view_present) ? "" :
      `children {
        id
        list_id
        created_at
        updated_at
      }`;

    // Create query
    let query = `query
      GetItem($token: String!, $page_id: ID!, $list_id: ID!, $item_id: ID!, $archived: Boolean) {
        getItem(token: $token, page_id: $page_id, list_id: $list_id, item_id: $item_id, archived: $archived) {
          id
          created_at
          archived_at
          ${children}
          ${children_archived}
        },
        getPage(token: $token, page_id: $page_id) {
          zones {
            name
            list_id
          }
        }
      }`;

    // Define params
    let params = {
      token: this.props.token,
      page_id: page_id,
      list_id: list_id,
      item_id: item_id,
      archived: archived
    };

    // Query API
    API.graphql(graphqlOperation(query, params))
    // Success
    .then((res) => {
      // Save children
      let item = res.data.getItem;
      this.setState({
        created_at: item.created_at,
        archived_at: item.archived_at
      });
      if("children" in item) this.setState({ children: item.children });
      if("children_archived" in item) this.setState({ children_archived: item.children_archived });
      // Save zones
      let parent = res.data.getPage;
      if("zones" in parent) {
        // Convert array of zones to object
        let parent_zones = {};
        for(let i=0; i<parent.zones.length; i++) {
          let zone = parent.zones[i];
          parent_zones[zone.list_id] = {
            name: zone.name
          };
        }
        this.setState({ parent_zones: parent_zones });
      }
      // Indicate loading is done
      this.setState({ loading: false });
    })
    // Error
    .catch((err) => console.log(err) );
  }

  formatTimestamp(value, options) {
    // Create new javascript date object
    let timestamp = new Date(value * 1000);

    // Default options
    if(!options) options = {
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      month: "short"
    };

    // Convert timestamp to pretty string with options
    let pretty = timestamp.toLocaleString('en-US', options);

    // Return formatted timestamp
    return pretty;
  }

  zoneName(list_id) {
    let parent_zones = this.state.parent_zones;
    if(list_id in parent_zones && "name" in parent_zones[list_id]) {
      return parent_zones[list_id].name
    }
    else return list_id;
  }

  render() {
    // Past zones
    let past = [];
    let children_archived = this.state.children_archived;
    if(children_archived.length > 0) {
      // Sort zones
      children_archived.sort((b, a) => {
        return (a.archived_at !== b.archived_at)
          ? a.archived_at - b.archived_at
          : (a.created_at !== b.created_at)
            ? a.created_at - b.created_at
            : a.id.localeCompare(b.id);
      });

      // Loop through zones
      for(let i=0; i<children_archived.length; i++) {
        let zone = children_archived[i];
        past.push(
          <tbody
            key={i}
            className="past">
            <tr>
              <th colSpan={2}>{this.zoneName(zone.list_id)}</th>
            </tr>
            <tr>
              <td>Start</td>
              <td>{this.formatTimestamp(zone.created_at)}</td>
            </tr>
            <tr>
              <td>Finish</td>
              <td>{this.formatTimestamp(zone.archived_at)}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{Util.formatClock(zone.archived_at - zone.created_at, {})}</td>
            </tr>
          </tbody>
        );
      }
    }

    // Present zones
    let present = [];
    let children = this.state.children;
    if(children.length > 0) {
      // Sort zones
      children_archived.sort((b, a) => {
        return (a.created_at !== b.created_at)
          ? a.created_at - b.created_at
          : a.id.localeCompare(b.id);
      });

      // Loop through zones
      for(let i=0; i<children.length; i++) {
        let zone = children[i];
        present.push(
          <tbody
            key={i}
            className="present">
            <tr>
              <th colSpan={2}>{this.zoneName(zone.list_id)}</th>
            </tr>
            <tr>
              <td>Start</td>
              <td>{this.formatTimestamp(zone.created_at)}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td className="active">
                <TimeInZone
                  createdAt={zone.created_at}/>
              </td>
            </tr>
          </tbody>
        );
      }
    }

    // Total time
    let total = [];
    if(this.state.created_at !== null
    && this.props.config.view_total) {
      // Active items
      if(this.state.archived_at === null) total.push(
        <tbody
          key="0"
          className="total">
          <tr>
            <th colSpan={2}>Total</th>
          </tr>
          <tr>
            <td>Start</td>
            <td>{this.formatTimestamp(this.state.created_at)}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td className="active">
              <TimeInZone
                createdAt={this.state.created_at}/>
            </td>
          </tr>
        </tbody>
      );

      // Archived items
      else total.push(
        <tbody
          key="0"
          className="total">
          <tr>
            <th colSpan={2}>Total</th>
          </tr>
          <tr>
            <td>Start</td>
            <td>{this.formatTimestamp(this.state.created_at)}</td>
          </tr>
          <tr>
            <td>Finish</td>
            <td>{this.formatTimestamp(this.state.archived_at)}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{Util.formatClock(this.state.archived_at - this.state.created_at, {})}</td>
          </tr>
        </tbody>
      );
    }

    // No history to show
    if(this.state.loading === null) return null;

    // Loading indicator
    if(this.state.loading === true) return (
      <div className="History loading">
        Loading...
      </div>
    );

    // Done loading
    if(this.state.loading === false) {
      // Show history
      if(past.length > 0 || present.length > 0 || total.length > 0) return (
        <table className="History">
          {total}
          {present}
          {past}
        </table>
      );

      // No items to show
      else return (
        <div className="History no_items">
          No zones to show
        </div>
      );
    }
  }
}

class Menu extends Component {
  render() {
    // Hide menu
    if(!this.props.showMenu) return "";

    // Create zone buttons
    let buttons = [];
    let buttons_config = (this.props.buttons !== null && this.props.buttons !== undefined)
      ? this.props.buttons
      : {};

    // Get all item buttons in config and sort
    let button_keys = Object.keys(buttons_config).sort((a, b) => {
      let sort = 0;
      // Get conditions
      let orderA = ("order" in buttons_config[a])
        ? buttons_config[a].order : null;
      let orderB = ("order" in buttons_config[b])
        ? buttons_config[b].order : null;

      // Sort by order
      if(orderA !== null && orderB !== null) sort = orderA - orderB;
      else if(orderA === null && orderB === null) sort = 0;
        else if(orderA !== null && orderB === null) sort = -1;
          else if(orderA === null && orderB !== null) sort = 1;

      // Sort by ID, if needed
      if(sort === 0) sort = a.localeCompare(b);

      // Return result
      return sort;
    });

    // Loop through buttons
    for(let i=0; i<button_keys.length; i++) {
      let button = buttons_config[button_keys[i]];
      // Add button
      buttons.push(
        <Button
          key={i}
          id={button_keys[i]}
          item={this.props.form_data.item}
          items={this.props.form_data.items}
          groups={this.props.form_data.groups}
          user={this.props.form_data.user}
          page={this.props.form_data.page}
          token={this.props.form_data.token}
          zone={this.props.zone}
          openForm={this.props.form_data.openForm.bind(this)}
          redirect={this.props.form_data.redirect.bind(this)}
          updateItem={this.props.form_data.updateItem.bind(this)}
          button={button}/>
      );
    }

    // Return menu
    return (
      <div className="menu">
        {buttons}
      </div>
    );
  }
}

class Zone extends Component {
  toggleMenu() {
    const menu_list_id = (this.props.menu_list_id === this.props.zone.list_id)
      // Hide menu if this zone's menu is open
      ? null
      // Show this zone's menu if no menu is open or another zone's menu is open
      : this.props.zone.list_id;
    this.props.showMenu(menu_list_id);
  }

  render() {
    // Check if completed
    let completed = (this.props.zone._completed)
      ? " completed"
      : "";

    // Check if required
    let required = (this.props.zone.required)
      ? " required"
      : "";

    // Check if zone is active
    let active = (this.props.zone._active)
      ? " active"
      : "";

    // Check if zone has been overriden
    let override = (this.props.zone._override)
      ? " override"
      : "";

    // Show/hide menu
    let showMenu = (this.props.menu_list_id === this.props.zone.list_id)
      ? true
      : false;

    return (
      <div
        className={"zone "+this.props.zone.flow + completed + required + active + override}
        onClick={this.toggleMenu.bind(this)}>
        {this.props.zone.name}
        <Menu
          form_data={this.props.form_data}
          zone={this.props.zone}
          buttons={this.props.buttons}
          showMenu={showMenu}/>
      </div>
    );
  }
}

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item_workflow: [],
      loading: null,
      menu_list_id: null
    };
  }

  componentDidMount() {
    if(this.props.config.view_chart === true) {
      // Set loading
      this.setState({ loading: true });
      // Get item-specific workflow
      this.getItemWorkflow();
    }
  }

  getItemWorkflow() {
    // Create query
    let query = `query
      CalculateItemWorkflow($token: String!, $page_id: ID!, $list_id: ID!, $item_id: ID!, $archived: Boolean) {
        calculateItemWorkflow(token: $token, page_id: $page_id, list_id: $list_id, item_id: $item_id, archived: $archived) {
          workflow
        }
      }`;

    // Define params
    let params = {
      token: this.props.token,
      page_id: this.props.config.page_id,
      list_id: this.props.config.list_id,
      item_id: this.props.config.item_id,
      archived: this.props.config.archived
    };

    // Query API
    API.graphql(graphqlOperation(query, params))
    // Success
    .then((res) => {
      // Save workflow
      try {
        let item_workflow = res.data.calculateItemWorkflow.workflow;
        this.setState({
          item_workflow: JSON.parse(item_workflow)
        });
      }
      // Handle errors
      catch(e) { console.log(e) }
      // Indicate loading is done
      this.setState({ loading: false });
    })
    // Error
    .catch((err) => console.log(err) );
  }

  parseWorkflow(workflow, flow="linear", depth=0) {
    let zones = [];

    // Loop through workflow
    for(let i=0; i<workflow.length; i++) {
      // Check if this is a zone or a sub-workflow
      if(typeof workflow[i] === "object" && "zones" in workflow[i]) {
        // Handle different type of flows
        let subFlow = (workflow[i].flow === "parallel")
          ? workflow[i].flow : "linear";
        // Process sub-workflow
        let subworkflow = this.parseWorkflow(workflow[i].zones, subFlow, depth + 1);
        // Create sub-workflow
        let className = "subworkflow " + subFlow;
        let swDiv = (
          <div
            className={className}
            key={i}>
            {subworkflow}
          </div>
        );
        // If first sub-workflow, add wrapper for horizontal scroll (in case wider than screen)
        if(depth === 0) {
          swDiv = (
            <div
              className="subWorkflowWrapper"
              key={"subWorkflowWrapper"+i}>
              {swDiv}
            </div>
          );
        }
        // Add to array
        zones.push(swDiv);
        // Skip to next item in workflow (already processed as a sub-workflow)
        continue;
      }

      // Get zone from workflow
      let zone = workflow[i];
      // Add flow to zone
      zone.flow = flow;

      // Add zone to array
      zones.push(
        <Zone
          key={i}
          form_data={this.props.form_data}
          buttons={this.props.config.buttons}
          showMenu={this.showMenu.bind(this)}
          menu_list_id={this.state.menu_list_id}
          zone={zone}/>
      );
    }

    // Return chart
    return zones;
  }

  showMenu(list_id) {
    this.setState({ menu_list_id: list_id });
  }

  render() {
    // No chart to show
    if(this.state.loading === null) return null;

    // Loading indicator
    if(this.state.loading === true) return (
      <div className="Chart loading">
        Loading...
      </div>
    );

    // Done loading
    if(this.state.loading === false) {
      // Show chart
      if(this.state.item_workflow !== null) return (
        <div className="Chart">
          {this.parseWorkflow(this.state.item_workflow)}
        </div>
      );

      // No items to show
      else return (
        <div className="Chart no_items">
          No workflow available
        </div>
      );
    }
  }
}

class Workflow extends Component {
  render() {
    return (
      <div className="Workflow">
        <History
          token={this.props.token}
          config={this.props.config}/>
        <Chart
          form_data={this.props.form_data}
          token={this.props.token}
          config={this.props.config}/>
      </div>
    );
  }
}

export default Workflow;