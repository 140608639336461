import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Header, Footer, Spacer } from "../Header/Header";
import Form from "../Form/Form";
import Icon from "../Icon/Icon";
import { Button, Buttons } from "../Button/Button";
import Status from "../Status/Status";
import "./UserPage.css";
import Analytics from '../analytics';
import { InstallPrompt } from "../InstallPage/InstallPage";
import Updater from "../Updater/Updater";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsmobile from '../amplify-config';
Amplify.configure(awsmobile);

class Menu extends Component {
  render() {
    let menu = (
      <div className="menu">
        <Button
          token={this.props.token}
          user={this.props.user}
          groups={this.props.groups}
          button={{
            title: "Edit User",
            if: { admin: true },
            action: this.props.updateUser.bind(this)
          }}/>
        <Button
          token={this.props.token}
          user={this.props.user}
          groups={this.props.groups}
          button={{
            title: "Remove User",
            if: { admin: true },
            class: "warning",
            action: this.props.removeUser.bind(this)
          }}/>
      </div>
    );

    return (this.props.showMenu) ? menu : "";
  }
}

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      loading: false
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.thisUser.id !== this.props.thisUser.id) {
      this.setState({ loading: false });
    }
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  updateUser() {
    // this.setState({ loading: true });
    this.props.updateUser(this.props.thisUser);
  }

  removeUser() {
    // this.setState({ loading: true });
    this.props.removeUser(this.props.thisUser);
  }

  render() {
    // Process groups
    let groupTags = [];
    const groups = this.props.groups;
    for(let i=0; i<this.props.thisUser.group_ids.length; i++) {
      let id = this.props.thisUser.group_ids[i];
      // Check if group exists
      let group = {};
      for(let j=0; j<groups.length; j++) {
        if(id !== groups[j].id) continue;
        group = groups[j];
      }
      // Get group name
      const group_name = (group.name) ? group.name : group.id;
      // Check if a language group
      let lang = (/^Language:/.test(group_name)) ? "language" : "";
      // Add group to component
      groupTags.push(
        <span
          key={i}
          className={lang}>
          {group_name}
        </span>
      );
    }
    if(groupTags.length === 0) groupTags.push("No assigned groups");

    // Format phone number
    let phone = (!("phone" in this.props.thisUser)) ? "" :
      (!this.props.thisUser.phone) ? "" : this.props.thisUser.phone
      .replace(/(.*)(\d{3})(\d{3})(\d{4})$/, "$1 ($2) $3-$4")
      .trim();

    // Class
    const className = "card grid-6 grid-xs-12 hasMenu" +
      ((this.state.showMenu) ? " showMenu" : "");

    return (
      <div
        onClick={this.toggleMenu.bind(this)}
        className={className}>
        <Menu
          token={this.props.token}
          user={this.props.user}
          groups={this.props.groups}
          showMenu={this.state.showMenu}
          updateUser={this.updateUser.bind(this)}
          removeUser={this.removeUser.bind(this)}/>
        {(this.state.loading) ? <Icon name="spinner"/> : ""}
        <div className="field name">
          {this.props.thisUser.name}
        </div>
        <div className="field email">
          {(this.props.thisUser.email) ? this.props.thisUser.email : "No Email" }
        </div>
        {(this.props.thisUser.employee_id) ? (
          <div className="field">
            {this.props.thisUser.employee_id}
          </div>
        ) : "" }
        {(this.props.thisUser.phone) ? (
          <div className="field phone">
            {phone}
          </div>
        ) : "" }
        <div className="field groups">
          {groupTags}
        </div>
      </div>
    );
  }
}

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      logout: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loaded(users, groups, status) {
    this.props.loaded(users, groups, status);
  }

  loadData() {
    const submit = async () => {
      // Create query
      let query = `query
        GetOrg($token: String!) {
          getOrg(token: $token) {
            new_token
            status
            groups {
              id
              name
              admin
            }
            users {
              id
              name
              email
              phone
              employee_id
              group_ids
            }
          }
        }`;
      // Create payload
      let payload = { token: this.props.token };
      // Submit to API
      return await API.graphql(graphqlOperation(query, payload));
    }
    submit()
      .then((res)=>{
        const getOrg = res.data.getOrg;
        // Get status
        let status = JSON.parse(getOrg.status)
        // Handle groups
        let groups = [];
        for(let i=0; i<getOrg.groups.length; i++) {
          groups = getOrg.groups;
        }
        // Convert users to object
        let users = {};
        for(let i=0; i<getOrg.users.length; i++) {
          users[getOrg.users[i].id] = getOrg.users[i];
        }
        // Send data to parent
        this.loaded(users, groups, status);
      },
      (err)=>{
        // Log out if invalid token
        if(err.errors[0].message.indexOf("token") >= 0) {
          return this.setState({ logout: true });
        }
        // Handle other errors
        console.log(err);
        this.setState({ error: true });
      });
  }

  render() {
    // Error
    if(this.state.error) return <Status status="error"/>;

    // Log out
    if(this.state.logout) return <Status status="logout"/>

    // Show data if loaded
    if(this.props.users !== null) {
      const user_ids = Object.keys(this.props.users);
      // Handle no users
      let users = [];
      if(user_ids.length === 0) return (
        <div className="empty">
          <Icon name="warning"/>
          <h1>No users to show</h1>
          <h2>This organization does not have any users</h2>
        </div>
      );

      // Show org's users
      for(let i=0; i<user_ids.length; i++) {
        let thisUser = this.props.users[user_ids[i]];
        users.push(
          <User
            key={i}
            updateUser={this.props.updateUser.bind(this)}
            removeUser={this.props.removeUser.bind(this)}
            token={this.props.token}
            groups={this.props.groups}
            user={this.props.user}
            thisUser={thisUser}/>
        );
      }

      // Sort users by user name
      users.sort(function(a, b) {
        let aName = (typeof a.props.thisUser.name === "string")
          ? a.props.thisUser.name
          : a.props.thisUser.id;
        let bName = (typeof b.props.thisUser.name === "string")
          ? b.props.thisUser.name
          : b.props.thisUser.id;
        return aName.localeCompare(bName);
      });

      // Return users
      return (
        <div
          className="cards">
          <Spacer position="top"/>
          {users}
          <Footer
            token={this.props.token}/>
        </div>
      );
    }

    // Loading (fallback)
    return <Status status="loading"/>;
  }
}

class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      groups: [],
      status: {},
      form_data: {},
      redirect: false,
      // Inputs for `add` and `edit` forms
      user_inputs: {
        name: {
          label: "Name",
          required: true,
          placeholder: "First Last",
          order: 1
        },
        email: {
          label: "Email",
          type: "email",
          required: true,
          placeholder: "you@yourcompany.com",
          order: 2
        },
        employee_id: {
          label: "ID #",
          placeholder: "Employee ID (Optional)",
          order: 4
        }
      }
    };
  }

  openForm(params) {
    // Update form data in state
    this.setState({ form_data: params });
  }

  closeForm() {
    this.setState({ form_data: {} });
  }

  loaded(users, groups, status) {
    // Add groups to inputs
    let inputs = JSON.parse(JSON.stringify(this.state.user_inputs));
    // Sort groups by name
    groups.sort(function(a, b) {
      // If missing one or both names
      if(!("name" in a)) {
        if(!("name" in b)) return a.id.localeCompare(b.id);
        else return 1;
      } else if(!("name" in b)) return -1;
      // If group is `Language:`
      if(/^Language:/.test(a.name) && !/^Language:/.test(b.name)) return 1;
      if(!/^Language:/.test(a.name) && /^Language:/.test(b.name)) return -1;
      // Sort by name
      return a.name.localeCompare(b.name);
    });
    // Create inputs
    let order_start = Object.keys(this.state.user_inputs).length + 10;
    for(let i=0; i<groups.length; i++) {
      inputs[groups[i].id] = {
        label: groups[i].name,
        type: "checkbox",
        value: true,
        order: order_start + i
      }
    }

    // Update state
    this.setState({
      user_inputs: inputs,
      users: users,
      groups: groups,
      status: status
    });
  }

  addUser(form_props) {
    const submit = async () => {
      // Create query
      let mutation = `mutation
        CreateUser($token: String!, $email: String!, $name: String, $phone: String, $employee_id: String, $group_ids: [ ID ]) {
          createUser(token: $token, email: $email, name: $name, phone: $phone, employee_id: $employee_id, group_ids: $group_ids) {
            id
            name
            email
            phone
            employee_id
            group_ids
          }
        }`;

      // Groups
      let user_groups = [];
      for(let i=0; i<this.state.groups.length; i++) {
        if(form_props.form[this.state.groups[i].id].value) {
          user_groups.push(this.state.groups[i].id);
        }
      }

      // Create payload
      let payload = {
        token: this.props.token,
        email: form_props.form.email.value,
        name: form_props.form.name.value,
        phone: (form_props.form.phone)
          ? form_props.form.phone.value : null,
        employee_id: (form_props.form.employee_id)
          ? form_props.form.employee_id.value : null,
        group_ids: user_groups
      };

      // Submit to API
      return await API.graphql(graphqlOperation(mutation, payload));
    }
    submit()
      .then((res)=>{
        // Analytics
        Analytics.event("user_created", { event_category: "users" });
        // Add new user to `users` object
        let users = this.state.users;
        users[res.data.createUser.id] = res.data.createUser;
        // Update `users` in state
        this.setState({ users: users });
      },
      (err)=>{
        console.log(err);
        // Analytics
        Analytics.error("user_not_created", { event_category: "users" });
      });
  }

  updateUserOpen(user) {
    // Form inputs
    let inputs = JSON.parse(JSON.stringify(this.state.user_inputs));
    inputs.user_id = {
      type: "hidden",
      value: user.id
    };
    inputs.name.value = user.name;
    inputs.email.value = user.email;
    inputs.employee_id.value = user.employee_id;
    for(let i=0; i<user.group_ids.length; i++) {
      inputs[user.group_ids[i]].checked = true;
    }

    // Add phone input if defined for user
    if(user.phone !== null) {
      inputs.phone = {
        label: "Phone",
        placeholder: "(123) 555-6789",
        order: 3,
        value: user.phone
      };
    }

    // Open form as confirmation
    this.openForm({
      config: {
        callback: this.updateUser.bind(this),
        inputs: inputs,
        title: "Edit User"
      }
    });
  }

  updateUser(form_props) {
    const submit = async () => {
      // Create query
      let mutation = `mutation
        UpdateUser($token: String!, $id: ID!, $email: String, $name: String, $phone: String, $employee_id: String, $group_ids: [ ID ]) {
          updateUser(token: $token, id: $id, email: $email, name: $name, phone: $phone, employee_id: $employee_id, group_ids: $group_ids) {
            id
            name
            email
            phone
            employee_id
            group_ids
          }
        }`;

      // Groups
      let user_groups = [];
      for(let i=0; i<this.state.groups.length; i++) {
        if(form_props.form[this.state.groups[i].id].value) {
          user_groups.push(this.state.groups[i].id);
        }
      }

      // Create payload
      let payload = {
        token: this.props.token,
        id: form_props.form.user_id.value,
        email: form_props.form.email.value,
        name: form_props.form.name.value,
        phone: (form_props.form.phone)
          ? form_props.form.phone.value : null,
        employee_id: (form_props.form.employee_id)
          ? form_props.form.employee_id.value : null,
        group_ids: user_groups
      };

      // Submit to API
      return await API.graphql(graphqlOperation(mutation, payload));
    }
    submit()
      .then((res)=>{
        // Analytics
        Analytics.event("user_updated", { event_category: "users" });
        // Update user to `users` object
        let user = res.data.updateUser;
        let users = this.state.users;
        Object.keys(user).forEach((key) => {
          users[user.id][key] = user[key];
        });
        // Update `users` in state
        this.setState({ users: users });
      },
      (err)=>{
        console.log(err);
        // Analytics
        Analytics.error("user_not_updated", { event_category: "users" });
      });
  }

  redirect(path) {
    this.setState({ redirect: path });
  }

  removeUserConfirm(user) {
    // User name
    let name = (user.name) ? user.name : "this user"

    // Open form as confirmation
    this.openForm({
      config: {
        callback: this.removeUser.bind(this),
        message: "Are you sure you want to remove "+name+"?",
        inputs: {
          user_id: {
            type: "hidden",
            value: user.id
          }
        },
        title: "Remove User"
      }
    });
  }

  removeUser(form_props) {
    const submit = async () => {
      // Create query
      let mutation = `mutation
        RemoveUser($token: String!, $id: ID!) {
          removeUser(token: $token, id: $id) {
            id
          }
        }`;
      // Create payload
      let payload = {
        token: this.props.token,
        id: form_props.form.user_id.value
      };
      // Submit to API
      return await API.graphql(graphqlOperation(mutation, payload));
    }
    submit()
      .then((res)=>{
        // Analytics
        Analytics.event("user_removed", { event_category: "users" });
        // Remove user from `users` object
        let users = this.state.users;
        delete users[res.data.removeUser.id];
        // Update `users` in state
        this.setState({ users: users });
      },
      (err)=>{
        console.log(err);
        // Analytics
        Analytics.error("user_not_removed", { event_category: "users" });
      });
  }

  render() {
    // Redirect
    if(this.state.redirect !== false) {
      return <Redirect to={this.state.redirect} push={true}/>;
    }

    // Buttons
    let buttons = {
      back: {
        icon: "arrow-back",
        title: "Back",
        position: "topleft",
        action: {
          function: "link",
          link: "/"
        }
      },
      add_user: {
        icon: "md-person-add",
        title: "Add User",
        position: "topright",
        if: { admin: true },
        action: {
          callback: this.addUser.bind(this),
          function: "form",
          inputs: this.state.user_inputs,
          title: "Add User"
        }
      }
    };

    // Users
    let users = (
      <Users
        loaded={this.loaded.bind(this)}
        updateUser={this.updateUserOpen.bind(this)}
        removeUser={this.removeUserConfirm.bind(this)}
        users={this.state.users}
        groups={this.state.groups}
        user={this.props.user}
        token={this.props.token}/>
    );

    // Account status
    if("status" in this.state.status
    && this.state.status.status !== "enabled") {
      // Disable buttons other than "back" button
      buttons = { back: buttons.back };
      // Show status instead of users
      users = (
        <Status
          status={this.state.status.status}
          description={this.state.status.description}
          token={this.props.token}/>
      );
    }

    return (
      <div
        className="UsersPage page">
        <InstallPrompt
          token={this.props.token}
          showDownloadModal={this.props.showDownloadModal.bind(this)}/>
        <Updater
          user={this.props.user}
          token={this.props.token}
          updateToken={this.props.updateToken.bind(this)}/>
        <Form
          openForm={this.openForm.bind(this)}
          redirect={this.redirect.bind(this)}
          closeForm={this.closeForm.bind(this)}
          form_data={this.state.form_data}/>
        <Buttons
          openForm={this.openForm.bind(this)}
          redirect={this.redirect.bind(this)}
          token={this.props.token}
          buttons={buttons}
          user={this.props.user}
          groups={this.state.groups}
          page={{ color: "#3f9af7" }}/>
        <Header/>
        {users}
      </div>
    );
  }
}

export default UsersPage;
