import { itemData } from "../Page/ItemFunctions.js"
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isPlainObject';

// Webapp version
const webapp_version_string = `${process.env.REACT_APP_VERSION}`;
// Native app version
const app_version_string = window.nativeDetails.app_version_string;

export function applyItemsConditions(itemsConfig, params) {
  if("conditions" in itemsConfig) {
    // Get all conditions in config
    Object.keys(itemsConfig.conditions)
    // Sort conditions
    .sort((a, b) => {
      let sort = 0;
      // Get conditions
      let orderA = ("order" in itemsConfig.conditions[a])
        ? itemsConfig.conditions[a].order : null;
      let orderB = ("order" in itemsConfig.conditions[b])
        ? itemsConfig.conditions[b].order : null;

      // Sort by order
      if(orderA !== null && orderB !== null) sort = orderA - orderB;
      else if(orderA === null && orderB === null) sort = 0;
        else if(orderA !== null && orderB === null) sort = -1;
          else if(orderA === null && orderB !== null) sort = 1;

      // Sort by ID, if needed
      if(sort === 0) sort = a.localeCompare(b);

      // Return result
      return sort;
    })
    // Loop through conditions
    .forEach((key) => {
      // Evaluate condition
      if(evalIf(itemsConfig.conditions[key].if, params)) {
        // Overwrite existing config with condition's config
        itemsConfig = overwrite(
          itemsConfig,
          cloneDeep(
            // Recursively check for nested conditions
            applyItemsConditions(itemsConfig.conditions[key].config, params)
          )
        );
      }
    });
    return itemsConfig;
  } else return itemsConfig;
}

export function camelCase(style) {
  let newStyle = {};
  // Convert style object's properties to react-friendly camel case
  Object.keys(style).forEach(function(key) {
    // Convert key to camel case
    let newKey = key
      .replace(/-([a-z])/g, function(g) {
        return g[1].toUpperCase()
      });
    // Copy value to new key
    newStyle[newKey] = style[key];
  });
  return newStyle;
}

export function evaluate(string) {
  // Safer than `eval()`
  try {
    // Prevent integers from returning as octals
    if(/^0\d+$/.test(string)) string = "\""+string+"\"";
    // eslint-disable-next-line
    return Function("\"use strict\";return ("+string+")")();
  }
  catch(error) {
    console.error(error);
    console.log(string);
    return false;
  }
}

export function evalIf(ifObj, data) {
  // Function to recursively evaluate `if` object
  const evalIfObj = function(ifObj, data) {
    // Skip if not defined
    if(ifObj === null || typeof ifObj === "undefined") return true;

    // Process if `ifObj` is a boolean
    if(typeof ifObj === "boolean") return ifObj;

    // Process if `ifObj` is a string
    if(typeof ifObj === "string") return evalIfString(ifObj, data);

    // Handle invalid ifObj
    if(typeof ifObj !== "object" || Array.isArray(ifObj)) {
      console.error("Invalid `if` attribute in config");
      return true;
    }

    // Process if `ifObj.statement` is a boolean
    if(ifObj.statement === false) return false;

    // Process if `ifObj.statement` is a string
    if(typeof ifObj.statement === "string") {
      if(!evalIfString(ifObj.statement, data)) return false;
    }

    // Check if user is in any admin groups
    if(typeof ifObj.admin === "boolean") {
      // Check for groups in data
      if(typeof data.groups !== "object"
      || !Array.isArray(data.groups)
      || data.groups === null) {
        console.error("Missing `groups`, cannot check if user is an admin");
        return false;
      }

      // Check if groups have loaded yet
      if(data.groups.length === 0) return false;

      // Check for user in data
      if(typeof data.user !== "object" && data.user !== null) {
        console.error("Missing `user` in data, cannot check if user is an admin");
        return false;
      }

      // Determine if user is a member of any admin groups
      let is_admin = false;
      let user_groups = (Array.isArray(data.user.group_ids))
        ? data.user.group_ids : [];
      for(let i=0; i<user_groups.length; i++) {
        // Check for user's group in org's groups
        for(let j=0; j<data.groups.length; j++) {
          if(user_groups[i] === data.groups[j].id) {
            // Check if group is an admin group
            if(data.groups[j].admin) {
              is_admin = true;
              break;
            }
          }
        }
      }

      // Return if false
      if(is_admin) {
        // User is an admin
        if(ifObj.admin === false) return false;
      } else {
        // User is not an admin
        if(ifObj.admin === true) return false;
      }
    }

    // Check if user is in any of the required groups
    if(Array.isArray(ifObj.group) && ifObj.group.length > 0) {
      // Check for user in data
      if(typeof data.user !== "object" && data.user !== null) {
        console.error("Missing `user` in data, cannot evaluate `group`");
        return false;
      }

      // Check for user groups
      if(typeof data.user.group_ids !== "object"
      || !Array.isArray(data.user.group_ids)) return false;

      // Look for any of the required groups in user's groups
      let valid = false;
      for(let i=0; i<ifObj.group.length; i++) {
        if(data.user.group_ids.indexOf(ifObj.group[i]) >= 0) {
          valid = true;
          break;
        }
      }
      // Return false if none of the required groups were found in user's groups
      if(!valid) return false;
    }

    // Check if user is in any of the prohibited groups
    if(Array.isArray(ifObj["!group"]) && ifObj["!group"].length > 0) {
      // Check for user in data
      if(typeof data.user !== "object" && data.user !== null) {
        console.error("Missing `user` in data, cannot evaluate `!group`");
        return false;
      }

      // Check for user groups
      if(Array.isArray(data.user.group_ids)) {
        // Look for any of the prohibited groups in user's groups
        for(let i=0; i<ifObj["!group"].length; i++) {
          if(data.user.group_ids.indexOf(ifObj["!group"][i]) >= 0) return false;
        }
      }
    }

    // Check if user is in all of the required groups
    if(Array.isArray(ifObj.groups) && ifObj.groups.length > 0) {
      // Check for user in data
      if(typeof data.user !== "object" && data.user !== null) {
        console.error("Missing `user` in data, cannot evaluate `groups`");
        return false;
      }

      // Check for user groups
      if(typeof data.user.group_ids !== "object"
      || !Array.isArray(data.user.group_ids)) return false;

      // Look for each of the required groups in user's groups
      for(let i=0; i<ifObj.groups.length; i++) {
        if(data.user.group_ids.indexOf(ifObj.groups[i]) < 0) return false;
      }
    }

    // Process kiosk mode
    if(typeof ifObj.kiosk === "boolean") {
      // Check for token in data
      if(typeof data.token !== "string") {
        console.error("Missing token, cannot check if in kiosk mode");
        return false;
      }

      // Determine if in kiosk mode
      if("kiosk_id" in jwtDecode(data.token)) {
        // Kiosk mode
        if(ifObj.kiosk === false) return false;
      } else {
        // Normal mode
        if(ifObj.kiosk === true) return false;
      }
    }

    // Process additional `and` conditions
    if(Array.isArray(ifObj.and)) {
      for(let i=0; i<ifObj.and.length; i++) {
        // Return false if any of the `and` conditions fail
        if(!evalIfObj(ifObj.and[i], data)) return false;
      }
    }

    // Process additional `or` conditions
    if(Array.isArray(ifObj.or)) {
      let valid = false;
      for(let i=0; i<ifObj.or.length; i++) {
        // Track validity of `or` conditions
        if(evalIfObj(ifObj.or[i], data)) {
          valid = true;
          break;
        }
      }
      // Return false if all of the `or` conditions fail
      if(!valid) return false;
    }

    // Return true if no prior returns
    return true;
  }

  // Function to insert variables and evaluate a string statement
  const evalIfString = function(ifObj, data) {
    // Insert variables into statement
    let statement = insertVariables(ifObj, data);
    // Evaluate statement
    return (evaluate(statement));
  }

  const jwtDecode = function(token) {
    let payload = token.split('.')[1]
      .replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(payload));
  }

  // Evaluate `if` object and return result
  return evalIfObj(ifObj, data);
}

export function formatClock(value) {
  // Days
  let days = Math.floor(value / 86400)
    .toString();
  let day_or_days = (days === "1") ? "day" : "days";
  // Hours
  let hours = Math.floor(value % 86400 / 3600)
    .toString().padStart(2, '0');
  // Minutes
  let minutes = Math.floor((value % 3600) / 60)
    .toString().padStart(2, '0');
  // Seconds
  let seconds = (value % 60)
    .toString().padStart(2, '0');
  // Combine into formatted value, hide hours if `00`
  return ((days !== '0') ? days + " "+day_or_days+", " : "") +
    ((hours !== '00' || days !== '0') ? hours + ":" : "") +
    minutes + ":" +
    seconds;
}

export function insertConfigVariables(config, string) {
  // Replace all config variables in config (or specific string if defined)
  let new_config = ((string) ? string : JSON.stringify(config))
  .replace(/\\?"?\$config\.variables\.([\w.[\]]+)\((.*?)\)\\?"?/g,
  function(x0, x1, x2) {
    // Check for variable in config variables
    if(!("variables" in config)
    || !config.variables
    || !(x1 in config.variables)) return x0;

    // Return variable value
    let variable = config.variables[x1];
    // Add appropriate quotes before and after, with/without escape
    if(typeof variable === "string" || typeof variable === "number" || variable === null) {
      variable = (/^\\"/.test(x0))  // Escaped quote at start
        ? "\\\""+variable
        : (/^"/.test(x0))  // Unescaped quote at start
          ? "\""+variable
          : variable;
      variable = (/\\"$/.test(x0))  // Escaped quote at end
        ? variable+"\\\""
        : (/"$/.test(x0))  // Unescaped quote at end
          ? variable+"\""
          : variable;
    } else {
      variable = JSON.stringify(variable);
    }
    // Allow config variables to contain other config variables
    return insertConfigVariables(config, variable);
  });

  // Return new config
  return (string) ? new_config : JSON.parse(new_config);
}

const insertVariablesRegex = /\$([\w.[\]]+)\(((?:\{(?:[^({})])*\})*|(?:[^({})])*)\)/g;
// Get variable, e.g. `$item.id()`: \$([\w.[\]]+)\( ... \)
// Get parameters, e.g. `{"id":"1234"}`, `2.12.1`, or blank: ((?:\{(?:[^({})])*\})*|(?:[^({})])*)
  // Find any string without `(`, `{`, `}`, or `)`: (?:[^({})])*
  // Within brackets, e.g. `{"id":"1234"}`: (?:\{ ... \})*
  // Blank parameters are an empty string: handled by `*` (zero or more)

export function insertVariables(
  string,
  data,
  returnOriginal = true,
  quoteWrap = true,
  depth = 0
) {
  // Replace variables in string
  let replaced = replaceVars(string, data, returnOriginal, quoteWrap);

  // Check if there are more variables to replace
  return (string !== replaced  // If string was not changed by last replaceVars(), no need to retry
  && typeof replaced === "string"  // Only replace variables within a string
  && insertVariablesRegex.test(replaced)  // Check for any variables to be replaced in the string
  && depth <= 10)  // Limit depth of replacements to prevent an infinite loop, e.g. if a variable can't be replaced
    ? insertVariables(replaced, data, returnOriginal, quoteWrap, depth+1)
    : replaced;
}

function replaceVars(string, data, returnOriginal, quoteWrap) {
  // Validate string
  if(typeof string !== "string") return string;

  // Replace variables in string, if x2 contains a variable it must be inside an object
  return string.replace(insertVariablesRegex, function(x0, x1, x2) {
    // Create regular expression to check if variable is already quote-wrapped
    let checkQuotesRegex =
      new RegExp("\""+x0.replace(/([$.(){}[\]])/g, "\\$1")+"\"");
    // Create return variable if error
    let variable = (!returnOriginal) ? "" :
      (quoteWrap && !checkQuotesRegex.test(string)) ? "\""+x0+"\"" : x0;

    // Get utility variable category
    let category = x1.match(/^\w+(?=\.|\[|$)/);
    if(category == null) return variable;
    else category = category[0];

    // Find variable matching `x1` (`$1`) and pass parameters `x2` (`$2`)
    switch(category) {

      case "app":
        if(x1 === "app.version") {
          return (quoteWrap)
            ? "\""+app_version_string+"\""
            : app_version_string;
        }

        // Minimum App Version
        if(x1 === "app.minVersion" && /^(^\d+|\.\d+){1,4}$/.test(x2)) {
          // Convert versions to integers
          let thisAppVersion = versionNumber(app_version_string);
          let minVersion = versionNumber(x2);
          // Compare and return
          return (thisAppVersion >= minVersion);
        }

        // Return original variable if no match found
        return variable;

      case "webapp":
        // Webapp Version
        if(x1 === "webapp.version") {
          return (quoteWrap)
            ? "\""+webapp_version_string+"\""
            : webapp_version_string;
        }

        // Minimum Webapp Version
        if(x1 === "webapp.minVersion" && /^(^\d+|\.\d+){1,4}$/.test(x2)) {
          // Convert versions to integers
          let thisWebappVersion = versionNumber(webapp_version_string);
          let minVersion = versionNumber(x2);
          // Compare and return
          return (thisWebappVersion >= minVersion);
        }

        // Return original variable if no match found
        return variable;

      case "form":
        // Get input, e.g. `form.notes` -> `notes` or `form.user.text` -> `user`
        let match = x1.match(/\.([\w\d]+)(?=\.([\w\d]+)|)/);
        if(match === null) return variable
        let input = match[1];
        let type = (typeof match[2] !== "undefined")
          ? match[2] : "value";  // e.g. `text` or `value`

        // Check if this form has been passed in function arguments
        if(!("form" in data) || typeof data.form !== "object") {
          return variable;
        }

        // Get input from `form`
        if(input in data.form) {
          // Check for arguments
          let prepend = "", append = "";
          try {
            let x2parsed = JSON.parse(x2);
            if("prepend" in x2parsed) prepend = x2parsed.prepend;
            if("append" in x2parsed) append = x2parsed.append;
          } catch(err) {}
          // Get value
          let value = data.form[input][type];
          if(typeof value === "string" && value !== "") {
            value = prepend + value + append;
          }
          // Wrap input value in quotes if a string
          return (typeof value === "string" && quoteWrap)
            ? "\""+value+"\""
            : value;
        }
        // If input is not in form, return null
        return null;

      case "groups":
        if(x1 === "groups.id"
        || x1 === "groups.name") {
          // Check if `groups` are in `data`
          if("groups" in data) {
            // Sort groups by name then ID for consistent responses
            data.groups.sort(function(a, b) {
              let sort = 0;
              if(a.name !== null) {
                if(b.name !== null) sort = a.name.localeCompare(b.name);
                else sort = -1;
              } else if (b.name !== null) sort = 1;
              if(sort === 0) sort = a.id.localeCompare(b.id);
              return sort;
            });
            // Parse arguments
            let idFilter = null, idFilterRegex = false;
            let nameFilter = null, nameFilterRegex = false;
            try {
              let x2parsed = JSON.parse(x2);
              // Filter by ID
              if("id" in x2parsed) {
                idFilter = x2parsed.id;
                let match = idFilter.match(/^\/(.*)\/(\w+)?$/);
                if(match !== null) {
                  idFilterRegex = true;
                  idFilter = (match.length === 2)
                    ? RegExp(match[1])
                    : RegExp(match[1], match[2]);
                }
              }
              // Filter by name
              if("name" in x2parsed) {
                nameFilter = x2parsed.name;
                let match = nameFilter.match(/^\/(.*)\/(\w+)?$/);
                if(match !== null) {
                  nameFilterRegex = true;
                  nameFilter = (match.length === 2)
                    ? RegExp(match[1])
                    : RegExp(match[1], match[2]);
                }
              }
            } catch(err) {}
            // Loop through groups
            let groups = [];
            for(let i=0; i<data.groups.length; i++) {
              // Check ID filter
              if(idFilter !== null) {
                if(idFilterRegex) {
                  // Filter by testing regular expression against group ID
                  if(!idFilter.test(data.groups[i].id)) continue;
                } else {
                  // Filter by matching string against group ID
                  if(idFilter !== data.groups[i].id) continue;
                }
              }
              // Check name filter
              if(nameFilter !== null) {
                if(nameFilterRegex) {
                  // Filter by testing regular expression against group name
                  if(!nameFilter.test(data.groups[i].name)) continue;
                } else {
                  // Filter by matching string against group name
                  if(nameFilter !== data.groups[i].name) continue;
                }
              }
              // Set value
              let val;
              if(x1 === "groups.id") val = data.groups[i].id;
              if(x1 === "groups.name") val = data.groups[i].name;
              // Add value to array, wrap in quotes if specified
              groups.push(val);
            }
            // Return groups
            let value = groups
            return (quoteWrap) ? JSON.stringify(value) : value;
          }
        }
        // Return original variable if no match found
        return variable;

      case "item":
        // Item's position on page
        if(x1 === "item._position"
        || x1 === "item._positionNominal") {
          let x = null;
          // Get item's position on page if found
          if("item" in data && "_position" in data.item) {
            x = data.item._position;
          }
          // If getting the nominal position, add `1` to eliminate `0` position
          return (x1 === "item._positionNominal" && x !== null)
            ? (x + 1) : x;
        }

        // Item's position on page (including faux items)
        if(x1 === "item._positionFaux"
        || x1 === "item._positionFauxNominal") {
          let x = null;
          // Get item's position on page if found
          if("item" in data && "_positionFaux" in data.item) {
            x = data.item._positionFaux;
          }
          // If getting the nominal position, add `1` to eliminate `0` position
          return (x1 === "item._positionFauxNominal" && x !== null)
            ? (x + 1) : x;
        }

        // Get field, e.g. `item.created_at` -> `created_at`
        let item_match, field;
        item_match = x1.match(/item\.((?:(data|parent|parent\.data)\.)?([\w\d]+))$/);
        if(item_match !== null) field = item_match[1];
        else return variable;

        // Check if this item has been passed in function arguments
        if(!("item" in data) || typeof data.item !== "object") {
          return variable;
        }

        // Get field from `item`
        let value = itemData(data.item, field);
        if(value !== null) {
          // Wrap field value in quotes if a string
          return (typeof value === "string" && quoteWrap)
            ? "\""+value+"\""
            : value;
        }
        // Return null if field is not in `item`
        return null;

      case "item_updated":
        // Updated item's position on page
        if(x1 === "item_updated._position"
        || x1 === "item_updated._positionNominal") {
          let x = null;
          // Get item's position on page if found
          if("item_updated" in data && "_position" in data.item_updated) {
            x = data.item_updated._position;
          }
          // If getting the nominal position, add `1` to eliminate `0` position
          return (x1 === "item_updated._positionNominal" && x !== null)
            ? (x + 1) : x;
        }

        // Updated item's position on page (including faux items)
        if(x1 === "item_updated._positionFaux"
        || x1 === "item_updated._positionFauxNominal") {
          let x = null;
          // Get item's position on page if found
          if("item_updated" in data && "_positionFaux" in data.item_updated) {
            x = data.item_updated._positionFaux;
          }
          // If getting the nominal position, add `1` to eliminate `0` position
          return (x1 === "item_updated._positionFauxNominal" && x !== null)
            ? (x + 1) : x;
        }

        // Get field_updated, e.g. `item_updated.created_at` -> `created_at`
        let item_updated_match, field_updated;
        item_updated_match = x1.match(/item_updated\.((?:data\.)?([\w\d]+))$/);
        if(item_updated_match !== null) field_updated = item_updated_match[1];
        else return variable;

        // Check if this updated item has been passed in function arguments
        if(!("item_updated" in data) || typeof data.item_updated !== "object") {
          return variable;
        }

        // Get field from `item_updated`
        let value_updated = itemData(data.item_updated, field_updated);
        if(value_updated !== null) {
          // Wrap field value in quotes if a string
          return (typeof value_updated === "string" && quoteWrap)
            ? "\""+value_updated+"\""
            : value_updated;
        }
        // Return null if field_updated is not in `item_updated`
        return null;

      case "items":
        let items_match =
          x1.match(/^items(?:\[(\d+)\]|)\.((?:data\.)?([\w\d]+))/);
        if(items_match !== null
        && "items" in data
        && typeof data.items === "object") {
          let items = data.items;
          let field = items_match[2];
          let index = items_match[1];
          return itemsVariables(x1, x2, items, field, index, quoteWrap);
        }
        // Return original variable if no match found
        return variable;

      case "items_updated":
        let items_updated_match =
          x1.match(/^items_updated(?:\[(\d+)\]|)\.((?:data\.)?([\w\d]+))/);
        if(items_updated_match !== null
        && "items_updated" in data
        && typeof data.items_updated === "object") {
          let items = data.items_updated;
          let field = items_updated_match[2];
          let index = items_updated_match[1];
          return itemsVariables(x1, x2, items, field, index, quoteWrap);
        }
        // Return original variable if no match found
        return variable;

      case "page":
        // Page ID
        if(x1 === "page.id") {
          // Check if page is in `data`
          if("page" in data && data.page !== null && "id" in data.page) {
            let value = data.page.id;
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // Page Name
        if(x1 === "page.name") {
          // Check if page is in `data`
          if("page" in data && data.page !== null && "name" in data.page) {
            let value = data.page.name;
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // Return original variable if no match found
        return variable;

      case "response":
        // Entire response
        if(x1 === "response") {
          if("response" in data) {
            let value = data.response;
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // Parse response and get attribute
        if(/response\.\w+/.test(x1)) {
          if("response" in data) {
            // Get response attribute from variable
            let attribute = x1.match(/response\.(\w+)/)[1];
            // Parse response (if possible)
            try {
              let response = JSON.parse(data.response);
              // Return attribute's value from response if defined
              if(attribute in response) {
                let value = response[attribute];
                return (quoteWrap) ? "\""+value+"\"" : value;
              }
            } catch(error) {}
          }
        }

        // Return original variable if no match found
        return variable;

      case "user":
        // User ID
        if(x1 === "user.id") {
          // Check if user is in `data`
          if("user" in data && data.user !== null && "id" in data.user) {
            let value = data.user.id;
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // User groups in current org
        if(x1 === "user.groups") {
          // Check if group IDs are in `data`
          if("user" in data && data.user !== null && "group_ids" in data.user) {
            let value = data.user.group_ids;
            return (quoteWrap) ? JSON.stringify(value) : value;
          }
        }

        // User's name
        if(x1 === "user.name") {
          // Check for arguments
          let prepend = "", append = "";
          try {
            let x2parsed = JSON.parse(x2);
            if("prepend" in x2parsed) prepend = x2parsed.prepend;
            if("append" in x2parsed) append = x2parsed.append;
          } catch(err) {}
          // Check if user is in `data`
          if("user" in data && data.user !== null
          && "name" in data.user && data.user.name !== null) {
            let value = prepend + data.user.name + append;
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // User's email
        if(x1 === "user.email") {
          // Check for arguments
          let prepend = "", append = "";
          try {
            let x2parsed = JSON.parse(x2);
            if("prepend" in x2parsed) prepend = x2parsed.prepend;
            if("append" in x2parsed) append = x2parsed.append;
          } catch(err) {}
          // Check if user is in `data`
          if("user" in data && data.user !== null
          && "email" in data.user && data.user.email !== null) {
            let value = prepend + data.user.email + append;
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // User's employee_id
        if(x1 === "user.employee_id") {
          // Check for arguments
          let prepend = "", append = "";
          try {
            let x2parsed = JSON.parse(x2);
            if("prepend" in x2parsed) prepend = x2parsed.prepend;
            if("append" in x2parsed) append = x2parsed.append;
          } catch(err) {}
          // Check if user is in `data`
          if("user" in data && data.user !== null
          && "employee_id" in data.user && data.user.employee_id !== null) {
            let value = prepend + data.user.employee_id + append;
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // Return original variable if no match found
        return variable;

      case "users":
        if(x1 === "users.id"
        || x1 === "users.name"
        || x1 === "users.email"
        || x1 === "users.employee_id") {
          // Check if `users` are in `data`
          if("users" in data) {
            // Sort users by name then ID for consistent responses
            data.users.sort(function(a, b) {
              let sort = 0;
              if(a.name !== null) {
                if(b.name !== null) sort = a.name.localeCompare(b.name);
                else sort = -1;
              } else if (b.name !== null) sort = 1;
              if(sort === 0) sort = a.id.localeCompare(b.id);
              return sort;
            });
            // Parse filters
            let filters = {};
            let group_ids = [];
            if(typeof x2 === "string" && x2[0] === "{") {
              filters = JSON.parse(x2.replace(/\\"/g, '"'));
              if("group" in filters) group_ids = filters.group;
            }
            // Loop through users
            let users = [];
            for(let i=0; i<data.users.length; i++) {
              // Filter by user info
              if("id" in filters && data.users[i].id !== filters.id) continue;
              if("name" in filters && data.users[i].name !== filters.name) continue;
              if("email" in filters && data.users[i].email !== filters.email) continue;
              if("employee_id" in filters && data.users[i].employee_id !== filters.employee_id) continue;
              // Filter by group (if specified)
              let validGroup = (group_ids.length > 0) ? false : true;
              for(let j=0; j<group_ids.length; j++) {
                if(data.users[i].group_ids.indexOf(group_ids[j]) >= 0) {
                  validGroup = true;
                  break;
                }
              }
              if(!validGroup) continue;  // Skip user if it is missing one of the required groups
              // Set value
              let val;
              if(x1 === "users.id") val = data.users[i].id;
              if(x1 === "users.name") val = data.users[i].name;
              if(x1 === "users.email") val = data.users[i].email;
              if(x1 === "users.employee_id") val = data.users[i].employee_id;
              // Add value to array, wrap in quotes if specified
              users.push(val);
            }
            // Return users
            let value = users
            return (quoteWrap) ? JSON.stringify(value) : value;
          }
        }
        // Return original variable if no match found
        return variable;

      case "time":
        // $time.at()
        if(x1 === "time.at") {
          // Check for arguments
          let args = {};
          try { args = JSON.parse(x2); } catch(err) {}

          // Get timezone
          const timezone = ("timezone" in args)
            ? args.timezone : "America/Los_Angeles";  // Specify a timezone fallback for consistent response regardless of client location
          // Get current time in specified timezone
          const now = new Date();
          const zonetime = new Date(now.toLocaleString("en-US",
            { timeZone: timezone }));
          // Get offset to timezone in seconds (round down to ignore milliseconds)
          let offset = (Math.floor(zonetime.getTime() / 1000) -
            Math.floor(now.getTime() / 1000)) * 1000;

          // Get date from string (`tomorrow`, etc.)
          const dayStrings = [ "today", "tomorrow", "yesterday" ];
          if("day" in args && dayStrings.indexOf(args.day) >= 0) {
            // Convert day string to a date
            switch(args.day) {
              case "tomorrow":
                args.day = zonetime.getDate() + 1;
                break;

              case "yesterday":
                args.day = zonetime.getDate() - 1;
                break;

              default:
                args.day = zonetime.getDate();  // Today
            }
          }

          // Assume minutes and seconds are `0` if only hour is specified
          if("hour" in args && !("minute" in args)) args.minute = 0;
          if("hour" in args && !("second" in args)) args.second = 0;

          // Convert arguments to a date object in specified timezone
          const newtime = new Date(
            ("year" in args) ? args.year : zonetime.getFullYear(),
            ("month" in args) ? args.month : zonetime.getMonth(),
            ("day" in args) ? args.day : zonetime.getDate(),
            ("hour" in args) ? args.hour : zonetime.getHours(),
            ("minute" in args) ? args.minute : zonetime.getMinutes(),
            ("second" in args) ? args.second : zonetime.getSeconds()
          );
          // Convert date object from timezone back to UTC
          return Math.floor(new Date(newtime - offset) / 1000);
        }

        // $time.now()
        if(x1 === "time.now") {
          // Return current time since epoch in seconds
          return Math.floor(new Date() / 1000);
        }
        // Return if no match
        return variable;

      case "zone":
        // Active
        if(x1 === "zone.active") {
          // Check if zone is in `data`
          if("zone" in data && data.zone !== null) {
            let value = ("_active" in data.zone)
              ? data.zone._active
              : false;  // Default to `active: false`
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // Completed
        if(x1 === "zone.completed") {
          // Check if zone is in `data`
          if("zone" in data && data.zone !== null) {
            let value = ("_completed" in data.zone)
              ? data.zone._completed
              : false;  // Default to `completed: false`
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // List ID
        if(x1 === "zone.list_id") {
          // Check if zone is in `data`
          if("zone" in data && data.zone !== null && "list_id" in data.zone) {
            let value = data.zone.list_id;
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // Name
        if(x1 === "zone.name") {
          // Check if zone is in `data`
          if("zone" in data && data.zone !== null && "name" in data.zone) {
            let value = data.zone.name;
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // Required
        if(x1 === "zone.required") {
          // Check if zone is in `data`
          if("zone" in data && data.zone !== null) {
            let value = ("required" in data.zone)
              ? data.zone.required
              : true;  // Default to `required: true`
            return (quoteWrap) ? "\""+value+"\"" : value;
          }
        }

        // Return original variable if no match found
        return variable;

      case "zones":
        // `zones.list_id`, `zones[0].list_id`, `zones.name`, or `zones[0].name`
        let zones_match = x1.match(/^zones(?:\[(\d+)\]|)\.(list_id|name)/);
        if(zones_match !== null) {
          let field = zones_match[2];
          let index = zones_match[1];
          // Check if `zones` are in `data`
          if("page" in data && "zones" in data.page) {
            // Parse arguments
            let idFilter = null, idFilterRegex = false;
            let nameFilter = null, nameFilterRegex = false;
            try {
              let x2parsed = JSON.parse(x2);
              // Filter by ID
              if("list_id" in x2parsed) {
                idFilter = x2parsed.list_id;
                // Check if idFilter is a regular expression
                let match = idFilter.match(/^\/(.*)\/(\w+)?$/);
                if(match !== null) {
                  idFilterRegex = true;
                  idFilter = (match.length === 2)
                    ? RegExp(match[1])
                    : RegExp(match[1], match[2]);
                }
              }
              // Filter by name
              if("name" in x2parsed) {
                nameFilter = x2parsed.name;
                // Check if nameFilter is a regular expression
                let match = nameFilter.match(/^\/(.*)\/(\w+)?$/);
                if(match !== null) {
                  nameFilterRegex = true;
                  nameFilter = (match.length === 2)
                    ? RegExp(match[1])
                    : RegExp(match[1], match[2]);
                }
              }
            } catch(err) {}
            // Loop through zones
            let zones = [];
            for(let i=0; i<data.page.zones.length; i++) {
              // Check ID filter
              if(idFilter !== null) {
                if(idFilterRegex) {
                  // Filter by testing regular expression against list_id
                  if(!idFilter.test(data.page.zones[i].list_id)) continue;
                } else if (Array.isArray(idFilter)) {
                  // Filter by checking array for list_id
                  if(idFilter.indexOf(data.page.zones[i].list_id) < 0) continue;
                } else {
                  // Filter by matching string against list_id
                  if(idFilter !== data.page.zones[i].list_id) continue;
                }
              }

              // Check name filter
              if(nameFilter !== null) {
                if(nameFilterRegex) {
                  // Filter by testing regular expression against group name
                  if(!nameFilter.test(data.page.zones[i].name)) continue;
                } else if (Array.isArray(nameFilter)) {
                  // Filter by checking array for name
                  if(nameFilter.indexOf(data.page.zones[i].name) < 0) continue;
                } else {
                  // Filter by matching string against group name
                  if(nameFilter !== data.page.zones[i].name) continue;
                }
              }

              // Set value
              let val;
              if(field === "list_id") val = data.page.zones[i].list_id;
              if(field === "name") val = data.page.zones[i].name;
              // Add value to array, wrap in quotes if specified
              zones.push(val);
            }

            // Result
            let value = zones;
            // If an index is given, return that value instead of an array
            if(index !== "" && typeof index !== "undefined") value = value[index];
            // Wrap field value in quotes
            if(quoteWrap) {
              value = (typeof value === "string")
                ? "\""+value+"\""
                : JSON.stringify(value);
            }
            // Return zones
            return value;
          }
        }
        // Return original variable if no match found
        return variable;

      default:
        // Return original if no variables found
        return variable;
    }
  });
}

export function isAlgebraic(expression) {
  // Phone numbers are not algebraic
  if(/^((\d{3}[-\s]\d{3}[-\s]\d{4}))$/.test(expression)) return false;

  // Expression must contain at least a digit, a symbol, then a digit
  return /^(?=\d+\s*[+\-*/]\s*\d+)[\d.\s+\-*/]+$/.test(expression);
}

export function isIfElse(string) {
  // String must be formatted as `(...) ? ... : ...`
  return /^\(.+\)\s*\?.+\s*:.*$/.test(string);
}

export function isNumeric(n) {
  return !/[a-z]/i.test(n) && !isNaN(parseFloat(n)) && isFinite(n);
}

function itemsVariables(x1, x2, items, field, index, quoteWrap) {
  let res = [];
  let args = [];
  try { if(x2 !== "") args = JSON.parse(x2) } catch(e) {}
  let filters = (args.length === 0) ? [] : Object.keys(args);
  // Loop through items
  itemsLoop:
  for(let i=0; i<items.length; i++) {
    // Loop through each field in filter
    for(let j=0; j<filters.length; j++) {
      // Check if item's field matches filter's value
      if(itemData(items[i], filters[j]) !== args[filters[j]]) {
        // If item's field value does not match filter, skip item
        continue itemsLoop;
      }
    }
    // Add item field to array of item fields
    res.push(itemData(items[i], field));
  }
  // Items array meta-data
  if(field === "_count") return res.length;
  if(field === "_last_index") return (res.length > 0) ? res.length - 1 : null;
  // If an index is given, return that value instead of an array
  let value;
  let isArray = false;
  if(index !== "" && typeof index !== "undefined") value = res[index];
  else {
    value = JSON.stringify(res);
    isArray = true;
  }
  // Wrap field value in quotes if a string
  return (typeof value === "string" && quoteWrap && !isArray)
    ? "\""+value+"\""
    : value;
}

export function overwrite(oldConfig, newConfig) {
  // Create new config object
  let config = (isObject(oldConfig)) ? cloneDeep(oldConfig) : {};

  // Check if new config is valid
  if(!isObject(newConfig)) return config;

  // Loop through new object's keys
  Object.keys(newConfig).forEach(function(key) {
    const oldVal = (oldConfig !== null && key in oldConfig)
      ? oldConfig[key] : null;
    const newVal = newConfig[key];
    // Overwrite object recursively
    if(isObject(newVal) && (isObject(oldVal) || oldVal === null)) {
      config[key] = overwrite(oldVal, newVal);
    }
    // Overwrite string
    else config[key] = newVal;
  });

  // Return config
  return config;
}

function versionNumber(version) {
  // Undefined version
  if(typeof version === "undefined") return 0;

  // Split version into sections
  let v;
  const match = version.match(/^(\d+)(?:\.(\d*)|)(?:\.(\d*)|)(?:\.(\d*)|)$/);
  // Combine into one string
  v = (typeof match[1] !== "undefined") ? match[1] : "";  // Major
  v += (typeof match[2] !== "undefined") ? match[2].padStart(3, "0") : "000";  // Minor
  v += (typeof match[3] !== "undefined") ? match[3].padStart(3, "0") : "000";  // Patch
  v += (typeof match[4] !== "undefined") ? match[4].padStart(3, "0") : "000";  // Build
  // Return as an integer
  return Number(v);
}
