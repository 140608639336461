import React, { Component } from 'react';
import * as Actions from "../Actions/Actions";
import Icon from "../Icon/Icon";
import * as Util from "../Utilities/Utilities";
import "./Button.css";

class ButtonMenu extends Component {
  render() {
    // Create each Button
    let menu_buttons = [];
    let buttons = this.props.buttons;
    // Sort buttons
    let button_keys = Object.keys(buttons)
      .sort((a, b) => {
        if("order" in buttons[a]) {
          if("order" in buttons[b]) return buttons[a].order - buttons[b].order;
          else return -1;
        } else if("order" in buttons[b]) return 1;
        return buttons[a].title.localeCompare(b.title);
      });
    // Add each button to menu
    for(let i=0; i<button_keys.length; i++) {
      let button = buttons[button_keys[i]];
      menu_buttons.push(
        <Button
          key={i}
          id={button_keys[i]}
          token={this.props.token}
          user={this.props.user}
          groups={this.props.groups}
          openForm={this.props.openForm.bind(this)}
          redirect={this.props.redirect.bind(this)}
          updateToken={this.props.updateToken.bind(this)}
          button={button}/>
      );
    }

    // Return menu
    return (
      <div className="menu">
        {menu_buttons}
      </div>
    );
  }
}

export class Button extends Component {
  constructor(props) {
    super(props);
    // Bind functions
    this.updateToken = ("updateToken" in this.props)
      ? this.props.updateToken.bind(this)
      : () => {}
    // Define intervals
    this.configInterval = null;
    // Set initial state
    this.state = {
      showMenu: false,
      visible: true
    };
  }

  componentDidMount() {
    this.processConfig();

    // Process config every second if it uses `$time`
    const config = this.props.button;
    if(/\$time\./.test(JSON.stringify(config.if))) {
      this.configInterval = setInterval(() => this.processConfig(), 1000);
    }
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      // Update component when props update
      this.processConfig();
    }
  }

  componentWillUnmount() {
    // Clear intervals
    clearInterval(this.configInterval);
  }

  doAction(event, action) {
    // Check if function was called by click (instead of callback)
    if(typeof action === "undefined") {
      // Show loading on click only
      if("toggleLoading" in this.props) this.props.toggleLoading(true);
      // Get action from props instead of function arguments
      action = this.props.button.action;
    }

    // Menu open
    if("buttons" in this.props.button
    && Object.keys(this.props.button.buttons).length > 0) {
      // Toggle menu
      this.setState({ showMenu: !this.state.showMenu });
      return;
    }

    let props = {
      token: this.props.token,
      item: this.props.item,
      items: this.props.items,
      zone: this.props.zone,
      user: this.props.user,
      groups: this.props.groups,
      page: this.props.page,
      openForm: this.buttonOpenForm.bind(this),
      redirect: ("redirect" in this.props)
        ? this.props.redirect.bind(this)
        : () => { return false },
      updateItem: ("updateItem" in this.props)
        ? this.props.updateItem.bind(this)
        : () => {},
      updateToken: this.updateToken.bind(this)
    };

    Actions.doAction(action, props);
  }

  buttonOpenForm(params) {
    // Open Form
    if(!("openForm" in this.props)) {
      console.error("Missing openForm in button props");
      return;
    }
    this.props.openForm(params);
    // Hide loading
    if("toggleLoading" in this.props) this.props.toggleLoading(false);
  }

  processConfig() {
    const config = this.props.button;

    // Prepare data
    let ivdata = {
      token: this.props.token,
      item: this.props.item,
      items: this.props.items,
      zone: this.props.zone,
      page: this.props.page,
      groups: this.props.groups,
      user: this.props.user
    };

    // Evaluate button's `if` statement
    this.setState({ visible: Util.evalIf(config.if, ivdata) });
  }

  render() {
    if(!this.state.visible) return "";

    // Button class
    const className = ("class" in this.props.button
    && this.props.button.class !== null)
      ? "button "+this.props.button.class
      : "button";

    // Button icon
    const icon = ("icon" in this.props.button
    && this.props.button.icon !== null)
      ? <Icon name={this.props.button.icon}/>
      : "";

    // Button title
    const title = ("title" in this.props.button
    && this.props.button.title !== null)
      ? <span>{this.props.button.title}</span>
      : "";

    // Menu
    let menu = "";
    if("buttons" in this.props.button && this.state.showMenu) {
      menu = (
        <ButtonMenu
          openForm={this.buttonOpenForm.bind(this)}
          redirect={("redirect" in this.props)
            ? this.props.redirect.bind(this)
            : () => { return false }}
          updateToken={this.updateToken.bind(this)}
          token={this.props.token}
          user={this.props.user}
          buttons={this.props.button.buttons}/>
      )
    }

    // Create button
    return (
      <div
        style={this.props.style}
        onClick={this.doAction.bind(this)}
        className={className}>
        {icon}
        {title}
        {menu}
      </div>
    );
  }
}

export class Buttons extends Component {
  constructor(props) {
    super(props);
    this.updateInset = this.updateInset.bind(this);
    this.state = {
      top_inset: (window.injectedData.safe_area_insets)
        ? window.injectedData.safe_area_insets.top : false
    };
  }

  componentDidMount() {
    // Update inset on native injection
    document.addEventListener("onDataInjection", this.updateInset);
  }

  componentWillUnmount() {
    // Remove event lsiteners before unmounting component
    document.removeEventListener("onDataInjection", this.updateInset);
  }

  updateInset() {
    if(!("safe_area_insets" in window.injectedData)) return;
    this.setState({ top_inset: window.injectedData.safe_area_insets.top });
  }

  render() {
    // Get page
    const page = ("page" in this.props && this.props.page !== null)
      ? this.props.page : {};

    // Get buttons config
    const config_buttons = ("buttons" in this.props
    && this.props.buttons !== null) ? this.props.buttons : {};

    let buttons = [],
      buttonsTopLeft = [],
      buttonsTopRight = [],
      buttonsBottomRight = [],
      buttonsBottomLeft = [];
    // Process buttons
    let button_keys = Object.keys(config_buttons)
    // Sort conditions
    .sort((a, b) => {
      let sort = 0;
      // Get conditions
      let orderA = ("order" in config_buttons[a])
        ? config_buttons[a].order : null;
      let orderB = ("order" in config_buttons[b])
        ? config_buttons[b].order : null;

      // Sort by order
      if(orderA !== null && orderB !== null) sort = orderA - orderB;
      else if(orderA === null && orderB === null) sort = 0;
        else if(orderA !== null && orderB === null) sort = -1;
          else if(orderA === null && orderB !== null) sort = 1;

      // Sort by ID, if needed
      if(sort === 0) sort = a.localeCompare(b);

      // Return result
      return sort;
    });

    // Style
    let style = {};
    if("color" in page && page.color !== null) {
      style.background = page.color;
    }
    // Bind openForm function
    let openForm = ()=>{};
    if("openForm" in this.props) {
      openForm = this.props.openForm.bind(this);
    }
    // Bind redirect function
    let redirect = () => { return false };
    if("redirect" in this.props) {
      redirect = this.props.redirect.bind(this);
    }
    // Bind updateItem function
    let updateItem = ()=>{};
    if("updateItem" in this.props) {
      updateItem = this.props.updateItem.bind(this);
    }
    // Bind updateToken function
    let updateToken = ()=>{};
    if("updateToken" in this.props) {
      updateToken = this.props.updateToken.bind(this);
    }
    // Loop through buttons
    for(let i=0; i<button_keys.length; i++) {
      let button = config_buttons[button_keys[i]];
      // Create button
      let b = (
        <Button
          key={i}
          id={button_keys[i]}
          items={this.props.items}
          user={this.props.user}
          groups={this.props.groups}
          page={page}
          token={this.props.token}
          updateToken={updateToken}
          openForm={openForm}
          redirect={redirect}
          updateItem={updateItem}
          style={style}
          button={button}/>
      );
      // Add button to correct position
      let position = ("position" in button) ? button.position : null;
      switch(position) {
        case "topleft":
          buttonsTopLeft.push(b);
          break;

        case "bottomright":
          buttonsBottomRight.push(b);
          break;

        case "bottomleft":
          buttonsBottomLeft.push(b);
          break;

        default:
          buttonsTopRight.push(b);
      }
    }

    // Create button containers
    if(buttonsTopLeft.length > 0) {
      buttons.push(
        <div
          key="topleft"
          style={{
            top: (this.state.top_inset) ? this.state.top_inset+"px" : "9px"
          }}
          className="page_buttons top left">
          {[ buttonsTopLeft ]}
        </div>
      );
    }
    if(buttonsTopRight.length > 0) {
      buttons.push(
        <div
          key="topright"
          style={{
            top: (this.state.top_inset) ? this.state.top_inset+"px" : "9px"
          }}
          className="page_buttons top right">
          {[ buttonsTopRight ]}
        </div>
      );
    }
    if(buttonsBottomRight.length > 0) {
      buttons.push(
        <div
          key="bottomright"
          className="page_buttons bottom right">
          {[ buttonsBottomRight ]}
        </div>
      );
    }
    if(buttonsBottomLeft.length > 0) {
      buttons.push(
        <div
          key="bottomleft"
          className="page_buttons bottom left">
          {[ buttonsBottomLeft ]}
        </div>
      );
    }

    // Add buttons to page
    return [ buttons ];
  }
}
