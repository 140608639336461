import * as Util from "../Utilities/Utilities";
import { mutateItem, sortItems } from "../Page/ItemFunctions.js"
import Analytics from '../analytics';
import Notifications from '../Native/Notifications.js';
import cloneDeep from 'lodash/cloneDeep';
import cloneDeepWith from 'lodash/cloneDeepWith';
import isObject from 'lodash/isPlainObject';

import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import awsmobile from '../amplify-config';
Amplify.configure(awsmobile);

function processActionData(data, props) {
  // Insert variables into data object
  let keys = Object.keys(data);
  for(let i=keys.length-1; i>= 0; i--) {
    let key = keys[i];
    // Replace variables with values
    data[key] = Util.insertVariables(data[key], props, false, false);
    // Evaluate (prevent integers/timestamps from returning as strings)
    if(data[key] === "null"
    || Util.isIfElse(data[key])
    || Util.isAlgebraic(data[key])
    || Util.isNumeric(data[key])) {
      data[key] = Util.evaluate(data[key]);
    }
    // No empty values in `data`
    if(data[key] === "" || data[key] === "undefined") data[key] = null;

    // Replace variables in keys with values
    let k = Util.insertVariables(key, props, false, false);
    if(typeof k === "string" && k !== key) {
      // Update key in data
      data[k] = data[key];
      delete data[key];
    }
  }
  // Return processed data
  return data;
}

export async function doAction(actionReference, props) {
  // Ignore invalid actions
  if(!actionReference) return;

  // Copy `action` without reference, prevent recursive callbacks
  let action = cloneDeepWith(actionReference, (value) => {
    // Pass-through for functions (cannot be cloned, return empty by default)
    if(typeof value === "function") return value;
  });

  // Handle array of callbacks
  if(Array.isArray(action)) {
    // Invoke each action in array asynchronously
    for(let i=0; i<action.length; i++) {
      doAction(action[i], props);
    }
    // Stop function, each sub-action has been invoked
    return;
  }

  // Check for callbacks
  if("callbacks" in action && Array.isArray(action.callbacks)) {
    // Check for `callback`
    if("callback" in action && action.callback !== null) {
      // Combine `callback` and `callbacks` into this action's callback
      action.callback = [ action.callback ].concat(action.callbacks);
      // Delete `callbacks` now that is is part of `callback`
      delete action.callbacks;
    } else {
      // Assign array of callbacks to this action's callback
      action.callback = action.callbacks;
      // Delete `callbacks` now that is is part of `callback`
      delete action.callbacks;
    }
  }

  // Set callback if not set
  if(!("callback" in action)) action.callback = null;

  // Set updated items variable if not set
  if(props && (!("items_updated" in props) || !props.items_updated)) {
    props.items_updated = ("items" in props && props.items)
      ? props.items : [];
  }

  // Track item changes
  if("item" in props && !("item_updated" in props)) {
    props.item_updated = props.item;
  }

  // Check `if` statement, handle if false
  if(!Util.evalIf(action.if, props)) {
    // Check for `else`
    if(action.else) doAction(action.else, props);
    // Handle fail if no `else`, invoke callback by default
    else handleFail(action, props, true);
    // Stop action
    return;
  }

  // Check if action is a function
  if(typeof action === "function") return action(props);

  // Check for function in action
  if(!("function" in action)) return console.log("Missing function in action");

  // Create new object for holding processed data
  let data = ("data" in action) ? cloneDeep(action.data) : {};
  // Add notification data to `data`
  if("local" in action) data.local = action.local;
  if("user_id" in action) data.user_id = action.user_id;
  if("group_id" in action) data.group_id = action.group_id;
  if("message" in action) data.message = action.message;
  // Add link data to `data`
  if("link" in action) data.link = action.link;
  if("external" in action) data.external = action.external;
  // Insert variables into function's data
  data = processActionData(data, props);
  // Insert variables into event tags in function's data
  if("tags" in data) {
    for(let i=0; i<data.tags.length; i++) {
      data.tags[i] = processActionData(data.tags[i], props);
    }
  }

  // Check if showing items or item children
  props.show_children = ("page" in props
    && typeof props.page !== "undefined"
    && "config" in props.page
    && "items" in props.page.config
    && props.page.config.items.children);
  // Define additional query params if showing children
  const childrenQuery = (!props.show_children) ? "" :
    `children {
      id
      list_id
      created_at
      updated_at
      archived_at
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
      }
      parent_id
      parent_list_id
      parent_page_id
    }`;

  // Determine function
  let params = {};
  switch(action.function) {
    // Archive item
    case "archive":
      // Fail action if item does not exist
      if(!props.item && !data.item_id) return handleFail(action, props);
      // Parameters
      params = {
        token: props.token,
        page_id: ("page_id" in data) ? data.page_id : props.page.id,
        list_id: ("list_id" in data) ? data.list_id :
          (props.item) ? props.item.list_id : props.page.list_ids[0],
        parent_list_id: ("parent_list_id" in data) ? data.parent_list_id :
          (props.item) ? props.item.parent_list_id : null,
        id: ("item_id" in data) ? data.item_id : props.item.id,
        data: JSON.stringify(data)
      };
      // Handle item params in data
      if("page_id" in data) delete data.page_id;
      if("list_id" in data) delete data.list_id;
      if("parent_list_id" in data) delete data.parent_list_id;
      if("item_id" in data) delete data.item_id;
      if("cue" in data) delete data.cue;
      params.data = JSON.stringify(data);
      // Submit mutation
      API.graphql(graphqlOperation(`mutation
        ArchiveItem(
          $token: String!
          $page_id: ID
          $list_id: ID!
          $parent_list_id: ID
          $id: ID!
          $data: String
        ) {
          archiveItem(
            token: $token
            page_id: $page_id
            list_id: $list_id
            parent_list_id: $parent_list_id
            id: $id
            data: $data
          ) {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
            ${childrenQuery}
          }
        }`, params))
        // Success
        .then((res) => {
          // Track item changes if in this page
          if(params.page_id === props.page.id) {
            trackItemChanges(res.data.archiveItem, action.callback, props);
          } else doAction(action.callback, props);
        })
        // Error
        .catch((err) => {
          // Remove item if invalid
          if(err.errors[0].message === "Invalid item_id") {
            // Submit item ID with a fake item to remove from page
            trackItemChanges({
              id: params.id,  // Need this to remove item
              list_id: params.list_id,  // Need this to remove item
              archived_at: 1,  // If `archived_at` is in item, it will be removed
            }, false, props);
          }
          // Handle failure
          handleFail(action, props);
        });
      break;

    // Create item
    case "create":
      // Parameters
      params = {
        token: props.token,
        page_id: ("page_id" in data) ? data.page_id : props.page.id,
        list_id: ("list_id" in data) ? data.list_id : props.page.list_ids[0],
        cue: (Util.isNumeric(data.cue)) ? Number(data.cue) : null,
        data: JSON.stringify(data)
      };
      // Additional data
      if("parent_id" in data
      && "parent_list_id" in data
      && "parent_page_id" in data) {
        params.parent_id = data.parent_id;
        params.parent_list_id = data.parent_list_id;
        params.parent_page_id = data.parent_page_id;
        delete data.parent_id;
        delete data.parent_list_id;
        delete data.parent_page_id;
      }
      if("zones" in data) {
        // Validate zones
        if(Array.isArray(data.zones)) {
          params.zones = [];
          for(let i=0; i<data.zones.length; i++) {
            if(!("list_id" in data.zones[i])) continue;
            // Add zone's list_id
            let z = { list_id: data.zones[i].list_id };
            // Add `required` to zone
            if("required" in data.zones[i]
            && typeof data.zones[i] === "boolean") {
              z.required = data.zones[i].required;
            }
            // Add zone to array of zones
            params.zones.push(z);
          }
        }
        delete data.zones;
      }
      // Handle item params in data
      if("page_id" in data) delete data.page_id;
      if("list_id" in data) delete data.list_id;
      if("cue" in data) delete data.cue;
      params.data = JSON.stringify(data);
      // Submit mutation
      API.graphql(graphqlOperation(`mutation
        CreateItem(
          $token: String!
          $page_id: ID
          $list_id: ID!
          $parent_id: ID
          $parent_list_id: ID
          $parent_page_id: ID
          $data: String
          $cue: Int
          $zones: [ ZoneInput ]
        ) {
          createItem(
            token: $token
            page_id: $page_id
            list_id: $list_id
            parent_id: $parent_id
            parent_list_id: $parent_list_id
            parent_page_id: $parent_page_id
            data: $data
            cue: $cue
            zones: $zones
          ) {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent {
              id
              list_id
              created_at
              updated_at
              archived_at
              bookmark
              cue
              data
              zones {
                list_id
                required
                added_at
              }
            }
            parent_id
            parent_list_id
            parent_page_id
            ${childrenQuery}
          }
        }`, params))
        // Success
        .then((res) => {
          // Track item changes if in this page
          if(params.page_id === props.page.id) {
            trackItemChanges(res.data.createItem, action.callback, props);
          } else doAction(action.callback, props);
        })
        // Error
        .catch(err => handleFail(action, props));
      break;

    // Record event
    case "event":
      // Parameters
      params = {
        token: props.token,
        page_id: ("page_id" in data)
          ? data.page_id
          : props.page.id,
        list_id: ("list_id" in data)
          ? data.list_id
          : props.page.list_ids[0],
        parent_list_id: ("parent_list_id" in data) ? data.parent_list_id :
          (props.item) ? props.item.parent_list_id : null,
        metric_id: data.metric_id,
        tags: ("tags" in data) ? data.tags : [],
        value: ("value" in data && data.value !== null
        && !isNaN(Number(data.value)))
          ? Number(data.value)
          : 1
      };
      // Add tag_id and tag_value to tags (shorthand)
      if("tag_id" in data && "tag_value" in data) {
        params.tags.push({
          id: data.tag_id,
          value: data.tag_value
        });
      }
      // If invalid metric_id, go to callback
      if(!params.metric_id || params.metric_id === "undefined") {
        console.error("Missing metric ID");
        return doAction(action.callback, props);
      }
      // Submit mutation
      API.graphql(graphqlOperation(mutations.recordEvent, params))
        // Success
        .then((res) => doAction(action.callback, props))
        // Error
        .catch((err) => {
          handleFail(action, props, true);
        });
      break;

    // Open form
    case "form":
      // Form parameters
      action.inputs = ("inputs" in action) ? action.inputs : {};
      params = {
        response: ("response" in props) ? props.response : null,
        token: props.token,
        item: props.item,
        items: ("items" in props) ? props.items : null,
        zone: ("zone" in props) ? props.zone : null,
        page: props.page,
        form: props.form,
        config: action
      };
      params.openForm = (params) => props.openForm(params);
      params.redirect = (params) => props.redirect(params);
      // Open form
      props.openForm(params);
      break;

    // Update form
    case "form_update":
      // Update map
      if("map" in action) {
        Object.keys(action.map).forEach(function(param) {
          // Update map config
          props.updateMapConfig(param, action.map[param]);
        });
      }
      // Update inputs
      if("inputs" in action) {
        Object.keys(action.inputs).forEach(function(name) {
          let input = action.inputs[name];
          // Get new input value (shorthand: `color: "red"`)
          let value = input, text, meta;
          // Get new input value (longhand: `color: { value: "red" }`)
          if(typeof input !== "string") {
            value = input.value;
            // Get `text` if defined
            if("text" in input) text = input.text;
            // Get `meta` if defined
            if("meta" in input) meta = input.meta;
          }
          // Insert variables
          value = Util.insertVariables(value, props, false, false);
          text = (typeof text !== "undefined")
            ? value : Util.insertVariables(text, props, false, false);
          if(typeof text !== "undefined") Util.insertVariables(meta, props, false, false);
          // Update input value in form
          props.updateInputValue(name, value, text, meta);
        });
      }
      // Invoke callback
      doAction(action.callback, props);
      break;

    // Integration
    case "integration":
      // Parameters
      params = {
        token: props.token,
        id: ("integration_id" in data) ? data.integration_id : null,
        data: JSON.stringify(data)
      };
      // If invalid integration_id, go to callback
      if(!params.id || params.id === "undefined") {
        console.error("Missing integration ID");
        return doAction(action.callback, props);
      }
      // Submit mutation
      API.graphql(graphqlOperation(mutations.invokeIntegration, params))
        // Success
        .then((res) => {
          // Add response to props
          props.response = res.data.invokeIntegration.data;
          // Invoke callback
          doAction(action.callback, props)
        })
        // Error
        .catch((err) => {
          handleFail(action, props, true);
        });
      break;

    // Enable Kiosk Mode
    case "kiosk":
      // Create mutation
      let mutation = `mutation
        UpdateToken($token: String!, $kiosk_name: String) {
          updateToken(token: $token, kiosk_name: $kiosk_name) {
            token
            org_id
            kiosk_id
          }
        }`;
      // Parameters
      params = {
        token: props.token,
        kiosk_name: data.kiosk_name
      };
      // Submit mutation
      API.graphql(graphqlOperation(mutation, params))
        // Success
        .then(async (res) => {
          const data = res.data.updateToken;
          // Analytics
          Analytics.event("kiosk_enabled", { event_category: "session" });
          Analytics.properties({
            uid: data.kiosk_id,
            user_id: null,
            kiosk_id: data.kiosk_id
          });

          // Remove device from notifications for this user
          await Notifications.RemoveDevice();

          // Update token
          props.updateToken(data.token);
        })
        // Error
        .catch((err) => console.log(err));
      break;

    // Follow a link
    case "link":
      handleRedirect(props.redirect, data.link, data.external);
      break;

    // Log out
    case "logout":
      // Analytics
      Analytics.event("logged_out", { event_category: "session" });
      Analytics.properties({
        uid: null,
        org_id: null,
        kiosk_id: null,
        user_id: null
      });

      // Turn off kiosk mode in native app
      window.postWebappData({ keepAwake: false });

      // Remove device from notifications for this user
      await Notifications.RemoveDevice();

      // Remove token from local storage
      localStorage.removeItem('token');

      // Invoke callback
      doAction(action.callback, props);
      break;

    // Send notification
    case "notification":
      // Parameters
      params = {
        token: props.token,
        local: (data.local === true) ? true : false,
        user_id: ("user_id" in data) ? data.user_id : null,
        user_ids: ("user_ids" in data && typeof data.user_ids === "string")
          ? JSON.parse(data.user_ids) : null,
        group_id: ("group_id" in data) ? data.group_id : null,
        message: data.message,
        page_id: props.page.id,
        page_name: props.page.name
      };
      // Local notification
      if(params.local) Notifications.ShowNotification({
        title: params.page_name,
        body: params.message
      });
      // If user_id, user_ids, and group_id are invalid, go to callback
      if((!params.user_id || params.user_id === "undefined")
      && (!params.user_ids || params.user_ids === "undefined")
      && (!params.group_id || params.group_id === "undefined")) {
        return doAction(action.callback, props);
      }
      // Submit mutation
      API.graphql(graphqlOperation(mutations.sendNotification, params))
        // Success
        .then((res) => doAction(action.callback, props))
        // Error
        .catch((err) => {
          handleFail(action, props, true);
        });
      break;

    // Update item
    case "update":
      // Fail action if item does not exist
      if(!props.item && !data.item_id) return handleFail(action, props);
      // Parameters
      params = {
        token: props.token,
        page_id: ("page_id" in data) ? data.page_id : props.page.id,
        list_id: ("list_id" in data) ? data.list_id :
          (props.item) ? props.item.list_id : props.page.list_ids[0],
        parent_list_id: ("parent_list_id" in data) ? data.parent_list_id :
          (props.item) ? props.item.parent_list_id : null,
        id: ("item_id" in data) ? data.item_id : props.item.id,
        cue: (Util.isNumeric(data.cue)) ? Number(data.cue) : null,
        data: JSON.stringify(data)
      };
      // Handle item params in data
      if("page_id" in data) delete data.page_id;
      if("list_id" in data) delete data.list_id;
      if("parent_list_id" in data) delete data.parent_list_id;
      if("item_id" in data) delete data.item_id;
      if("cue" in data) delete data.cue;
      params.data = JSON.stringify(data);
      // Submit mutation
      API.graphql(graphqlOperation(`mutation
        UpdateItem(
          $token: String!
          $page_id: ID!
          $list_id: ID!
          $parent_list_id: ID
          $id: ID!
          $data: String
          $cue: Int
        ) {
          updateItem(
            token: $token
            page_id: $page_id
            list_id: $list_id
            parent_list_id: $parent_list_id
            id: $id
            data: $data
            cue: $cue
          ) {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
            ${childrenQuery}
          }
        }`, params))
        // Success
        .then((res) => {
          // Track item changes if in this page
          if(params.page_id === props.page.id) {
            trackItemChanges(res.data.updateItem, action.callback, props);
          } else doAction(action.callback, props);
        })
        // Error
        .catch(err => handleFail(action, props));
      break;

    // Add zone to item
    case "zone_add":
      // Fail action if item does not exist
      if(!props.item && !data.item_id) return handleFail(action, props);
      // Parameters
      params = {
        token: props.token,
        page_id: ("page_id" in data) ? data.page_id : props.page.id,
        list_id: ("list_id" in data) ? data.list_id :
          (props.item) ? props.item.list_id : props.page.list_ids[0],
        parent_list_id: ("parent_list_id" in data) ? data.parent_list_id :
          (props.item) ? props.item.parent_list_id : null,
        id: ("item_id" in data) ? data.item_id : props.item.id,
        zones: [
          {
            list_id: ("zone_list_id" in data) ? data.zone_list_id : null,
            required: ("zone_required" in data && (data.zone_required)) ? true : false
          }
        ]
      };
      // Submit mutation
      API.graphql(graphqlOperation(`mutation
        AddItemZones(
          $id: ID!
          $list_id: ID!
          $parent_list_id: ID
          $page_id: ID
          $token: String!
          $zones: [ZoneInput]
        ) {
          addItemZones(
            id: $id
            list_id: $list_id
            parent_list_id: $parent_list_id
            page_id: $page_id
            token: $token
            zones: $zones
          ) {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
          }
        }`, params))
        // Success
        .then((res) => doAction(action.callback, props))
        // Error
        .catch(err => handleFail(action, props));
      break;

    // Fallback
    default:
      console.log("Invalid function");
  }
}

function handleFail(action, props, defaultIsCallback = false) {
  // Invoke default behavior
  const doDefault = function() {
    // Invoke callback if that is the default behavior
    if(defaultIsCallback === true) doAction(action.callback, props);
  }

  // Check for `on_fail`
  if("on_fail" in action) {
    // Force callback to be invoked
    if(action.on_fail === true) return doAction(action.callback, props);

    // Invoke default action
    if(action.on_fail === null) return doDefault();

    // Skip callback
    if(action.on_fail === false) return;

    // Skip callback & invoke alternate action
    if(isObject(action.on_fail)) doAction(action.on_fail, props);
  }

  // Invoke default action
  doDefault();
}

function handleRedirect(redirect, path, external) {
  // Handle path
  if(!path) path = "";

  // Track redirect success
  let redirected = false;

  // Check if `redirect` is a function and path is local (starts with `/`)
  if(typeof redirect === "function"
  && /^\//.test(path)
  && path !== "/login") {
    redirected = redirect(path);
  } else redirected = false;

  // Hard redirect if handled redirect fails
  if(redirected === false) {
    if(external) window.open(path, '_blank');
    else window.location.href = path;
  }
}

function trackItemChanges(item_updated, callback, props) {
  // Update items in page (instead of waiting for subscription to update)
  if("updateItem" in props) {
    // Update item in page
    if(!props.show_children  // Page is not showing children
    || ("parent_id" in item_updated && item_updated.parent_id !== null)) {  // Item is not a child
      // Update item in page if: page is not showing children, or item is not a child
      props.updateItem(item_updated);
    }

    // If showing item's children, update children in page instead of item
    else if("children" in item_updated) {
      // Loop through children
      for(let i=0; i<item_updated.children.length; i++) {
        // Update child item in page
        props.updateItem(item_updated.children[i]);
      }
    }
  }

  // Update `item` in `items_updated` to track changes after each action
  props.items_updated = mutateItem(props.items_updated, item_updated)[0];
  // Sort updated items
  props.items_updated = sortItems(props.items_updated, props.page.config);
  // Update item's `_position`
  if(!("archived_at" in item_updated)
  || item_updated.archived_at === null) {
    props.items_updated.forEach(item => {
      // Find updated item in updated array of items
      if(item.id === item_updated.id) {
        // Update item's position
        item_updated._position = item._position;
        item_updated._positionFaux = item._positionFaux;
      }
    })
  } else {
    // Reset archived item's `_position`
    item_updated._position = null;
    item_updated._positionFaux = null;
  }

  // Store updated item in props
  props.item_updated = item_updated;

  // Next, invoke callback
  doAction(callback, props);
}
