import React, { Component } from 'react';
import Icon from "../Icon/Icon";
import * as Util from "../Utilities/Utilities";
import "./Header.css";

export class Spacer extends Component {
  constructor(props) {
    super(props);
    this.updateInset = this.updateInset.bind(this);
    this.state = {
      top_inset: (window.injectedData.safe_area_insets)
        ? window.injectedData.safe_area_insets.top : 0,
      bottom_inset: (window.injectedData.safe_area_insets)
        ? window.injectedData.safe_area_insets.bottom : 0
    };
  }

  componentDidMount() {
    // Update inset on native injection
    document.addEventListener("onDataInjection", this.updateInset);
  }

  componentWillUnmount() {
    // Remove event lsiteners before unmounting component
    document.removeEventListener("onDataInjection", this.updateInset);
  }

  updateInset() {
    if(!("safe_area_insets" in window.injectedData)) return;
    this.setState({
      top_inset: window.injectedData.safe_area_insets.top,
      bottom_inset: window.injectedData.safe_area_insets.bottom
    });
  }

  render() {
    // Get spacer position
    const position = ("position" in this.props)
      ? this.props.position
      : "";

    // Add header height if position is at top of page
    const inset = (position === "top")
      ? 44 + this.state.top_inset
      : (position === "bottom")
        ? this.state.bottom_inset
        : 0;

    // Set height
    const height = ("height" in this.props)
      ? Number(this.props.height) + inset
      : inset;

    // Set a minimum height
    const min = ("min" in this.props) ? this.props.min : 0;
    const minHeight = Math.max(height, min);

    // Classes
    const classes = "spacer "+
      (("className" in this.props) ? this.props.className : "");

    return (
      <p
        className={classes}
        style={{ height: minHeight+"px" }}/>
    );
  }
}

export class Header extends Component {
  constructor(props) {
    super(props);
    this.updateInset = this.updateInset.bind(this);
    this.state = {
      top_inset: (window.injectedData.safe_area_insets)
        ? window.injectedData.safe_area_insets.top : false
    };
  }

  componentDidMount() {
    // Update inset on native injection
    document.addEventListener("onDataInjection", this.updateInset);
  }

  componentWillUnmount() {
    // Remove event lsiteners before unmounting component
    document.removeEventListener("onDataInjection", this.updateInset);
  }

  updateInset() {
    if(!("safe_area_insets" in window.injectedData)) return;
    this.setState({ top_inset: window.injectedData.safe_area_insets.top });
  }

  render() {
    let style = {};

    // Header height adjustment for native app status bar
    style.paddingTop = (this.state.top_inset)
      ? this.state.top_inset+"px"
      : "9px";

    // Background color
    style.background = ("color" in this.props && this.props.color)
      ? this.props.color : "#3F9AF7";

    // Browser theme color
    document.querySelector('meta[name="theme-color"]')
      .setAttribute("content", style.background);

    // Title
    const title = ("title" in this.props && this.props.title)
      ? this.props.title
      : "LotSuite";

    // Icon
    const icon = ("icon" in this.props && this.props.icon)
      ? this.props.icon
      : "lotsuite";

    // Return header
    return (
      <header
        style={style}
        className="header">
        <div className="title">
          <Icon name={icon}/>
          <span>{title}</span>
        </div>
      </header>
    );
  }
}

export class Footer extends Component {
  render() {
    // Create spacer to accomodate footer
    const footerSpacer = (
      <Spacer
        key="footerSpacer"
        height="68"/>  // Footer height
    );

    // Create spacer to accomodate bottom padding and inset
    const paddingSpacer = (
      <Spacer
        key="paddingSpacer"
        position="bottom"
        min="30"/>
    );

    const is_kiosk = (Util.evalIf({ kiosk: true },
      { token: this.props.token })) ? true : false;

    // Define footer
    const footer = (
      /* eslint-disable jsx-a11y/anchor-is-valid */
      <footer
        key="footer"
        className={"footer" + ((is_kiosk) ? " disabled" : "")}>
        <span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={(is_kiosk) ? undefined : "https://www.makinauto.com"}>
            &copy; Makin Automotive Software
          </a>
        </span>
        <span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={(is_kiosk) ? undefined : "mailto:support@lotsuite.com"}>
            support@lotsuite.com
          </a>
        </span>
        <Spacer  // Bottom padding and inset
          position="bottom"
          min="30"/>
      </footer>
      /* eslint-enable jsx-a11y/anchor-is-valid */
    );

    return [ footerSpacer, paddingSpacer, footer ];
  }
}

export default Header;
