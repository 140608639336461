import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

export function itemData(item, key, in_data) {
  // Parse key if in parent
  if(/^parent\./.test(key)
  && "parent" in item
  && item.parent !== null) {
    key = key.replace(/^parent\./, '');
    item = item.parent;
  }

  // Parse key if `data.field` instead of `field`
  if(/^data\./.test(key)) {
    key = key.replace(/^data\./, '');
    in_data = true;
  }

  // Get value from item root, unless `in_data` is true
  // If `in_data` is true, must get value from `item.data[key]`
  return (!in_data && key in item)
    // Get value from item
    ? item[key]
    // Get value from item's data object
    : (key in item.data) ? item.data[key] : null;
}

export function parseItem(item) {
  // Parse item data from JSON
  item.data = (!("data" in item) || item.data === null) ? {}
    : (typeof item.data === "object") ? item.data
      : JSON.parse(item.data);
  // Remove type info
  delete item.__typename;
  // Parse parent
  if("parent" in item && item.parent !== null) {
    item.parent = parseItem(item.parent);
  }
  // Return parsed item
  return item;
}

export function mutateItem(items, item) {
  // Prevent array from referencing another array
  items = JSON.parse(JSON.stringify(items));

  // Parse item data
  item = parseItem(item);

  // Return: `items` (array), `mutated` (boolean), `type` (string)
  let mutated = false;

  // Archived item - if item contains archive timestamp
  if("archived_at" in item && item.archived_at !== null) {
    for(let i=0; i<items.length; i++) {
      if(items[i].id === item.id) {
        // Item has been mutated
        mutated = true;
        // Remove item
        items.splice(i, 1);
        // Stop looking
        break;
      }
    }
    // Return items (must be after loop in case already archived)
    return [items, mutated, "archive"];
  }

  // Update item - if item is in items array
  for(let i=0; i<items.length; i++) {
    if(items[i].id === item.id) {
      // New item must be newer (prevent asynchronous update issues)
      if(item.updated_at >= items[i].updated_at) {
        // New item must be different
        if(items[i].updated_at !== item.updated_at
        || items[i].cue !== item.cue
        || !isEqual(items[i].data, item.data)) {
          // Item has been mutated
          mutated = true;
          // Update item
          items[i].updated_at = item.updated_at;
          items[i].cue = item.cue;
          items[i].data = item.data;
        }
      }
      // Return items
      return [items, mutated, "update"];
    }
  }

  // Create item - if item was not found in items array
  items.push(item);
  return [items, true, "create"];
}

export function sortItems(items, config) {
  // Copy items to be sorted
  let sortedItems = [];
  for(let i=0; i<items.length; i++) {
    // Prevent item overwriting
    let item = cloneDeep(items[i]);
    // Add item to array of items to be sorted
    sortedItems.push(item);
  }

  // Sort items (without changing original array of items)
  sortedItems.sort((a, b) => { return itemSortFunction(a, b, config) });

  // Add page position to each item
  let nonFauxItemsCount = 0;
  for(let i=0; i<sortedItems.length; i++) {
    // Position excluding faux items
    sortedItems[i]._position = (!sortedItems[i].faux)
      ? nonFauxItemsCount++  // Count non-faux items
      : null;  // No position for faux items

    // Position including faux items
    sortedItems[i]._positionFaux = i;
  }

  return sortedItems;
}

function itemSortFunction(itemA, itemB, config) {
  // Get sort from config
  let sorts = ("items" in config
  && "sort" in config.items
  && "fields" in config.items.sort) ? config.items.sort.fields : {};

  // Loop through sort settings in config and sort by `order`
  let sortFields = Object.keys(sorts).sort(function(a, b) {
    if("order" in sorts[a]) {
      if("order" in sorts[b]) {
        return sorts[a].order - sorts[b].order
      } else return -1;
    } else if("order" in sorts[b]) return 1;
    else return a.localeCompare(b);  // Sort by field_id if no "order"
  });

  // Loop though sort fields in order
  for(let i=0; i<sortFields.length; i++) {
    let key = sortFields[i];
    let valueA = itemData(itemA, key);
    let valueB = itemData(itemB, key);
    let comp = 0;
    let ascending = ("ascending" in sorts[key])
      ? sorts[key].ascending : true;  // Default to `true`
    let null_after = ("null_after" in sorts[key])
      ? sorts[key].null_after : true;  // Default to `true`

    // Compare `a` to `b`
    if(valueA !== null) {
      if(valueB !== null) {  // `a` and `b` have values

        // Sort as numbers if both are numbers
        if(typeof valueA === "number" && typeof valueB === "number") {
          comp = valueA - valueB;
        }
        // Sort both as strings
        else comp = valueA.toString().localeCompare(valueB.toString());

      } else comp = (null_after) ? -1 : 1;  // Sort `a` value before `b` null
    } else if(valueB !== null) {
      comp = (null_after) ? 1 : -1;  // Sort 'b' value before `b` null
    } else comp = 0;  // Both are null, don't sort

    // Return if comp is not zero
    if(comp !== 0) return (ascending) ? comp : (comp * -1);
  }

  // Sort by created_at time
  return itemA.created_at - itemB.created_at;
}
