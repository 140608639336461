import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Agreements from '../Agreements/Agreements';
import "./LoginPage.css";
import Analytics from '../analytics';

import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsmobile from '../amplify-config';
Amplify.configure(awsmobile);

class LoginPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      path: this.props.path,
      prt: this.getUrlParameter('prt'),
      email: "",
      name: "",
      phone: "",
      password: "",
      password_confirm: "",
      error: null,
      sent: false,
      message: null,
      token: null,
      orgs: [],
      showAgreements: false,
      loading: false,
      rerender: 0
    };
    this.getUrlParameter = this.getUrlParameter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.jwtDecode = this.jwtDecode.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.sendPassword = this.sendPassword.bind(this);
    this.setPassword = this.setPassword.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.path !== this.props.path) {
      this.setState({
        path: this.props.path,
        prt: this.getUrlParameter('prt')
      });
    }
  }

  getUrlParameter(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return (results === null) ? '' :
      decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  handleChange(event){
    // Clear errors
    let state = { error: null };
    // Update input value in state
    state[event.target.name] = event.target.value;
    this.setState(state);
  }

  jwtDecode(token) {
    // Check token's format
    if(!(/^[\w-_]+\.[\w-_]+\.[\w-_]+$/.test(token))) return false;
    // Get payload from token
    let payload = token.split('.')[1]
      .replace(/-/g, '+').replace(/_/g, '/');
    // Parse JSON
    try {
      return JSON.parse(window.atob(payload));
    }
    catch(e) {
      return false;
    }
  }

  submitForm(event) {
    // Prevent native form submit
    event.preventDefault();

    // Go back to login if password was sent
    if(this.state.sent) {
      this.webappRedirect("/login");
      return;
    }

    // Indicate loading
    this.setState({ loading: true });

    // Determine function to call
    switch(this.state.path) {
      case "/signup":
        this.sendPassword(event, true);
        break;

      case "/password":
        if(this.state.prt) {
          let checkPrt = this.checkPrt();
          // Set password
          if(checkPrt.status === "valid") this.setPassword(event);
          // Return to login if password reset token is invalid or expired
          else {
            // Redirect to reset password form
            this.setState({
              prt: null,
              email: checkPrt.email,
              loading: false
            });
          }
        }
        // Send password
        else this.sendPassword(event);
        break;

      default:
        this.authenticate(event);
    }
  }

  authenticate(event) {
    // Submit to API
    const submit = async () => {
      // Create mutation
      let mutation = `mutation
        CreateToken($email: String!, $password: String!) {
          createToken(email: $email, password: $password) {
            token
            user {
              id
              name
              email
              phone
              orgs {
                name
                id
              }
            }
          }
        }`;
      // Create payload
      let payload = {
        email: this.state.email.toLowerCase().trim(),
        password: this.state.password
      };
      // Submit to API
      return await API.graphql(graphqlOperation(mutation, payload));
    }
    submit()
      .then((res)=>{
        // Stop loading
        this.setState({ loading: false });
        // Analytics
        Analytics.properties({
          uid: res.data.createToken.user.id,
          user_id: res.data.createToken.user.id
        });
        Analytics.event("logged_in", {
          event_category: "session",
          name: (res.data.createToken.user.name)
            ? res.data.createToken.user.name : null,
          email: (res.data.createToken.user.email)
            ? res.data.createToken.user.email : null,
          phone: (res.data.createToken.user.phone)
            ? res.data.createToken.user.phone : null
        });
        // Show terms and log in
        let token = res.data.createToken.token;
        let orgs = res.data.createToken.user.orgs;
        this.setState({
          token: token,
          orgs: orgs,
          showAgreements: true
        });
      },
      (err)=>{
        // Stop loading
        this.setState({ loading: false });
        // Analytics
        Analytics.event("invalid_login", { event_category: "session" });
        // Show error
        this.setState({ error: err.errors[0].message });
      });
  }

  sendPassword(event, autoAdd) {
    // Create payload
    let payload = {
      email: this.state.email.toLowerCase().trim(),
      name: this.state.name.trim(),
      phone: this.state.phone.replace(/\D/g, ''),
      create: (autoAdd) ? true : false
    };
    // Submit to API
    const submit = async () => {
      // Create mutation
      let mutation = `mutation
        SendPassword($email: String!, $name: String, $phone: String, $create: Boolean) {
          sendPassword(email: $email, name: $name, phone: $phone, create: $create)
        }`;
      // Submit to API
      return await API.graphql(graphqlOperation(mutation, payload));
    }
    submit()
      .then((res)=>{
        // Stop loading
        this.setState({ loading: false });
        // Analytics
        Analytics.event("password_sent", {
          event_category: "password_reset",
          event_label: (autoAdd) ? "signup" : "reset"
        });
        // Indicate password has been sent
        this.setState({ sent: true });
        // Show message
        let msg = "A link to set your new password has been sent to your email";
        this.setState({ message: msg });
      },
      (err)=>{
        // Stop loading
        this.setState({ loading: false });
        // Analytics
        Analytics.error("password_not_sent", {
          event_category: "password_reset",
          event_label: (autoAdd) ? "signup" : "reset"
        });
        // Show error
        this.setState({ error: err.errors[0].message });
      });
  }

  setPassword(event, autoAdd) {
    // Verify passwords match
    if(this.state.password === "" ||
    (this.state.password !== this.state.password_confirm)) {
      // Stop loading
      this.setState({ loading: false });
      // Show error
      let msg = (this.state.password === "")
        ? "Invalid Password"
        : "Passwords Do Not Match";
      this.setState({ error: msg });
      // Stop function
      return;
    }
    // Submit to API
    const submit = async () => {
      // Create mutation
      let mutation = `mutation
        SetPassword($prt: String!, $password: String!) {
          setPassword(prt: $prt, password: $password) {
            token
            user {
              name
              email
              phone
              orgs {
                name
                id
              }
            }
          }
        }`;
      // Create payload
      let payload = {
        prt: this.state.prt,
        password: this.state.password
      };
      // Submit to API
      return await API.graphql(graphqlOperation(mutation, payload));
    }
    submit()
      .then((res)=>{
        // Stop loading
        this.setState({ loading: false });
        // Analytics
        let user = res.data.setPassword.user;
        Analytics.event("password_changed", {
          event_category: "password_reset",
          name: (user.name) ? user.name : null,
          email: (user.email) ? user.email : null,
          phone: (user.phone) ? user.phone : null
        });
        // Show terms and log in
        let token = res.data.setPassword.token;
        let orgs = res.data.setPassword.user.orgs;
        this.setState({
          token: token,
          orgs: orgs,
          showAgreements: true
        });
      },
      (err)=>{
        // Stop loading
        this.setState({ loading: false });
        // Analytics
        Analytics.error("password_not_changed", {
          event_category: "password_reset"
        });
        // Show error
        this.setState({ error: err.errors[0].message });
      });
  }

  webappRedirect(path) {
    this.resetForm();
    this.redirect = true;
    this.redirectPath = path;
    this.setState({ rerender: this.state.rerender + 1 });
  }

  resetForm() {
    this.setState({
      error: null,
      loading: false,
      message: null,
      password: "",
      sent: false
    });
  }

  checkPrt() {
    // Decode password reset token
    const decoded = this.jwtDecode(this.state.prt);

    // Validate password reset token
    if(!decoded) return {
      status: "invalid",
      email: null
    }

    // Get email
    let email = ("email" in decoded) ? decoded.email : null;

    // Check if token is over 24 hours old
    if(("iat" in decoded) && decoded.iat < ((new Date() / 1000) - 60*60*24)) return {
      status: "expired",
      email: email
    }

    // If not invalid and not expired, return valid
    return {
      status: "valid",
      email: email
    }
  }

  render() {
    // Redirect
    if(this.redirect === true) {
      this.redirect = false;
      return <Redirect to={this.redirectPath}/>;
    }

    // Agreements
    if(this.state.showAgreements) return (
      <Agreements
        orgs={this.state.orgs}
        login={this.props.login.bind(this)}
        token={this.state.token}/>
    );

    // Constants
    const path = this.state.path;
    const prt = this.state.prt;
    // Components
    let title;
    let toggle;
    let email;
    let token_email;
    let name;
    let phone;
    let password;
    let password_confirm;
    let message = this.state.message;
    let loginText;
    let forgotPassword;
    let toLogin;
    let toSignup;
    let supportEmail;

    // Customize each screen
    switch(path) {
      // Log in
      case "/login":
        title = "LotSuite Login";
        toggle = true;
        email = true;
        password = true;
        forgotPassword = true;
        break;

      // Send password reset and set password
      case "/password":
        // Set password
        if(prt) {
          title = "Set New Password"
          email = true;
          password = true;
          password_confirm = true;
          loginText = "Submit";
          toLogin = true;
          toSignup = false;

          // Validate password reset token
          let checkPrt = this.checkPrt();
          // Check if token is invalid
          if(checkPrt.status === "invalid") {
            message = "Looks like this link is invalid, please check the link or try again";
            email = false;
            password = false;
            password_confirm = false;
            loginText = "Next";
          }
          else {
            // Check if token is expired
            if(checkPrt.status === "expired") {
              message = "Looks like this link has expired, please check the link or try again";
              email = false;
              password = false;
              password_confirm = false;
              loginText = "Next";
            }
            else token_email = checkPrt.email;
          }
        }

        // Send password reset
        if(!prt) {
          title = "Reset Password"
          email = true;
          loginText = "Next";
          toLogin = true;
          toSignup = true;

          // After link has been sent
          if(this.state.sent) {
            email = false;
            loginText = "Done";
            toLogin = false;
            toSignup = false;
          }
        }
        break;

      // Signup
      case "/signup":
        title = "Welcome to LotSuite";
        toggle = true;
        email = true;
        name = true;
        phone = false;  // Disabled (use app push notifications instead)
        toLogin = true;
        loginText = "Next";

        // Signup error message
        if(!message && this.state.error) {
          message = "Please make sure your email is correct or ask your "+
            "administrator to add you to LotSuite";
        }

        // After link has been sent
        if(this.state.sent) {
          toggle = false;
          email = false;
          name = false;
          phone = false;
          toLogin = false;
          loginText = "Done";
        }
        break;

      // Fallback
      default:
    }

    // Components
    toggle = (!toggle) ? "" : (
      <div className="toggle">
        <Link
          to="/login"
          className={(path === "/login") ? "active" : ""}>
          Log In
        </Link>
        <Link
          onClick={() => this.resetForm()}
          to="/signup"
          className={(path === "/signup") ? "active" : ""}>
          Sign Up
        </Link>
      </div>
    );
    email = (!email) ? "" : (
      <label>
        Email
        <input name="email" type="email" required
          disabled={(token_email) ? true : false}
          autoComplete="email"
          placeholder="you@yourcompany.com"
          value={(token_email) ? token_email : this.state.email}
          onChange={this.handleChange}/>
      </label>
    );
    name = (!name) ? "" : (
      <label>
        Name
        <input name="name" required
          autoComplete="name"
          placeholder="First Last"
          onChange={this.handleChange}/>
      </label>
    );
    phone = (!phone) ? "" : (
      <label>
        Mobile Phone
        <input name="phone" type="tel"
          pattern="\(?[0-9]{3}\)?[- ]?[0-9]{3}[- ]?[0-9]{4}"
          placeholder="(123) 555-6789"
          value={this.state.phone} onChange={this.handleChange}/>
      </label>
    );
    password = (!password) ? "" : (
      <label>
        Password
        <input name="password" type="password" required
          autoComplete={(!password_confirm)
            ? "current-password" : "new-password"}
          placeholder="••••••••"
          value={this.state.password} onChange={this.handleChange}/>
      </label>
    );
    password_confirm = (!password_confirm) ? "" : (
      <label>
        Confirm Password
        <input name="password_confirm" type="password" required
          autoComplete="new-password"
          placeholder="••••••••"
          value={this.state.password_confirm} onChange={this.handleChange}/>
      </label>
    );
    message = (!message) ? "" : (
      <div className="message">
        {message}
      </div>
    );
    forgotPassword = (!forgotPassword) ? "" : (
      <Link
        onClick={() => this.resetForm()}
        to="/password">
        Forgot Password
      </Link>
    );
    toLogin = (!toLogin) ? "" : (
      <Link
        to="/login">
        Back to Login
      </Link>
    );
    toSignup = (!toSignup) ? "" : (
      <Link
        onClick={() => this.resetForm()}
        to="/signup">
        Create Account
      </Link>
    );
    supportEmail = (
      <a
        href="mailto:support@lotsuite.com">
        support@lotsuite.com
      </a>
    );

    // Button status indicator
    if(loginText === undefined) loginText = "Login";
    let loginClass = "";
    if(this.state.loading) {
      loginText = "Loading...";
      loginClass = "loading";
    }
    if(this.state.error) {
      loginText = this.state.error;
      loginClass = "error";
    }
    let button = (loginText === false) ? "" : (
      <button type="submit" className={loginClass}>
        {loginText}
      </button>
    );

    return (
      <div className="login">
        <form onSubmit={this.submitForm}>
          <h1>{title}</h1>
          {toggle}
          {name}
          {email}
          {phone}
          {password}
          {password_confirm}
          {message}
          {button}
          <div className="links">
            {forgotPassword}
            {toLogin}
            {toSignup}
            {supportEmail}
          </div>
        </form>
      </div>
    );
  }
}

export default LoginPage;
