/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_appsync_graphqlEndpoint": "https://vicsp2hjfbbl7dgo5cw7jl6lju.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-c7uucq5uxngctdldc6eyugd4py",
};

export default awsmobile;