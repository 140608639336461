import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Icon from "../Icon/Icon";
import * as Actions from "../Actions/Actions";
import Analytics from '../analytics';
import * as Util from "../Utilities/Utilities";
import "./Status.css";

class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  redirect(path) {
    this.setState({ redirect: path });
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    // Redirect
    if(this.state.redirect !== false) {
      return <Redirect to={this.state.redirect} push={true}/>;
    }

    let content = "",
      description = "",
      is_kiosk = false;
    const status = (!("status" in this.props)) ? null : this.props.status;
    const callback = ("callback" in this.props && this.props.callback)
      ? this.props.callback.bind(this)
      : this.refreshPage.bind(this);
    /* eslint-disable jsx-a11y/anchor-is-valid */
    switch(status) {
      case "disabled":
        // Variables
        description = (this.props.description) ? this.props.description
          : "Your organization's account has been disabled";
        is_kiosk = (Util.evalIf({ kiosk: true },
          { token: this.props.token })) ? true : false;
        // Message
        content = (
          <div>
            <h1>Account Disabled</h1>
            <h2>{description}</h2>
            <h2>
              Questions: <a
                target="_blank"
                rel="noopener noreferrer"
                href={(is_kiosk) ? undefined : "mailto:support@lotsuite.com"}>
                support@lotsuite.com
              </a>
            </h2>
            <h2
              onClick={() => Actions.doAction({
                function: "link",
                link: "/orgs"
              }, { redirect: this.redirect.bind(this) })}
              className="clickable">
              Go Back
            </h2>
          </div>
        );
        break;

      case "disconnected":
        // Check if an error occured
        if(this.props.error === true || this.props.error === undefined) {
          // Analytics
          Analytics.error("disconnected", {
            event_category: "network",
            non_interaction: true
          });
        }
        // Message
        content = (
          <div
            onClick={() => {
              Analytics.event("reconnect", { event_category: "network" });
              callback();
            }}>
            <h1>Disconnected from Server</h1>
            <h2 className="clickable">Click to Reconnect</h2>
          </div>
        );
        break;

      case "error":
        // Analytics
        Analytics.error("error", {
          event_category: "general",
          non_interaction: true
        });
        // Message
        content = (
          <div onClick={callback}>
            <h1>Something Went Wrong</h1>
            <h2 className="clickable">Click to Try Again</h2>
          </div>
        );
        break;

      case "link":
        // Link to a page
        Actions.doAction({
          function: "link",
          link: ("link" in this.props) ? this.props.link : "/"
        }, {
          redirect: this.redirect.bind(this)
        });
        // Show spinner to indicate loading
        content = <Icon name="spinner"/>;
        break;

      case "loading":
        content = <Icon name="spinner"/>;
        break;

      case "logout":
        // Log out and redirect to login page
        Actions.doAction({
          function: "logout",
          callback: {
            function: "link",
            link: "/login"
          }
        }, {
          redirect: this.redirect.bind(this)
        });
        // Show spinner to indicate loading
        content = <Icon name="spinner"/>;
        break;

      case "paused":
        // Variables
        description = (this.props.description) ? this.props.description
          : "Your organization's account has been paused";
        is_kiosk = (Util.evalIf({ kiosk: true },
          { token: this.props.token })) ? true : false;
        // Message
        content = (
          <div>
            <h1>Account Temporarily Disabled</h1>
            <h2>{description}</h2>
            <h2>
              Questions: <a
                target="_blank"
                rel="noopener noreferrer"
                href={(is_kiosk) ? undefined : "mailto:support@lotsuite.com"}>
                support@lotsuite.com
              </a>
            </h2>
            <h2
              onClick={() => Actions.doAction({
                function: "link",
                link: "/orgs"
              }, { redirect: this.redirect.bind(this) })}
              className="clickable">
              Go Back
            </h2>
          </div>
        );
        break;

      default:
        return "";
    }

    return (
      <div className="StatusComponent">
        <div className="content">
          {content}
        </div>
      </div>
    );
  }
}

export default Status;
