import React, { Component } from 'react';
import Analytics from '../analytics';
import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);
const legacyDownload = browser.satisfies({
  "internet explorer": "<=11",
  "edge": "12"
});

class DownloadArchive extends Component {
  ieFileDownload(csv, filename) {
    // Create blob from file
    let blob = new Blob([csv], {type: "text/csv"});
    // Download file
    navigator.msSaveBlob(blob, filename+".csv");
  }

  render() {
    // Hide if in native app
    if(window.isNative) return "";

    // Get archive config
    const archive_config = this.props.page.config.items_archived;

    // Check for "download" in config
    if(!("download" in archive_config)) return "";

    // File name
    const file_name = ("name" in archive_config.download)
      ? archive_config.download.name
      : this.props.page.name;

    // Prepare CSV
    let csv = [];
    const fields = archive_config.download.fields;

    // Headers row
    let headers = [];
    for(let i=0; i<fields.length; i++) {
      // Header is field ID
      if(typeof fields[i] === "string") headers.push(fields[i]);
      else {
        // Header name is defined
        if("name" in fields[i]) headers.push(fields[i].name);
        // Header name is not defined
        else headers.push(fields[i].id);
      }
    }
    csv.push(headers);

    // Data rows
    let items = this.props.items;
    let id, field, datetime;
    for(let i=0; i<items.length; i++) {
      let row = [];
      // Fields
      for(let j=0; j<fields.length; j++) {
        // Get field details
        if(typeof fields[j] === "string") {
          id = fields[j];
          datetime = false;
        } else {
          id = fields[j].id;
          datetime = ("format" in fields[j]
            && fields[j].format === "datetime") ? true : false;
       }

        // Get item field
        field = null;
        if(id in items[i]) field = items[i][id];
        else if(id in items[i].data) field = items[i].data[id];

        // Format item field
        if(datetime && field !== null) {
          field = new Date(Number(field) * 1000).toLocaleString()
            .replace(/,/g, "");
        }

        // Add item field to row
        row.push(field);
      }
      csv.push(row);
    }

    // Convert CSV to string
    let csvString = csv.map(e => e.join(",")).join("\n");

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a className="download"
        onClick={() => {
          // Analytics
          Analytics.event("archive_downloaded", {
            event_category: "archive",
            page_id: this.props.page.id,
            page_name: this.props.page.name
          });
          // Legacy file download
          if(legacyDownload) this.ieFileDownload(csvString, file_name);
        }}
        href={(legacyDownload) ? undefined
          : "data:text/csv;charset=utf-8,"+encodeURIComponent(csvString)}
        download={(legacyDownload) ? undefined : file_name+".csv"}>
        Download {this.props.items.length} Records
      </a>
    );
  }
}

export default DownloadArchive;
