import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Header, Footer, Spacer } from "../Header/Header";
import { Buttons } from "../Button/Button";
import Icon from "../Icon/Icon";
import Bowser from "bowser";
import "./InstallPage.css";
import Updater from "../Updater/Updater";
import app_store_button from './app_store_button.png';
import play_store_button from './play_store_button.png';
import mockup from './mockup.png';

const browser = Bowser.getParser(window.navigator.userAgent);
const isIos = browser.is("iOS");
const isAndroid = browser.is("Android");

export class InstallPrompt extends Component {
  closeModal() {
    this.props.showDownloadModal("close");
  }

  render() {
    // Close modal if specified
    if(!this.props.showDownloadModal()) return false;

    // Show modal only if on iOS or Android, not in mobile app
    if(!(isIos || isAndroid) || window.isNative) return false;

    // Pass token in URL
    let url = "https://app.lotsuite.com/install";
    if("token" in this.props && this.props.token !== null) {
      url += "?token="+this.props.token;
    }

    return (
      <div
        className="miniModal">
        <h1>
          More Features Available in App
        </h1>
        <div className="buttons">
          <table>
            <tbody>
              <tr>
                <th>
                  <Icon name="lotsuite"/>
                </th>
                <th>
                  LotSuite App
                  </th>
                <td>
                  <a
                    className="button"
                    href={url}>
                    Open
                  </a>
                </td>
              </tr>
              <tr>
                <th
                  className="continue">
                  <Icon name="md-globe"/>
                </th>
                <th>
                  Browser (Limited)
                </th>
                <td>
                  <div
                    className="button continue"
                    onClick={this.closeModal.bind(this)}>
                    Continue
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export class InstallPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  componentDidMount() {
    // Clear query string if it contains `token`
    if(window.location.search.indexOf("token") >= 0) {
      window.history.replaceState(null, null, window.location.pathname);
    }
  }

  redirect(path) {
    this.setState({ redirect: path });
  }

  render() {
    // Redirect function
    if(this.state.redirect !== false) {
      return <Redirect to={this.state.redirect} push={true}/>;
    }

    // Redirect download page
    let url;
    if((isIos || isAndroid)) {
      // Set URL to download app
      url = (isAndroid)
        ? "https://play.google.com/store/apps/details?id=com.lotsuite"
        : "https://itunes.apple.com/us/app/lotsuite-by-makin-auto/id1465839854?mt=8";

      // Redirect to app download page
      window.location = url;
    }

    // Buttons
    const buttons = {
      back: {
        icon: "arrow-back",
        title: "Back",
        position: "topleft",
        action: {
          function: "link",
          link: "/"
        }
      }
    };

    // Show page to download app
    return (
      <div className="InstallPage page">
        {
          // Do not show updater if token is undefined (e.g. before login)
          ("token" in this.props && typeof this.props.token === "string") &&
          <Updater
            user={this.props.user}
            token={this.props.token}
            updateToken={this.props.updateToken.bind(this)}/>
        }
        <Header/>
        <Buttons
          buttons={buttons}
          redirect={this.redirect.bind(this)}/>
        <div
          className="cards">
          <Spacer position="top"/>
          <h1>
            Get the Full Power of LotSuite
          </h1>
          <h2>
            Download the LotSuite app {(isAndroid) ? "for Android " : (isIos) ? "for iOS " : ""}
            to get all the latest features.
          </h2>
          <div className="links">
            {
              (isIos || (!isIos && !isAndroid)) && <a
                className="download_link"
                target="_blank"
                rel="noopener noreferrer"
                href={url}>
                <img
                  className="download_img"
                  alt="Download App from App Store"
                  src={app_store_button}/>
              </a>
            }
            {
              (isAndroid || (!isIos && !isAndroid)) && <a
                className="download_link"
                target="_blank"
                rel="noopener noreferrer"
                href={url}>
                <img
                  className="download_img"
                  alt="Download App from Play Store"
                  src={play_store_button}/>
              </a>
            }
          </div>
          <img
            className="hero"
            alt="LotSuite"
            src={mockup}/>
          <Footer
            token={this.props.token}/>
        </div>
      </div>
    );
  }
}
