import React, { Component, useCallback } from 'react';
import { Redirect } from 'react-router';
import { Header, Footer, Spacer } from "../Header/Header";
import * as Util from "../Utilities/Utilities";
import Form from "../Form/Form";
import { Buttons } from "../Button/Button";
import Status from "../Status/Status";
import "./BillingPage.css";
import Analytics from '../analytics';
import { InstallPrompt } from "../InstallPage/InstallPage";
import Updater from "../Updater/Updater";
import Icon from "../Icon/Icon";
import { usePlaidLink } from 'react-plaid-link';

import {
  Elements,
  CardElement,
  useStripe,
  useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsmobile from '../amplify-config';
Amplify.configure(awsmobile);

const env_check = process.env.NODE_ENV === 'production';
const stripe_prod_key = `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`;
const stripe_dev_key = `${process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV}`;

const plaid_env = (env_check) ? 'production' : 'development';

const AddBankAccount = (props) => {
  const onSuccess = useCallback((token, metadata) => {
    // Attach bank account to billing_id in Stripe
    props.attachBankAccount(token, metadata.account_id);
  }, [props]);

  const config = {
    clientName: 'LotSuite',
    env: plaid_env,
    product: ['auth'],
    publicKey: `${process.env.REACT_APP_PLAID_PUBLIC_KEY}`,
    onSuccess
  };

  const { open, ready } = usePlaidLink(config);

  // Show button to initiate Plaid Link
  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="bank">
      <h1>Add Bank Account</h1>
      <p>Use our secure API to connect your bank account</p>
      {(props.status !== "bank.error") ? "" : (
        <div className="error">
          There was an error linking this bank account, please try again
        </div>
      )}
      {(props.status !== "bank.loading") ? (
        <div className="buttons">
          <input
            type="button"
            className="plaid_button"
            onClick={() => open()}
            disabled={!ready}
            value="Connect Account"/>
        </div>
      ) : (
        <Icon name="spinner"/>
      )}
    </form>
  );
}

const BankAccount = (props) => {
  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="filled bank">
      <h1>Saved Bank Account</h1>
      <div className="input">
        <span id="bank_name">{props.details.bank_name}</span>
        <span id="last4">{props.details.last4}</span>
      </div>
    </form>
  );
};

const CreditCard = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    // Check if Stripe.js has not loaded yet
    if (!stripe || !elements) return;

    // Indicate loading
    props.updateStatus("card.loading");

    // Get a reference to a mounted CardElement
    const cardElement = elements.getElement(CardElement);

    // Create payment source
    const {error, source} = await stripe.createSource(cardElement);

    // Handle response
    if(error) {
      console.log('[error]', error);
      props.updateStatus("card.error");
    } else {
      // Pass source to API to attach to customer in Stripe
      props.attachPaymentSource(source.id);
    }
  };

  // Show card details if passed
  if(props.details !== undefined && props.details !== null) return (
    <form
      onSubmit={(e) => e.preventDefault()}
      width="400" className="filled">
      <h1>Saved Credit Card</h1>
      {(props.details.default) ? <div className="default_indicator">Default</div> : ""}
      <div className={"input "+props.details.brand}>
        <span id="brand">{props.details.brand}</span>
        <span id="last4">{props.details.last4}</span>
        <span className="exp">
          <span id="exp_month">{props.details.exp_month}</span>
          <span id="exp_year">{props.details.exp_year}</span>
        </span>
      </div>
    </form>
  );

  return (
    <form onSubmit={handleSubmit} width="400">
      <h1>Add Credit Card</h1>
      <div className="input">
        <CardElement
          options={{ style: { base: {
            fontSize: '14px',
            fontFamily: 'Open Sans, Segoe UI, sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': { color: '#C0C0C0' },
          }}}}/>
      </div>
      {(props.status !== "card.error") ? "" : (
        <div className="error">
          There was an error processing this card, please try again
        </div>
      )}
      {(props.status !== "card.loading") ? (
        <div className="buttons">
          <input type="reset" value="Clear"
            onClick={() => elements.getElement(CardElement).clear()}/>
          <input type="submit" value="Save"/>
        </div>
      ) : (
        <Icon name="spinner"/>
      )}
    </form>
  );
};

class Billing extends Component {
  constructor(props) {
    super(props);
    this.stripePromise = null;
    this.state = {
      status: false,
      loading: true,
      logout: false,
      showcc: false,
      sources: []
    };
  }

  componentDidMount() {
    this.loadData();
    // Load Stripe only outside of native app (avoid app whitelist issue)
    if(!window.isNative) this.stripePromise =
      loadStripe((env_check) ? stripe_prod_key : stripe_dev_key);
  }

  loaded(groups, status) {
    // Only allow admins to access billing page
    if(!Util.evalIf({ admin: true }, {
      groups: groups,
      user: this.props.user
    })) this.props.redirect("/");

    // Update data in parent element
    this.props.loaded(groups, status);

    // Stop loading
    this.setState({ loading: false });
  }

  loadData() {
    const submit = async () => {
      // Create query
      let query = `query
        GetOrg($token: String!) {
          getOrg(token: $token) {
            new_token
            status
            groups {
              id
              name
              admin
            }
            billing {
              sources {
                id
                type
                default
                bank_name
                last4
                brand
                exp_month
                exp_year
              }
            }
          }
        }`;
      // Create payload
      let payload = { token: this.props.token };
      // Submit to API
      return await API.graphql(graphqlOperation(query, payload));
    }
    submit()
      .then((res)=>{
        const getOrg = res.data.getOrg;
        // Get status
        let status = JSON.parse(getOrg.status)
        // Handle groups
        let groups = [];
        for(let i=0; i<getOrg.groups.length; i++) {
          groups = getOrg.groups;
        }
        // Send data to parent
        this.loaded(groups, status);
        // Add data to sources
        this.setState({ sources: getOrg.billing.sources });
      },
      (err)=>{
        // Log out if invalid token
        if(err.errors[0].message.indexOf("token") >= 0) {
          return this.setState({ logout: true });
        }
        // Handle other errors
        console.log(err);
        this.setState({ status: "error" });
      });
  }

  updateStatus(status) {
    this.setState({ status: status });
  }

  attachBankAccount(bank_token, bank_account_id) {
    // Indicate bank account is loading
    this.setState({ status: "bank.loading" });

    // Submit Plaid token to API to add to Stripe customer
    const submit = async () => {
      // Create query
      let mutation = `mutation
        StripeAttachPlaid($token: String!, $bank_token: String!, $bank_account_id: String!, $environment: String) {
          stripeAttachPlaid(token: $token, bank_token: $bank_token, bank_account_id: $bank_account_id, environment: $environment) {
            id
            type
            bank_name
            last4
          }
        }`;

      // Create payload
      let payload = {
        token: this.props.token,
        bank_token: bank_token,
        bank_account_id: bank_account_id,
        environment: plaid_env
      };

      // Submit to API
      return await API.graphql(graphqlOperation(mutation, payload));
    }
    submit()
      .then((res)=>{
        // Analytics
        Analytics.event("added_bank_account", {
          event_category: "billing",
          event_label: "bank_account"
        });
        // Show that payment source has been added
        let sources = this.state.sources;
        let source = res.data.stripeAttachPlaid;
        sources.push({
          type: source.type,
          bank_name: source.bank_name,
          last4: source.last4
        });
        this.setState({ sources: sources });
      },
      (err)=>{
        console.log(err);
        // Analytics
        Analytics.error("bank_account_not_added", {
          event_category: "users",
          event_label: "bank_account"
        });
        // Show error
        this.updateStatus("bank.error");
      });
  }

  attachPaymentSource(payment_source_id) {
    const submit = async () => {
      // Create query
      let mutation = `mutation
        StripeAttachSource($token: String!, $payment_source_id: String!) {
          stripeAttachSource(token: $token, payment_source_id: $payment_source_id) {
            id
            type
            last4
            brand
            exp_month
            exp_year
          }
        }`;

      // Create payload
      let payload = {
        token: this.props.token,
        payment_source_id: payment_source_id
      };

      // Submit to API
      return await API.graphql(graphqlOperation(mutation, payload));
    }
    submit()
      .then((res)=>{
        // Analytics
        Analytics.event("added_payment_source", {
          event_category: "billing",
          event_label: "card"
        });
        // Show that payment source has been added
        let sources = this.state.sources;
        let source = res.data.stripeAttachSource;
        sources.push({
          type: source.type,
          brand: source.brand,
          exp_month: source.exp_month,
          exp_year: source.exp_year,
          last4: source.last4
        });
        this.setState({
          sources: sources,
          showcc: false,
          status: false
        });
      },
      (err)=>{
        console.log(err);
        // Analytics
        Analytics.error("payment_source_not_updated", {
          event_category: "users",
          event_label: "card"
        });
        // Show error
        this.updateStatus("card.error");
      });
  }

  render() {
    // Error
    if(this.state.status === "error") return <Status status="error"/>;

    // Log out
    if(this.state.logout) return <Status status="logout"/>

    // Error
    if(this.state.loading) return <Status status="loading"/>;

    // Parse sources
    let banks = [];
    let cards = [];
    for(let i=0; i<this.state.sources.length; i++) {
      let source = this.state.sources[i];
      // Add bank account
      if(source.type === "bank_account") banks.push(
        <BankAccount
          key={i}
          details={source}/>
      );
      // Add card
      if(source.type === "card") cards.push(
        <CreditCard
          key={i}
          details={source}/>
      );
    }

    // Show billing details
    return (
      <div
        className="cards">
        <Spacer position="top"/>
        {(!window.isNative) ? (
          <div className="description">
            Thank you for subscribing to LotSuite, please enter your
            billing information to enable automatic subscription payments.
          </div>
        ) : (
          <div className="description no_billing">
            Thank you for subscribing to LotSuite, to update your billing
            information please log in to LotSuite.com from a web browser.
          </div>
        )}
        <Elements stripe={this.stripePromise}>
          {(banks.length > 0) ? banks : (window.isNative) ? "" : (
            <AddBankAccount
              status={this.state.status}
              updateStatus={this.updateStatus.bind(this)}
              attachBankAccount={this.attachBankAccount.bind(this)}/>
          )}
          {cards}
          {((cards.length === 0 && banks.length === 0 && !window.isNative)
          || this.state.showcc) ? (
            <CreditCard
              status={this.state.status}
              updateStatus={this.updateStatus.bind(this)}
              attachPaymentSource={this.attachPaymentSource.bind(this)}/>
          ) : (window.isNative) ? "" : (
            <div
              onClick={() => this.setState({ showcc: true })}
              className="showcc">
              Add Credit Card
            </div>
          )}
        </Elements>
        <Footer
          token={this.props.token}/>
      </div>
    );
  }
}

class BillingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      status: {},
      form_data: {},
      redirect: false
    };
  }

  openForm(params) {
    // Update form data in state
    this.setState({ form_data: params });
  }

  closeForm() {
    this.setState({ form_data: {} });
  }

  loaded(users, groups, status) {
    // Update state
    this.setState({
      groups: groups,
      status: status
    });
  }

  redirect(path) {
    this.setState({ redirect: path });
  }

  render() {
    // Redirect
    if(this.state.redirect !== false) {
      return <Redirect to={this.state.redirect} push={true}/>;
    }

    // Buttons
    let buttons = {
      back: {
        icon: "arrow-back",
        title: "Back",
        position: "topleft",
        action: {
          function: "link",
          link: "/"
        }
      }
    };

    // Users
    let billing = (
      <Billing
        redirect={this.redirect.bind(this)}
        loaded={this.loaded.bind(this)}
        groups={this.state.groups}
        user={this.props.user}
        token={this.props.token}/>
    );

    return (
      <div
        className="BillingPage page">
        <InstallPrompt
          token={this.props.token}
          showDownloadModal={this.props.showDownloadModal.bind(this)}/>
        <Updater
          user={this.props.user}
          token={this.props.token}
          updateToken={this.props.updateToken.bind(this)}/>
        <Form
          openForm={this.openForm.bind(this)}
          redirect={this.redirect.bind(this)}
          closeForm={this.closeForm.bind(this)}
          form_data={this.state.form_data}/>
        <Buttons
          openForm={this.openForm.bind(this)}
          redirect={this.redirect.bind(this)}
          token={this.props.token}
          buttons={buttons}
          user={this.props.user}
          groups={this.state.groups}
          page={{ color: "#3f9af7" }}/>
        <Header/>
        {billing}
      </div>
    );
  }
}

export default BillingPage;
