// eslint-disable
// this is an auto generated file. This will be overwritten

export const checkSoftwareVersion = `query CheckSoftwareVersion($token: String!) {
  checkSoftwareVersion(token: $token) {
    new_token
    reload_at
    agree_at
    webapp_version
    app_version
  }
}
`;
export const validateToken = `query ValidateToken($token: String!) {
  validateToken(token: $token) {
    token
    user_id
    org_id
    user {
      id
      name
      email
      phone
      employee_id
      active_at
      login_at
      password_at
      orgs {
        id
        name
        groups {
          id
          name
          admin
        }
        users {
          id
          name
          email
          phone
          employee_id
          active_at
          login_at
          password_at
          orgs {
            id
            name
            config
            status
            new_token
          }
          group_ids
          new_token
        }
        pages {
          id
          name
          icon
          color
          description
          config
          analytics
          lists {
            id
            new_token
          }
          list_ids
          new_token
        }
        config
        status
        billing {
          billing_id
          sources {
            id
            type
            bank_name
            last4
            brand
            exp_month
            exp_year
          }
        }
        new_token
      }
      group_ids
      new_token
    }
    org {
      id
      name
      groups {
        id
        name
        admin
      }
      users {
        id
        name
        email
        phone
        employee_id
        active_at
        login_at
        password_at
        orgs {
          id
          name
          groups {
            id
            name
            admin
          }
          users {
            id
            name
            email
            phone
            employee_id
            active_at
            login_at
            password_at
            group_ids
            new_token
          }
          pages {
            id
            name
            icon
            color
            description
            config
            analytics
            list_ids
            new_token
          }
          config
          status
          billing {
            billing_id
          }
          new_token
        }
        group_ids
        new_token
      }
      pages {
        id
        name
        icon
        color
        description
        config
        analytics
        lists {
          id
          items {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          items_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          new_token
        }
        list_ids
        new_token
      }
      config
      status
      billing {
        billing_id
        sources {
          id
          type
          bank_name
          last4
          brand
          exp_month
          exp_year
        }
      }
      new_token
    }
    group_ids
    kiosk_id
  }
}
`;
export const getList = `query GetList($token: String!, $page_id: ID!, $list_id: ID!) {
  getList(token: $token, page_id: $page_id, list_id: $list_id) {
    id
    items {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    items_archived {
      id
      list_id
      created_at
      updated_at
      archived_at
      bookmark
      cue
      data
      parent {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      parent_id
      parent_list_id
      parent_page_id
      children {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      children_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      zones {
        list_id
        required
        added_at
      }
    }
    new_token
  }
}
`;
export const getMetrics = `query GetMetrics(
  $token: String!
  $page_id: ID
  $list_id: ID!
  $metric_id: ID
  $metric_ids: [ID]
  $tag_id: ID
  $tag_ids: [ID]
  $tag_value: ID
  $interval: MetricInterval!
  $limit: Int
  $start_year: Int
  $start_month: Int
  $start_day: Int
  $end_year: Int
  $end_month: Int
  $end_day: Int
) {
  getMetrics(
    token: $token
    page_id: $page_id
    list_id: $list_id
    metric_id: $metric_id
    metric_ids: $metric_ids
    tag_id: $tag_id
    tag_ids: $tag_ids
    tag_value: $tag_value
    interval: $interval
    limit: $limit
    start_year: $start_year
    start_month: $start_month
    start_day: $start_day
    end_year: $end_year
    end_month: $end_month
    end_day: $end_day
  ) {
    list_id
    metric_id
    tag_id
    tag_value
    year
    month
    day
    value
  }
}
`;
export const getOrg = `query GetOrg($token: String, $org_id: ID) {
  getOrg(token: $token, org_id: $org_id) {
    id
    name
    groups {
      id
      name
      admin
    }
    users {
      id
      name
      email
      phone
      employee_id
      active_at
      login_at
      password_at
      orgs {
        id
        name
        groups {
          id
          name
          admin
        }
        users {
          id
          name
          email
          phone
          employee_id
          active_at
          login_at
          password_at
          orgs {
            id
            name
            config
            status
            new_token
          }
          group_ids
          new_token
        }
        pages {
          id
          name
          icon
          color
          description
          config
          analytics
          lists {
            id
            new_token
          }
          list_ids
          new_token
        }
        config
        status
        billing {
          billing_id
          sources {
            id
            type
            bank_name
            last4
            brand
            exp_month
            exp_year
          }
        }
        new_token
      }
      group_ids
      new_token
    }
    pages {
      id
      name
      icon
      color
      description
      config
      analytics
      lists {
        id
        items {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        items_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        new_token
      }
      list_ids
      new_token
    }
    config
    status
    billing {
      billing_id
      sources {
        id
        type
        bank_name
        last4
        brand
        exp_month
        exp_year
      }
    }
    new_token
  }
}
`;
export const getUser = `query GetUser($token: String, $user_id: ID) {
  getUser(token: $token, user_id: $user_id) {
    id
    name
    email
    phone
    employee_id
    active_at
    login_at
    password_at
    orgs {
      id
      name
      groups {
        id
        name
        admin
      }
      users {
        id
        name
        email
        phone
        employee_id
        active_at
        login_at
        password_at
        orgs {
          id
          name
          groups {
            id
            name
            admin
          }
          users {
            id
            name
            email
            phone
            employee_id
            active_at
            login_at
            password_at
            group_ids
            new_token
          }
          pages {
            id
            name
            icon
            color
            description
            config
            analytics
            list_ids
            new_token
          }
          config
          status
          billing {
            billing_id
          }
          new_token
        }
        group_ids
        new_token
      }
      pages {
        id
        name
        icon
        color
        description
        config
        analytics
        lists {
          id
          items {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          items_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          new_token
        }
        list_ids
        new_token
      }
      config
      status
      billing {
        billing_id
        sources {
          id
          type
          bank_name
          last4
          brand
          exp_month
          exp_year
        }
      }
      new_token
    }
    group_ids
    new_token
  }
}
`;
export const getPage = `query GetPage($token: String, $page_id: ID!) {
  getPage(token: $token, page_id: $page_id) {
    id
    name
    icon
    color
    description
    config
    analytics
    lists {
      id
      items {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      items_archived {
        id
        list_id
        created_at
        updated_at
        archived_at
        bookmark
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        parent_id
        parent_list_id
        parent_page_id
        children {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        children_archived {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
          parent {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          parent_id
          parent_list_id
          parent_page_id
          children {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          children_archived {
            id
            list_id
            created_at
            updated_at
            archived_at
            bookmark
            cue
            data
            parent_id
            parent_list_id
            parent_page_id
          }
          zones {
            list_id
            required
            added_at
          }
        }
        zones {
          list_id
          required
          added_at
        }
      }
      new_token
    }
    list_ids
    new_token
  }
}
`;
